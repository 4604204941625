import type { ICheckboxListProps } from "@ui/ui.types";
import cn from "classnames";
import React from "react";

import styles from "./styes.module.scss";
import { Checkbox } from "..";

const CheckboxList = ({
  list,
  checked,
  setChecked,
  itemClassName,
  className,
  onChange,
  error,
  disableUncheck = false
}: ICheckboxListProps): JSX.Element => {
  return (
    <ul className={cn(styles.list, className)}>
      {list.map((el) => {
        const isChecked = Array.isArray(checked)
          ? checked.find((item) => el.value === item.value) !== undefined
          : el.value === checked?.value;
        return (
          <li key={el.label} className={itemClassName}>
            <Checkbox
              label={el.label}
              id={el.label}
              checked={isChecked}
              error={Boolean(error)}
              setChecked={(isChecked) => {
                if (onChange !== undefined) {
                  onChange(isChecked, el);
                }
                if (Array.isArray(checked)) {
                  if (isChecked) {
                    setChecked([...checked, el]);
                  } else {
                    setChecked(
                      checked.filter((item) => item.value !== el.value)
                    );
                  }

                  return;
                }
                setChecked(isChecked || disableUncheck ? el : null);
              }}
            />
          </li>
        );
      })}
      {/* <div className={styles.list__error}>{error}</div> */}
    </ul>
  );
};

export default CheckboxList;

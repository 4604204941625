import React from "react";
import cn from "classnames";

import { EmptySvg } from "@assets/svg";

import type { IEmptyProps } from "@ui/ui.types";

import styles from "./styles.module.scss";

const Empty = ({ className, title, subtitle }: IEmptyProps): JSX.Element => {
  return (
    <div className={cn(styles.empty, className)}>
      <EmptySvg className={styles.empty__icon} />
      <div className={styles.empty__title}>{title}</div>
      <div className={styles.empty__subtitle}>{subtitle}</div>
    </div>
  );
};

export default Empty;

import React from "react";
import cn from "classnames";

import type { ISwitchProps } from "@ui/ui.types";

import styles from "./Switch.module.scss";
import { PopupTriangleLayout } from "@layouts";

const Switch = ({
  children,
  className,
  active,
  setActive,
  id,
  reverse = false,
  hint
}: ISwitchProps): JSX.Element => {
  return (
    <PopupTriangleLayout
      className={cn(styles.switch, className, reverse && styles.reverse)}
      popup={hint}
      show={hint !== undefined}
      textAlign="textCenter"
      size="md"
    >
      <div className={styles.switch__title}>{children}</div>
      <input
        className={styles.switch__input}
        type="checkbox"
        id={id}
        checked={active}
        onChange={(e) => {
          setActive(e.target.checked);
        }}
      />
      <label htmlFor={id} className={cn(styles.switch__btn)}>
        <span />
      </label>
    </PopupTriangleLayout>
  );
};

export default Switch;

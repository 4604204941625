import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "@utils";

export const fetchcustomerReviewStats = createAsyncThunk(
  "customerReviewStats/get",
  async (aid: number) => {
    const response = await axiosInstance.get(`customer-polls-stats?aid=${aid}`);
    return response.data;
  }
);

import React from "react";
import cn from "classnames";

import styles from "./styles.module.scss";
import type { ISpinnerProps } from "@ui/ui.types";
const Spinner = ({
  className,
  size = "md",
  color = "none"
}: ISpinnerProps): JSX.Element => {
  return (
    <div className={cn(styles.spinner, className, styles[size], styles[color])}>
      <span></span>
    </div>
  );
};

export default Spinner;

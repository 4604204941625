import React, { useState } from "react";
import cn from "classnames";

import { ArrowSvg } from "@assets/svg";

import type { ISelectProps } from "../ui.types";

import styles from "./Select.module.scss";
import { useOutsideClick } from "@hooks";
const Select = ({
  title,
  className,
  list = [],
  value,
  size = "full",
  color,
  setValue,
  input = false
}: ISelectProps): JSX.Element => {
  const [show, setShow] = useState<boolean>(false);

  const toggleShow = (): void => {
    setShow((prev) => !prev);
  };
  const ref = useOutsideClick(toggleShow, show);
  return (
    <div
      className={cn(
        styles.select,
        className,
        styles[size],
        color && styles[color]
      )}
      ref={ref}
    >
      <div
        className={cn(
          styles.select__btn,
          value?.label !== undefined && styles.active,
          show && styles.show
        )}
        onClick={toggleShow}
      >
        {input ? (
          <input
            value={value?.label}
            placeholder={title}
            onChange={(e) => {
              setValue((prev) => ({
                ...prev,
                label: e.target.value
              }));
            }}
          />
        ) : (
          value?.label ?? title
        )}
        <ArrowSvg className={styles.arrow} />
      </div>
      <div className={cn(styles.select__popup, show && styles.show)}>
        <div className={styles.select__list}>
          {list.map((el) => (
            <div
              key={el.value}
              className={styles["select__list-item"]}
              onClick={() => {
                setValue(el);
                toggleShow();
              }}
            >
              {el.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Select;

import React, { useRef } from "react";
import type { Result } from "check-password-strength";
import { InputLabelForm } from "@getpickedupgpu/gpu2.shared";

import styles from "./styles.module.scss";

import { useFormContext } from "react-hook-form";

const ResetPassword = (): JSX.Element => {
  const { control, watch } = useFormContext();
  const password = watch("password");

  const passwordStrength = useRef<Result<string> | null>(null);

  return (
    <div className={styles.reset}>
      <InputLabelForm
        control={control}
        setPasswordStrength={(value: Result<string>) => {
          passwordStrength.current = value;
        }}
        className={styles.reset__input}
        label="New Password"
        name="password"
        type="password"
        id="password"
        error={null}
        required
        rules={{
          validate: (value: string) => {
            console.log("validate", passwordStrength.current);

            if ((passwordStrength.current?.id ?? 0) < 2) {
              return "Too weak";
            }
            return true;
          }
        }}
      />
      <InputLabelForm
        control={control}
        className={styles.reset__input}
        label="Confirm Password"
        name="password_confirmation"
        id="password_confirmation"
        type="password"
        required
        rules={{
          validate: (value: string) => {
            if (value !== password) {
              return "Passwords must match";
            }
            return true;
          }
        }}
      />
    </div>
  );
};

export default ResetPassword;

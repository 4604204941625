import React, { type SyntheticEvent, useContext, useState } from "react";
import cn from "classnames";

import { ModalLayout, PopupTriangleLayout } from "@layouts";
import { ModalContext } from "@contexts";
import { Button, Dots } from "@ui";
import { EBookingType } from "@constants/history";
import { EModalTypes } from "@constants/modal";

import { DownloadSvg } from "@assets/svg";

import type { IOrder } from "@interfaces";

import styles from "./styles.module.scss";
import { useFetchData } from "@hooks";
import { useNavigate } from "react-router-dom";
import { BOOKING_ROUTE, HISTORY_ROUTE, PAYMENT_ROUTE } from "@constants/routes";
import { downloadFile, getFullUserMobile, getFullUserName } from "@utils";
import { BookingStatus } from "@modules/History/components";
import { CancelBooking } from "@modules/History/widgets";
import { PaymentMethod } from "@components";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectUser } from "@store/user/selector";

const BookingModal = (): JSX.Element => {
  const user = useSelector(selectUser);
  const modalContext = useContext(ModalContext);
  const navigate = useNavigate();
  const [receiptLoading, setReceiptLoading] = useState<boolean>(false);

  const booking: IOrder =
    modalContext !== null ? modalContext.data?.booking : {};
  const { data, loading } = useFetchData<IOrder>(`orders/${booking.oid}`);

  const allData = {
    ...booking,
    ...data
  };

  const cancelBooking = (e: SyntheticEvent<Element, Event>): void => {
    modalContext?.handleOpen(EModalTypes.AGREE, e, {
      extra: <CancelBooking order={allData} />,
      removeOrderById: modalContext.data?.removeOrderById,
      title: "Cancel booking",
      removeBtns: true
    });
  };

  const onDownloadReceipt = async (invno: number): Promise<void> => {
    try {
      setReceiptLoading(true);
      await downloadFile(
        `invoices/${invno}/customer-download`,
        `invoice-${invno}.pdf`
      );
    } catch (e) {
      toast.error(e.data?.message ?? "Something went wrong");
    }
    setReceiptLoading(false);
  };
  return (
    <ModalLayout
      type={EModalTypes.BOOKING}
      className={styles.booking__inner}
      size="lg"
    >
      {allData && !allData.tm ? (
        <Dots />
      ) : (
        <div className={styles.booking}>
          <BookingStatus
            className={cn(styles.booking__type, styles[allData.status])}
            status={allData.status as EBookingType}
          />
          <p className={styles.booking__number}>№ {allData.oid}</p>
          <div className={styles.booking__row}>
            <div className={styles["booking__row-title"]}>Date/time</div>
            <div className={styles["booking__row-value"]}>
              {`${allData.tm_as_string}${
                allData.order_pt === 1 ? ` - ${allData.endtm_as_string}` : ""
              }`}
            </div>
          </div>
          {/* {booking.scheduledRepeating !== undefined ? (
          <div className={styles.booking__row}>
            <div className={styles["booking__row-title"]}>
              Scheduled repeating:
            </div>
            <div className={styles["booking__row-value"]}>
              {booking.scheduledRepeating.map((el) => (
                <p key={el}>{el}</p>
              ))}
            </div>
          </div>
        ) : null} */}
          <div className={styles.booking__row}>
            <div className={styles["booking__row-title"]}>Customer</div>
            <div className={cn(styles["booking__row-value"], styles.name)}>
              {getFullUserName(allData.customer)}
            </div>
          </div>
          <div className={styles.booking__row}>
            <div className={styles["booking__row-title"]}>Phone</div>
            <div className={styles["booking__row-value"]}>
              {getFullUserMobile(allData.customer)}
            </div>
          </div>
          <div className={styles.booking__row}>
            <div className={styles["booking__row-title"]}>From</div>
            <div className={styles["booking__row-value"]}>
              {allData.from_address}
            </div>
          </div>
          {allData.stops.map((el, index) =>
            el !== null ? (
              <div className={styles.booking__row} key={el.place}>
                <div className={styles["booking__row-title"]}>
                  Stop {index + 1}
                </div>
                <div className={styles["booking__row-value"]}>{el.place}</div>
              </div>
            ) : null
          )}
          <div className={styles.booking__row}>
            <div className={styles["booking__row-title"]}>To</div>
            <div className={styles["booking__row-value"]}>
              {allData.to_address}
            </div>
          </div>
          <div className={styles.booking__info}>
            {allData.flight_no !== "" ? (
              <p>{`${allData.flight_type} flight №${allData.flight_no}`}</p>
            ) : null}
            {allData.signboard_text !== "" ? (
              <p>Signboard: {allData.signboard_text}</p>
            ) : null}
            {allData.comments !== "" ? (
              <p>Comment: {allData.comments}</p>
            ) : null}
            <p>{`Passengers: ${allData.pass_num}, Bags: ${allData.luggage}${
              allData.baby_seat !== "" ? `, ${allData.baby_seat}` : ""
            }${
              allData.agent_booking_name
                ? `, Booking reference: ${allData.agent_booking_name}`
                : ""
            }${allData.event ? `, Event: ${allData.event}` : ""}`}</p>
          </div>
          <div className={styles.booking__bar} />
          <div className={styles.booking__row}>
            <div className={styles["booking__row-title"]}>Car</div>
            <div className={styles["booking__row-value"]}>
              {allData.cartype.title}
            </div>
          </div>
          <div className={styles.booking__row}>
            <div className={styles["booking__row-title"]}>Driver</div>

            <PopupTriangleLayout
              className={cn(
                styles["booking__row-value"],
                styles.name,
                (allData.driver?.first_name === undefined ||
                  allData.driver?.first_name === "") &&
                  styles.pending
              )}
              popup={
                <div className={styles["booking__row-popup"]}>
                  We are in the process of allocating a driver. 1 hour prior to
                  the pick up time we will send the traveler an sms with the
                  driver details.
                </div>
              }
              show={allData.driver?.did === 0}
            >
              {allData.driver?.first_name === "" || allData.driver === null
                ? "Pending"
                : getFullUserName(allData.driver)}
            </PopupTriangleLayout>
          </div>
          {allData.driver?.did !== 0 && allData.driver !== null ? (
            <>
              {allData.driver.mobile !== undefined ? (
                <div className={styles.booking__row}>
                  <div className={styles["booking__row-title"]}>Mobile</div>
                  <div className={styles["booking__row-value"]}>
                    {getFullUserMobile(allData.driver)}
                  </div>
                </div>
              ) : null}
              {allData.vechicle !== undefined ? (
                <div className={styles.booking__row}>
                  <div className={styles["booking__row-title"]}>Vehicle</div>
                  <div className={styles["booking__row-value"]}>
                    {allData.vechicle}
                  </div>
                </div>
              ) : null}
              {allData.plate !== undefined ? (
                <div className={styles.booking__row}>
                  <div className={styles["booking__row-title"]}>Plate</div>
                  <div className={styles["booking__row-value"]}>
                    {allData.plate}
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
          {loading ? <Dots /> : <div className={styles.booking__bar} />}

          {loading ? null : allData.payments === undefined ||
            allData.payments.length === 0 ? (
            <>
              <div className={styles.booking__row}>
                <div className={styles["booking__row-title"]}>
                  Payment Method
                </div>
                <div
                  className={cn(styles["booking__row-value"], styles.pending)}
                >
                  Unpaid
                </div>
              </div>
              <div className={styles.booking__bar} />

              {allData.cost !== undefined ? (
                <div className={styles.booking__row}>
                  <div className={styles["booking__row-title"]}>Amount</div>
                  <div className={styles["booking__row-value"]}>
                    ${allData.cost}
                  </div>
                </div>
              ) : null}
              {allData.iagent !== undefined && user?.agent_type === 0 ? (
                <div className={styles.booking__row}>
                  <div className={styles["booking__row-title"]}>
                    Agent Comission
                  </div>
                  <div className={styles["booking__row-value"]}>
                    ${allData.iagent.toFixed(2)}
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            allData.payments?.map((el, index) => (
              <>
                {allData.payment_type === "Account" ? (
                  <div className={styles.booking__row}>
                    <div className={styles["booking__row-title"]}>
                      Payment Method
                    </div>
                    <div className={cn(styles["booking__row-value"])}>
                      {allData.payment_type}
                    </div>
                  </div>
                ) : null}
                {allData.payment_type === "Card" ? (
                  <div className={styles.booking__row}>
                    <div className={styles["booking__row-title"]}>
                      Payment Time
                    </div>
                    <div className={styles["booking__row-value"]}>
                      {el.tm_as_string}
                    </div>
                  </div>
                ) : null}

                {el.cc_num && el.cc_type ? (
                  <div className={styles.booking__row}>
                    <div className={styles["booking__row-title"]}>
                      Payment Method
                    </div>
                    <div className={styles["booking__row-value"]}>
                      <PaymentMethod
                        number={el.cc_num}
                        type={el.cc_type}
                        logo={el.logo}
                        align="right"
                      />
                    </div>
                  </div>
                ) : null}

                {allData.payment_type === "Card" ? (
                  <div className={styles.booking__row}>
                    <div className={styles["booking__row-title"]}>
                      Transaction type
                    </div>
                    <div
                      className={cn(
                        styles["booking__row-value"],
                        el.status?.str === "failed" && styles.pending
                      )}
                    >
                      {el.status?.human_status ?? ""}
                    </div>
                  </div>
                ) : null}

                {el.sender_name && allData.payment_type === "Card" ? (
                  <div className={styles.booking__row}>
                    <div className={styles["booking__row-title"]}>
                      Sender Name
                    </div>
                    <div className={styles["booking__row-value"]}>
                      {el.sender_name}
                    </div>
                  </div>
                ) : null}
                <div className={styles.booking__row}>
                  <div className={styles["booking__row-title"]}>Amount</div>
                  <div className={styles["booking__row-value"]}>
                    ${el.amount}
                  </div>
                </div>
                {el.invno && el.status?.str !== "failed" ? (
                  <div
                    className={cn(
                      styles.booking__download,
                      receiptLoading && styles.disabled
                    )}
                    onClick={() => {
                      onDownloadReceipt(el.invno);
                    }}
                  >
                    <DownloadSvg /> Get PDF Receipt
                  </div>
                ) : null}
                {index + 1 < allData.payments.length ? (
                  <div className={styles.booking__bar} />
                ) : null}
              </>
            ))
          )}
          {allData.status === EBookingType.SUSPENDED ? (
            <>
              <div className={styles.booking__row}>
                <div className={styles["booking__row-title"]}>Paid Amount</div>
                <div className={styles["booking__row-value"]}>
                  ${allData.opaid}
                </div>
              </div>
              <div className={cn(styles.booking__row, styles.bold)}>
                <div className={styles["booking__row-title"]}>
                  Amount to Pay
                </div>
                <div className={styles["booking__row-value"]}>
                  ${allData.amount_to_pay}
                </div>
              </div>
            </>
          ) : null}

          <div className={styles.booking__btns}>
            {allData.status === EBookingType.UPCOMING ? (
              <>
                <Button size="full" color="ghost" onClick={cancelBooking}>
                  Cancel Booking
                </Button>
                <Button
                  size="full"
                  onClick={(e) => {
                    if (allData.read_only_message) {
                      toast.info(allData.read_only_message);
                      return;
                    }
                    navigate(
                      `/${HISTORY_ROUTE}/${BOOKING_ROUTE}/${booking.oid}`,
                      {
                        state: allData
                      }
                    );
                    modalContext?.handleClose(e);
                  }}
                  isLoading={loading}
                >
                  Edit
                </Button>
              </>
            ) : null}
            {allData.status === EBookingType.SUSPENDED ? (
              <>
                <Button size="full" color="ghost" onClick={cancelBooking}>
                  Cancel Booking
                </Button>
                <Button
                  size="full"
                  onClick={(e) => {
                    modalContext?.handleClose(e);
                    navigate(`/${PAYMENT_ROUTE}/${allData.oid}`);
                  }}
                >
                  Pay
                </Button>
              </>
            ) : null}
            {/* {allData.status === EBookingType.DONE ? (
              <Button size="full">Rebook</Button>
            ) : null} */}
          </div>
        </div>
      )}
    </ModalLayout>
  );
};

export default BookingModal;

import React from "react";
import ReactCalendar from "react-calendar";
import cn from "classnames";

import styles from "./styles.module.scss";
import type { IRepeatDate, TDateCalendar } from "@interfaces";
import type { IMultipleCalendarProps } from "@ui/ui.types";
import moment from "moment";
import { DATE_FORMAT } from "@constants/config";
import { ArrowSvg } from "@assets/svg";

const dateAlreadyClicked = (
  dates: IRepeatDate[],
  date: TDateCalendar
): IRepeatDate | undefined =>
  dates.find(
    (d) =>
      moment(d.date?.toString()).format(DATE_FORMAT) ===
      moment(date?.toString()).format(DATE_FORMAT)
  );
const datesExcept = (
  dates: IRepeatDate[],
  date: TDateCalendar
): IRepeatDate[] =>
  dates.filter(
    (d) =>
      moment(d.date?.toString()).format(DATE_FORMAT) !==
      moment(date?.toString()).format(DATE_FORMAT)
  );

const MultipleCalendar = ({
  dates,
  setDates,
  removedFromList = () => {},
  addedToList = () => {},
  className
}: IMultipleCalendarProps): JSX.Element => {
  const onClickDay = (date: TDateCalendar): void => {
    const dateClicked = dateAlreadyClicked(dates, date);
    if (dateClicked?.disabled) {
      addedToList(date);
      return;
    }
    if (dateClicked) {
      setDates((prev) => datesExcept(prev, date));
      removedFromList(date);
    } else {
      setDates([...dates, { date }]);
      addedToList(date);
    }
  };

  // eslint-disable-next-line
  const tileClassName = ({ date }: any): string[] => {
    const classNames = [styles.dayTile];

    if (dateAlreadyClicked(dates, date))
      return [styles.activeDay, ...classNames];
    return classNames;
  };
  return (
    <ReactCalendar
      className={cn(styles.calendar, className)}
      prevLabel={<ArrowSvg />}
      nextLabel={<ArrowSvg />}
      tileClassName={tileClassName}
      onClickDay={onClickDay}
      locale="us"
      minDate={new Date()}
      showNeighboringMonth={false}
    />
  );
};

export default MultipleCalendar;

import { SLICE_NAMES } from "@store/actionTypes";
import { createSelector } from "reselect";
import type { BookingState } from "./type";

const selectBookingStore = (state: {
  [key in SLICE_NAMES]: BookingState;
}): BookingState => state[SLICE_NAMES.BOOKING];

export const selectBookingCustomer = createSelector(
  selectBookingStore,
  (state) => state.customer
);

export const selectBookings = createSelector(
  selectBookingStore,
  (state) => state.bookings
);

export const selectPaymentStatus = createSelector(
  selectBookingStore,
  (state) => state.paymentStatus
);
export const selectBookingEdit = createSelector(
  selectBookingStore,
  (state) => state.edit
);
export const selectShowDetails = createSelector(
  selectBookingStore,
  (state) => state.showDetail
);
export const selectRefreshCount = createSelector(
  selectBookingStore,
  (state) => state.refreshCount
);
export const selectBookingSubmited = createSelector(
  selectBookingStore,
  (state) => state.submited
);
export const selectBookingError = createSelector(
  selectBookingStore,
  (state) => state.error
);

export const selectIspayment = createSelector(
  selectBookingStore,
  (state) => state.isPayment
);

export const selectActiveBooking = createSelector(
  selectBookingStore,
  (state) => state.activeBooking
);

export const selectCartypeLoading = createSelector(
  selectBookingStore,
  (state) => state.carTypesLoading
);

export const seelctHourlyHire = createSelector(
  selectBookingStore,
  (state) => state.hourlyHire
);
export const selectClientBookingId = createSelector(
  selectBookingStore,
  (state) => state.clientBookingId
);

export const selectCustomerCoupon = createSelector(
  selectBookingStore,
  (state) => state.customerCoupon
);
export const selectAreaError = createSelector(
  selectBookingStore,
  (state) => state.areaError
);
export const selectServerError = createSelector(
  selectBookingStore,
  (state) => state.serverError
);

import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";

import type { ChangeEvent } from "react";

import { selectCountryCodes } from "@store/lookup/selector";

import { ArrowSvg, SearchSvg } from "@assets/svg";

import { useAppDispatch, useOutsideClick } from "@hooks";
import { getCountryCodes } from "@store/lookup/actions";
import type { ICountryCodeSelectProps } from "../components.types";

import styles from "./styles.module.scss";

function CountryCodeSelect({
  value,
  setValue,
  className
}: ICountryCodeSelectProps): JSX.Element {
  const dispatch = useAppDispatch();
  const codes = useSelector(selectCountryCodes);
  const [search, setSearch] = useState<string>("");
  const [focused, setFocused] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(false);

  const toggleShow = (): void => {
    setShowSearch((prev) => !prev);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (codes.length === 0) {
      dispatch(getCountryCodes());
    }
  }, []);

  const ref = useOutsideClick(toggleShow, showSearch);

  useEffect(() => {
    if (
      value?.code === undefined &&
      codes.length !== 0 &&
      (typeof value?.code !== "string" || !value?.code)
    ) {
      setValue(
        codes.find((el) => el.code === (value?.code ?? value)?.toString()) ??
          codes[0]
      );
    }
  }, [codes, value?.code]);

  return (
    <div className={styles.select} ref={ref}>
      <div className={cn(styles.select__btn, className)} onClick={toggleShow}>
        <div className={styles["select__btn-icon"]}>
          <img src={value?.img ?? codes[0]?.img} alt={value?.title} />
        </div>
        <ArrowSvg />
      </div>
      <div className={cn(styles.list, showSearch && styles.active)}>
        <div
          className={cn(
            styles.list__search,
            codes.length > 0 ? styles.border : ""
          )}
        >
          <SearchSvg className={cn(styles.search, styles.icon)} />
          <input
            value={search}
            placeholder={"Search"}
            onChange={onChange}
            autoFocus
            onFocus={() => {
              setFocused(true);
            }}
            onBlur={() => {
              setTimeout(() => {
                setFocused(false);
              }, 200);
            }}
          />
          <span className={cn(styles.arrow, styles.icon)}>
            <ArrowSvg />
          </span>
        </div>

        <div
          className={cn(styles.list__box, focused && styles.focused)}
          id="list"
        >
          {codes
            .filter((el) =>
              el.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
            )
            .map((el) => (
              <div
                key={el.id}
                className={styles["list__box-item"]}
                onClick={() => {
                  toggleShow();
                  setValue(el);
                }}
              >
                <img
                  className={styles["list__box-icon"]}
                  src={el.img}
                  alt={el.title}
                />
                <p>{el.title}</p> <span> +{el.code}</span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default CountryCodeSelect;

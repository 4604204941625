import React from "react";

// styles
import styles from "./SpinnerButton.module.scss";

const SpinnerButton = (): JSX.Element => (
  <div className={styles["loading-dots"]}>
    <span />
    <span />
    <span />
  </div>
);

export default SpinnerButton;

import React, { type ChangeEvent, forwardRef } from "react";
import cn from "classnames";

import styles from "./styles.module.scss";
import type { IInputProps } from "../components.types";
const Input = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      placeholder,
      label,
      icon: Icon = null,
      inputSize = "half",
      setValue,
      onIconClick,
      className,
      asTag,
      onChange = () => {},
      error = false,
      iconText,
      select,
      ...rest
    },
    ref
  ): JSX.Element => {
    const Tag = asTag !== undefined ? asTag : "input";
    return (
      <div className={cn(styles.input, styles[inputSize], className)}>
        <div className={styles.input__wrapper}>
          <div className={cn(styles.input__label, error && styles.error)}>
            {label}
          </div>
          <Tag
            type="text"
            placeholder={placeholder}
            ref={ref}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onChange(e);
              if (setValue !== undefined) {
                setValue(e.currentTarget.value);
              }
            }}
            {...rest}
          />
        </div>
        {Icon !== null && (
          <div className={styles.input__btn} onClick={onIconClick}>
            <Icon />
            {iconText ?? ""}
          </div>
        )}
        {select}
      </div>
    );
  }
);

export default Input;

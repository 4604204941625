import React, { useContext, useEffect, useState } from "react";
import GoogleMapReact, { type Coords } from "google-map-react";

import cn from "classnames";

import styles from "./styles.module.scss";
import { ModalLayout } from "@layouts";
import { EModalTypes } from "@constants/modal";
import { ModalContext } from "@contexts";
import SelectList from "@ui/SelectList";
import type { ISelectListItem } from "@ui/ui.types";
import { Button, Dots, TimePicker } from "@ui";
import { config } from "@constants/config";
import { axiosInstance, getFullUserName } from "@utils";
import type { IOrderTracking, ITimePicker, ITrackerLog } from "@interfaces";
import { ClearSvg, SearchSvg } from "@assets/svg";
import moment from "moment";
import { finish, pickUp, trackingCar } from "@assets/svg/tracking";
import { EBookingType } from "@constants/history";

const list = [
  {
    label: "Onsite",
    id: "1"
  },
  {
    label: "Begin trip",
    id: "2"
  },
  {
    label: "End trip",
    id: "3"
  },
  {
    label: "Stop location",
    id: "4"
  },
  {
    label: "Stop wait time "
  }
];
// eslint-disable-next-line
interface ILogType {
  [key: number]: string;
}

const LogType: ILogType = {
  0: "Trip",
  1: "Onsite",
  2: "Begin trip",
  3: "End trip",
  4: "Start stop",
  5: "End stop"
};

interface IMarkerProps {
  className?: string;
  index?: number;
  lat: number;
  lng: number;
  image?: string;
}

interface IHoveredItem {
  start: number;
  stop: number;
}

const TrackOrderModal = (): JSX.Element => {
  const [position, setPosition] = useState<ISelectListItem>(list[0]);
  const [tracking, setTracking] = useState<IOrderTracking>();
  const [filteredLogs, setFilteredLogs] = useState<ITrackerLog[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [center, setCenter] = useState<Coords>();
  const [zoom, setZoom] = useState<number>(11);
  const [dateFrom, setDateFrom] = useState<ITimePicker>();
  const [dateTo, setDateTo] = useState<ITimePicker>();
  const [hovered, setHovered] = useState<IHoveredItem[]>([]);
  const [isSearchEmpty, setIsSearchEmpty] = useState<boolean>(false);
  const modalContext = useContext(ModalContext);
  const { order } = modalContext?.data;

  const clearHovered = (): void => {
    setHovered([]);
  };
  const getTracking = async (init: boolean = false): Promise<void> => {
    try {
      const resp = await axiosInstance.get(`orders/${order.oid}/tracking`);
      const data: IOrderTracking = resp.data;
      const tracker_logs = data.tracker_logs.reverse().map((el, index) => ({
        ...el,
        index: index + 1,
        timestamp: new Date(
          moment(el.tm.split(" ")[0], "HH:mm:ss").toISOString()
        ).getTime()
      }));

      setTracking({
        ...data,
        tracker_logs
      });

      if (init) {
        const logs_x = tracker_logs.reduce(
          (prev, cur) => [...prev, cur.pos_x],
          []
        );
        const logs_y = tracker_logs.reduce(
          (prev, cur) => [...prev, cur.pos_y],
          []
        );

        setCenter({
          lat: tracker_logs.length
            ? (Math.max(...logs_x) + Math.min(...logs_x)) / 2
            : -33.84534798734395,
          lng: tracker_logs.length
            ? (Math.max(...logs_y) + Math.min(...logs_y)) / 2
            : 151.1761809125058
        });
      }
    } catch (e) {
      setCenter({
        lat: -33.84534798734395,
        lng: 151.1761809125058
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (order) getTracking(true);
    if (order.status === EBookingType.BEGIN) {
      interval = setInterval(() => {
        getTracking();
      }, 5000);
    }
    return () => {
      clearInterval(interval);
    };
  }, []);
  if (!order) return <></>;

  const scrollToLog = (index: number): void => {
    const log = document.getElementById(`log_${index}`);
    log?.scrollIntoView({ behavior: "smooth" });
  };

  const moveMap = (lat: number, lng: number, zoom: number): void => {
    setCenter({
      lat,
      lng
    });
    setZoom(zoom);
  };
  const Marker = ({ className, image, index }: IMarkerProps): JSX.Element => (
    <div
      className={cn(
        image ? styles.marker__car : styles.marker,
        !image && className
      )}
      onMouseUp={() => {
        if (!index) return;
        scrollToLog(index);
      }}
      onMouseLeave={clearHovered}
      onMouseEnter={(e) => {
        if (!index) return;
        setHovered([
          {
            start: index,
            stop: index
          }
        ]);
      }}
    >
      {image ? <img src={image} alt="marker" /> : null}
    </div>
  );

  const onClearFilter = (): void => {
    setDateFrom(undefined);
    setDateTo(undefined);
    setFilteredLogs([]);
    setIsSearchEmpty(false);
  };

  const onSearch = (): void => {
    const start = dateFrom
      ? new Date(
          moment(
            `${dateFrom.hour}:${dateFrom.minute} ${dateFrom.dayTime}`,
            "hh:mm A"
          ).toISOString()
        ).getTime() - 15000
      : undefined;
    const stop = dateTo
      ? new Date(
          moment(
            `${dateTo.hour}:${dateTo.minute} ${dateTo.dayTime}`,
            "hh:mm A"
          ).toISOString()
        ).getTime() + 15000
      : undefined;

    const logs = tracking?.tracker_logs.filter((el) => {
      return (
        (start && el.timestamp ? el.timestamp > start : true) &&
        (stop && el.timestamp ? el.timestamp < stop : true)
      );
    });

    setIsSearchEmpty(logs?.length === 0);

    setFilteredLogs(logs ?? []);
  };

  return (
    <ModalLayout
      className={styles.order}
      headerClassName={styles.order__header}
      type={EModalTypes.TRACK_ORDER}
      size="lg"
      title={`Track order #${order.oid}`}
    >
      <div className={styles.order__subtitle}>
        Driver position{" "}
        {`${tracking ? `(${getFullUserName(tracking?.driver)})` : ""}`}{" "}
      </div>
      <SelectList
        className={styles.order__select}
        list={
          tracking?.tracker_logs.find((el) => el.type === 4)
            ? list
            : list.slice(0, 3)
        }
        active={position}
        setActive={setPosition}
        overflow="unset"
        onClick={(e) => {
          const logs = tracking?.tracker_logs ?? [];
          const id = e.currentTarget.id;
          const item = logs.find((el) => el.type === Number(id));
          if (!item) {
            return;
          }
          scrollToLog((item.index ?? 0) + (id === "3" || id === "4" ? 0 : 1));
          moveMap(item.pos_x, item.pos_y, 13);
        }}
        onMouseEnter={(e) => {
          const logs = tracking?.tracker_logs ?? [];
          const id = e.currentTarget.id;
          if (id === "1" || id === "2") {
            setHovered([
              {
                start: logs.find((el) => el.type === Number(id))?.index ?? 0,
                stop:
                  logs.find((el) => el.type === Number(id) + 1)?.index ??
                  logs.length
              }
            ]);
          }
          if (id === "3") {
            const index =
              logs.find((el) => el.type === 3)?.index ?? logs.length;
            setHovered([
              {
                start: index,
                stop: index
              }
            ]);
          }
          if (id === "4") {
            const hoveredStop: IHoveredItem[] = [];
            let tempStart: number | undefined;
            logs.forEach((el) => {
              if (el.type === 4) {
                tempStart = el.index;
              }
              if (el.type === 5) {
                hoveredStop.push({
                  start: tempStart ?? 0,
                  stop: el.index ?? logs.length
                });
                tempStart = undefined;
              }
            });
            setHovered(hoveredStop);
          }
        }}
        onMouseLeave={clearHovered}
      />
      {loading ? (
        <Dots padding />
      ) : (
        <div className={styles.order__box}>
          <div className={cn(styles["order__box-item"])}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: config.google_key }}
              center={center}
              zoom={zoom}
              yesIWantToUseGoogleMapApiInternals
            >
              {tracking?.tracker_logs.map((el) => (
                <Marker
                  key={el.index}
                  lat={el.pos_x}
                  lng={el.pos_y}
                  index={el.index ?? 0}
                  className={cn(
                    hovered.find(
                      (item) =>
                        item.start <= (el.index ?? 0) &&
                        item.stop >= (el.index ?? 0)
                    ) && styles.hovered,
                    (el.type === 1 || el.type === 3) && styles.hovered
                  )}
                />
              ))}
              {tracking?.driver_tracking?.last_gps_activity && (
                <Marker
                  lat={tracking?.driver_tracking?.last_gps_activity.pos_x}
                  lng={tracking?.driver_tracking?.last_gps_activity.pos_y}
                  image={trackingCar}
                />
              )}
              {tracking?.tracker_onsite && (
                <Marker
                  lat={tracking?.tracker_onsite.pos_x}
                  lng={tracking?.tracker_onsite.pos_y}
                  image={pickUp}
                />
              )}
              {tracking?.tracker_end && (
                <Marker
                  lat={tracking?.tracker_end.pos_x}
                  lng={tracking?.tracker_end.pos_y}
                  image={finish}
                />
              )}
            </GoogleMapReact>
          </div>
          <div className={cn(styles["order__box-item"], styles.list)}>
            {order.state_as_string === EBookingType.BEGIN ||
            order.state_as_string === EBookingType.ON_SITE ? (
              <>
                <div className={styles.order__subtitle}>Distance between:</div>
                <div className={cn(styles.order__row, styles.first)}>
                  <div className={cn(styles["order__row-item"])}>
                    Driver and Pick up
                  </div>
                  <div className={cn(styles["order__row-item"], styles.bold)}>
                    {tracking?.driver_tracking?.driver_pick_up
                      ? `${tracking?.driver_tracking.driver_pick_up} km`
                      : "n/a"}
                  </div>
                </div>
                <div className={cn(styles.order__row, styles.first)}>
                  <div className={cn(styles["order__row-item"])}>
                    Driver and destination
                  </div>
                  <div className={cn(styles["order__row-item"], styles.bold)}>
                    {tracking?.driver_tracking?.driver_destination
                      ? `${tracking?.driver_tracking.driver_destination} km`
                      : "n/a"}
                  </div>
                </div>
              </>
            ) : null}

            <div className={cn(styles.order__subtitle, styles.marginTop)}>
              GPS log:
            </div>
            <div className={styles.order__date}>
              <TimePicker
                label="from"
                value={dateFrom}
                onChange={setDateFrom}
              />
              <TimePicker label="to" value={dateTo} onChange={setDateTo} />
              <div className={styles["order__date-search"]}>
                <SearchSvg onClick={onSearch} />
                <ClearSvg onClick={onClearFilter} />
              </div>
            </div>
            <div className={styles["order__list-wrapper"]}>
              <div className={styles.order__list}>
                {!filteredLogs.length && isSearchEmpty ? (
                  <div className={styles["order__list-empty"]}>
                    No activity in the selected interval
                  </div>
                ) : (
                  (filteredLogs.length > 0
                    ? filteredLogs
                    : tracking?.tracker_logs
                  )?.map((el, index) => (
                    <div
                      className={cn(
                        styles.order__row,
                        hovered.find(
                          (item) =>
                            item.start <= (el.index ?? 0) &&
                            item.stop >= (el.index ?? 0)
                        ) && styles.hover
                      )}
                      id={`log_${el.index}`}
                      onMouseLeave={clearHovered}
                      onMouseEnter={(e) => {
                        setHovered([
                          {
                            start: el.index ?? 0,
                            stop: el.index ?? 0
                          }
                        ]);
                      }}
                      style={
                        [1, 2, 3].includes(el.type)
                          ? {
                              order: el.type
                            }
                          : {}
                      }
                      onClick={() => {
                        moveMap(el.pos_x, el.pos_y, 13);
                      }}
                      key={el.index}
                    >
                      <div
                        className={cn(
                          styles["order__row-item"],
                          el.type && styles.bold
                        )}
                      >
                        {LogType[el.type]}
                      </div>
                      <div className={cn(styles["order__row-item"])}>
                        {`${el.index}. ${el.tm}`}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <Button
        className={styles.order__btn}
        size="full"
        color="ghost"
        onClick={() => {
          modalContext?.handleClose();
        }}
      >
        Close
      </Button>
    </ModalLayout>
  );
};

export default TrackOrderModal;

export enum EBookingType {
  UPCOMING = "upcoming",
  SUSPENDED = "suspended",
  DONE = "complete",
  CANCELED = "canceled",
  BEGIN = "begin trip",
  ON_SITE = "on site",
  AUTOREPEAT = "autorepeat"
}

export enum EPaymentTypes {
  CARD = "card",
  ACCOUNT = "account"
}

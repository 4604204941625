import React, { useState } from "react";

import { Input } from "@ui";
import { ArrowLeft, SearchSvg } from "@assets/svg";

import type { IHeaderProps } from "../components.types";

import styles from "./styles.module.scss";
import { useDebounce } from "@hooks";

const Header = ({
  setSearch,
  changeTitle,
  setDriverOpen
}: IHeaderProps): JSX.Element => {
  const [showInput, setShowInput] = useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const toggleInput = (): void => {
    setShowInput((prev) => !prev);
  };

  const onChangeSearch = (e: React.FormEvent<HTMLInputElement>): void => {
    setText(e.currentTarget.value);
  };
  useDebounce(
    () => {
      setSearch(text);
    },
    text,
    1000
  );
  return (
    <div className={styles.header}>
      <div className={styles.title}>
        {changeTitle && (
          <div
            onClick={() => {
              setDriverOpen(false);
            }}
          >
            <ArrowLeft />
          </div>
        )}
        <h1>{changeTitle ? "Set driver" : "Customers"}</h1>
      </div>
      {!changeTitle && (
        <div className={styles.header__btn}>
          <SearchSvg onClick={toggleInput} />
        </div>
      )}
      {showInput && !changeTitle && (
        <Input
          className={styles.header__input}
          placeholder="Search"
          value={text}
          onChange={onChangeSearch}
          autoFocus
        />
      )}
    </div>
  );
};

export default Header;

import React from "react";

import styles from "./styles.module.scss";
import {
  CountryCodeSelectForm,
  InputLabelForm,
  InputLabel
} from "@getpickedupgpu/gpu2.shared";
import { useFormContext } from "react-hook-form";
import AutocompleteInputForm from "@modules/Booking/components/Input/AutocompleteInputForm";
import { useSelector } from "react-redux";
import { selectCountryCodes } from "@store/lookup/selector";

const Settings = (): JSX.Element => {
  const { control, watch } = useFormContext();
  const codes = useSelector(selectCountryCodes);

  const code = watch("code");
  return (
    <div className={styles.settings}>
      <InputLabelForm
        className={styles.settings__input}
        control={control}
        label="First name"
        name="first_name"
        required
        rules={{
          required: "First name is required"
        }}
      />
      <InputLabelForm
        control={control}
        className={styles.settings__input}
        label="Last name"
        name="last_name"
        required
        rules={{
          required: "Last name is required"
        }}
      />
      <div className={styles.settings__row}>
        <CountryCodeSelectForm
          control={control}
          name="code"
          initCodes={codes}
        />
        <InputLabelForm
          control={control}
          className={styles.settings__input}
          label="Office phone number"
          name="mobile"
          extra={typeof code !== "string" ? `+${code?.code ?? ""}` : ""}
          rules={{
            required: "Mobile is required",
            minLength: {
              value: 9,
              message: "Mobile should consist of at least 9 digits"
            },
            maxLength: {
              value: 10,
              message: "Mobile must have a maximum of 10 digits."
            }
          }}
        />
      </div>
      <InputLabelForm
        control={control}
        className={styles.settings__input}
        name="email"
        label="Email"
        required
        rules={{
          required: "Email is required",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid email address"
          }
        }}
      />
      <InputLabelForm
        control={control}
        className={styles.settings__input}
        label="Company name"
        name="company"
        required
        rules={{
          required: "Company name is required"
        }}
      />
      <InputLabelForm
        control={control}
        className={styles.settings__input}
        name="ABN"
        label="ABN"
        required
        rules={{
          required: "ABN is required"
        }}
      />
      <AutocompleteInputForm
        control={control}
        className={styles.settings__input}
        name="addr_streetname"
        label="Address"
        tag={InputLabel}
      />
    </div>
  );
};

export default Settings;

const env = process.env;

export const DEFAULT_URL = env.REACT_APP_DEFAULT_URL;
export const MAX_BOOKINGS_IN_ORDER = 30;

export const DATE_FORMAT = "DD-MM-YYYY";

export const DATE_TIME_FORMAT = "DD.MM.YYYY hh:mm";

export const config = {
  google_key: env.REACT_APP_GOOGLE_KEY ?? "",
  main_website: env.REACT_APP_MAIN_WEBSITE,
  contact: {
    phone: env.REACT_APP_CONTACT_PHONE,
    email: env.REACT_APP_CONTACT_EMAIL
  }
};

export const footerLinks = {
  terms: "https://gpu.travel/common/index_customerterms.php",
  faq: "https://gpu.travel/common/index_faq.php",
  support: "https://gpu.travel/common/contactus",
  services: "https://gpu.travel/common/business"
};

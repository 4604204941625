import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAMES } from "../actionTypes";
import {
  getCarTypes,
  getCitites,
  getCountries,
  getCountryCodes
} from "./actions";

const initialState: LookupState = {
  cities: [],
  countries: [],
  cartypes: [],
  countryCodes: [],
  carTypesLoading: true
};

const slice = createSlice({
  name: SLICE_NAMES.LOOKUP,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCitites.fulfilled, (state, action) => {
      state.cities = action.payload;
    });
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.countries = action.payload;
    });
    builder.addCase(getCountryCodes.fulfilled, (state, action) => {
      state.countryCodes = action.payload;
    });
    builder.addCase(getCarTypes.fulfilled, (state, action) => {
      state.cartypes = action.payload;
      state.carTypesLoading = false;
    });
  }
});
// export const { } = slice.actions;

export default slice.reducer;

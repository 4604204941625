import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "@utils";

export const getAgent = createAsyncThunk(
  "agent/get",
  async (initOrders?: string) => {
    const response = await axiosInstance.get(`agents/current`);
    return { ...response.data, initOrders };
  }
);

export const getAgentLogo = createAsyncThunk(
  "agent-logo/get",
  async (userId: number | string = "current") => {
    const response = await axiosInstance.get(`agent-logos/${userId}`);
    return response.data;
  }
);
export const logout = createAsyncThunk("agent/logout", async () => {
  const response = await axiosInstance.delete(`agents/token`);
  return response.data;
});

export const getAgentPreferredDrivers = createAsyncThunk(
  "agent-preferred-driver/get",
  async (aid: number) => {
    const response = await axiosInstance.get(
      `agents/${aid}/customers-preferred-drivers`
    );
    return response.data;
  }
);
export const getAgentExcludedDrivers = createAsyncThunk(
  "agent-excluded-driver/get",
  async (aid: number) => {
    const response = await axiosInstance.get(
      `agents/${aid}/customers-excluded-drivers`
    );
    return response.data;
  }
);

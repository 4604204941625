import React, { useState } from "react";
import cn from "classnames";

import styles from "./styles.module.scss";
import type { CollapsedBlockProps } from "@ui/ui.types";
import { ArrowSvg } from "@assets/svg";
import { toast } from "react-toastify";
const CollapsedBlock = ({ title, block }: CollapsedBlockProps): JSX.Element => {
  const [show, setShow] = useState<boolean>(false);
  const toggleShow = (): void => {
    toast.info("Soon");
    // setShow((prev) => !prev);
    setShow(false);
  };
  return (
    <div className={styles.collapse}>
      <div
        className={cn(styles.collapse__title, show && styles.active)}
        onClick={toggleShow}
      >
        <h1> {title}</h1>
        <ArrowSvg />
      </div>
      {show ? <div className={styles.collapse__block}>{block}</div> : null}
    </div>
  );
};

export default CollapsedBlock;

/* eslint-disable @typescript-eslint/consistent-type-assertions */
import styles from "./styles.module.scss";
import type { IListProps } from "../widgets.types";
import { toast } from "react-toastify";

import {
  useState,
  type Dispatch,
  type SetStateAction,
  useContext
} from "react";
import { SetMobileDriver } from "@modules/Customers/components";
import { EDriver } from "@interfaces";
import type { ICustomer, IDriver } from "@interfaces";
import { axiosInstance, getFullUserName } from "@utils";
import { ClearSvg } from "@assets/svg";
import { ModalContext } from "@contexts";
import { EModalTypes } from "@constants/modal";
import { InfiniteScroll } from "@layouts";

interface IMobileListProps extends IListProps {
  setDriverOpen: Dispatch<SetStateAction<boolean>>;
  isDriverOpen: boolean;
}

export default function MobileList({
  data,
  loading,
  nextPage,
  hasMore,
  setData,
  isDriverOpen,
  setDriverOpen
}: IMobileListProps): JSX.Element {
  const modalContext = useContext(ModalContext);

  const [customer, setCustomer] = useState<ICustomer>();

  const deleteDriver = async (
    type: EDriver,
    customer: ICustomer,
    did: number
  ): Promise<void> => {
    const driverType = `${type as string}_drivers`;
    try {
      setData(
        data.map((el: ICustomer) =>
          el.cid === customer.cid
            ? {
                ...el,
                [driverType]: el[driverType]?.filter(
                  (driver: IDriver) => driver.did !== did
                )
              }
            : el
        )
      );
      setCustomer({
        ...customer,
        [driverType]: customer[driverType]?.filter(
          (driver: IDriver) => driver.did !== did
        )
      });
      await axiosInstance.delete(
        `customer/${customer.cid}/${`${type as string}-driver`}/${did}`
      );
    } catch (e) {
      toast.error(`Something went wrong`);
    }
  };

  const addDriver = (
    type: EDriver,
    customer: ICustomer,
    driver: IDriver
  ): void => {
    const driverType = `${type as string}_drivers`;

    setData(
      data.map((el) =>
        el.cid === customer.cid &&
        el[driverType].find(
          (currentDriver: IDriver) => currentDriver.did === driver.did
        ) === undefined
          ? {
              ...el,
              [driverType]: [...el[driverType], driver]
            }
          : el
      )
    );
    const updatedCustomer =
      customer[driverType].find(
        (currentDriver: IDriver) => currentDriver.did === driver.did
      ) === undefined
        ? {
            ...customer,
            [driverType]: [...customer[driverType], driver]
          }
        : customer;
    setCustomer(updatedCustomer);
  };

  const deleteCustomer = async (customer: ICustomer): Promise<void> => {
    try {
      await axiosInstance.delete(`customers/${customer.cid}`);
      setData(data.filter((el) => el.cid !== customer.cid));
      toast.success("Deleted");
    } catch (e) {
      toast.error(`Something went wrong`);
    }
  };

  const onUpdateCustomer = (customer: ICustomer): void => {
    setData((prev) =>
      prev.map((el) => (el.cid === customer.cid ? customer : el))
    );
  };

  return (
    <div className={styles.container}>
      {!isDriverOpen ? (
        <div className={styles.list} id="mobile_list">
          <InfiniteScroll
            length={data.length}
            hasMore={hasMore}
            getData={nextPage}
            scrollableTarget="mobile_list"
          >
            {data.map((customer) => (
              <div
                key={customer.cid}
                className={styles.list__item}
                onClick={(e) => {
                  modalContext?.handleOpen(EModalTypes.EDIT_CUSTOMER, e, {
                    customer,
                    onUpdateCustomer
                  });
                }}
              >
                <div>
                  <div className={styles.list__item__name}>
                    {customer.first_name} {customer.last_name}{" "}
                    <ClearSvg
                      className={styles.list__clear}
                      onClick={(e) => {
                        modalContext?.handleOpen(EModalTypes.AGREE, e, {
                          onConfirm: (): void => {
                            void deleteCustomer(customer);
                          },
                          title: `Are you sure to delete ${getFullUserName(
                            customer
                          )} and all related data?`
                        });
                      }}
                    />
                  </div>
                  <div className={styles.list__item__phone}>
                    +{customer.mobile_country_code} {customer.mobile}
                  </div>
                  {customer.credit_card !== null ? (
                    <div className={styles.list__item__card}>
                      {customer.credit_card?.number}
                    </div>
                  ) : (
                    <div
                      className={styles.list__item__card}
                      onClick={(e) => {
                        e.stopPropagation();
                        toast.info("Coming soon!");
                      }}
                    >
                      Set payment method
                    </div>
                  )}
                </div>
                <div>
                  <p
                    className={styles.list__item__set_driver}
                    onClick={(e) => {
                      e.stopPropagation();
                      setDriverOpen(true);
                      setCustomer(customer);
                    }}
                  >
                    Set driver
                  </p>
                  {!!customer.preferred_drivers?.length && (
                    <p className={styles.list__item__count}>
                      {customer.preferred_drivers.length}
                      {customer.preferred_drivers.length !== 1
                        ? " preferred drivers"
                        : " preferred driver"}
                    </p>
                  )}
                  {!!customer.excluded_drivers?.length && (
                    <p className={styles.list__item__count}>
                      {customer.excluded_drivers.length}{" "}
                      {customer.excluded_drivers.length !== 1
                        ? " excluded drivers"
                        : " excluded driver"}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      ) : (
        <div className={styles.set_driver_container}>
          <div className={styles.prefered_driver}>
            <SetMobileDriver
              type={EDriver.preferred}
              customer={customer ?? ({} as ICustomer)}
              addDriver={addDriver}
              placeholder="Preferred driver"
            />
          </div>

          <div className={styles.chip_wrap}>
            {customer?.preferred_drivers.map((driver: IDriver) => {
              return (
                <p className={styles.btn} key={driver.did}>
                  {driver.display_name ?? getFullUserName(driver)}
                  <ClearSvg
                    className={styles.list__clear}
                    onClick={(e) => {
                      modalContext?.handleOpen(EModalTypes.AGREE, e, {
                        onConfirm: (): void => {
                          void deleteDriver(
                            EDriver.preferred,
                            customer,
                            driver.did
                          );
                        },
                        title: `Are you sure you want to delete ${
                          driver.display_name ?? getFullUserName(driver)
                        } from the preferred driver list?`
                      });
                    }}
                  />
                </p>
              );
            })}
          </div>
          <div className={styles.divider}></div>
          <div>
            <div className={styles.prefered_driver}>
              <SetMobileDriver
                type={EDriver.excluded}
                customer={customer ?? ({} as ICustomer)}
                addDriver={addDriver}
                placeholder="Exclude driver"
              />
            </div>

            <div className={styles.chip_wrap}>
              {customer?.excluded_drivers.map((driver: IDriver) => {
                return (
                  <p className={styles.btn} key={driver.did}>
                    {driver.display_name ?? getFullUserName(driver)}
                    <ClearSvg
                      className={styles.list__clear}
                      onClick={(e) => {
                        modalContext?.handleOpen(EModalTypes.AGREE, e, {
                          onConfirm: (): void => {
                            void deleteDriver(
                              EDriver.excluded,
                              customer,
                              driver.did
                            );
                          },
                          title: `Are you sure you want to delete ${
                            driver.display_name ?? getFullUserName(driver)
                          } from the preferred driver list?`
                        });
                      }}
                    />
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import { useContext } from "react";
import type { SyntheticEvent } from "react";
import Modal from "react-modal";
import cn from "classnames";

import type { IModalLayoutProps } from "../layout.types";
import { ModalContext } from "@contexts";
import { CloseSvg } from "@assets/svg";

import styles from "./styles.module.scss";

const ModalLayout = ({
  children,
  size = "md",
  type,
  title,
  close = true,
  className,
  headerClassName
}: IModalLayoutProps): JSX.Element => {
  const modalContext = useContext(ModalContext);
  const modalIsOpen = modalContext?.modalType === type;

  const closeModal = (e: SyntheticEvent): void => {
    modalContext?.handleClose(e);
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className={cn(styles.modal, styles[size], className)}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
    >
      {close ? (
        <div className={cn(styles.modal__header, headerClassName)}>
          <div className={styles["modal__header-title"]}>{title}</div>
          <CloseSvg className={styles.modal__close} onClick={closeModal} />
        </div>
      ) : null}

      {children}
    </Modal>
  );
};

export default ModalLayout;

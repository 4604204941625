import React, { useEffect } from "react";

import { OrderCreate } from "@getpickedupgpu/gpu2.shared";
import { useSelector } from "react-redux";
import { selectUser } from "@store/user/selector";
import { selectRefreshCount } from "@store/booking/selector";
import { WelcomeBack } from "@components";
import { selectCarTypes, selectCarTypesLoading } from "@store/lookup/selector";
import { useNavigate } from "react-router-dom";
import { HISTORY_ROUTE } from "@constants/routes";
import { DEFAULT_URL, config } from "@constants/config";
import { useAppDispatch } from "@hooks";
import { updateUser } from "@store/user/reducer";

const BookingPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const refreshCount = useSelector(selectRefreshCount);
  const cars = useSelector(selectCarTypes);
  const carTypesLoading = useSelector(selectCarTypesLoading);

  useEffect(() => {
    dispatch(
      updateUser({
        initOrders: undefined
      })
    );
  }, []);
  return (
    <OrderCreate
      user={user}
      refreshCount={refreshCount}
      onUpdate={() => {
        navigate(`/${HISTORY_ROUTE}`);
      }}
      extra={<WelcomeBack />}
      cars={cars}
      carTypesLoading={carTypesLoading}
      config={{
        ...config,
        default_url: DEFAULT_URL
      }}
      initOrders={user?.initOrders}
    />
  );
};

export default BookingPage;

import React from "react";
import { useField } from "formik";

import { type TDateCalendar } from "@interfaces";
import DatePicker from ".";
import { type IDatePickerFormikProps } from "@ui/ui.types";

const DatePickerFormik = ({
  name,
  error,
  ...rest
}: IDatePickerFormikProps): JSX.Element => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(name);

  const onChange = (date: TDateCalendar): void => {
    helpers.setValue(date);
  };
  return (
    <DatePicker
      {...rest}
      value={field.value}
      onChange={onChange}
      // eslint-disable-next-line
      error={error || (Boolean(meta.error) && meta.touched)}
      showNeighboringMonth
    />
  );
};

export default DatePickerFormik;

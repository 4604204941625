import cn from "classnames";

import React, { useEffect, useRef, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import Input from ".";
import type { IAutocompleteInputProps } from "../components.types";
import { useDebounce } from "@hooks";

import styles from "./styles.module.scss";
import { type UseControllerProps, useController } from "react-hook-form";
import { config } from "@constants/config";
import { Dots } from "@ui/Loader";

const autocompleteOptions: google.maps.places.AutocompletionRequest = {
  types: [
    "point_of_interest",
    "premise",
    "subpremise",
    "route",
    "street_number"
  ],
  componentRestrictions: { country: "AU" },
  input: ""
};

const AutocompleteInput = ({
  name,
  tag,
  onChoose,
  error,
  scrollOnError,
  control,
  ...rest
}: // eslint-disable-next-line
UseControllerProps<any> & Partial<IAutocompleteInputProps>): JSX.Element => {
  const Tag = tag ?? Input;

  // eslint-disable-next-line
  const { field, fieldState } = useController<any>({
    control,
    name
  });

  const ref = useRef<HTMLInputElement>();
  // eslint-disable-next-line
  const [search, setSearch] = useState<string>(
    field.value?.formatted_address ?? field.value ?? ""
  );

  const [show, setShow] = useState<boolean>(false);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading
  } = usePlacesService({
    apiKey: config.google_key,

    language: "en-AU",
    libraries: ["places"]
    // options: autocompleteOptions
  });

  const onFocus = (): void => {
    setShow(true);
  };
  const onBlur = (): void => {
    setShow(false);
  };

  const onItemClick = (
    place: google.maps.places.AutocompletePrediction
  ): void => {
    setShow(false);

    placesService?.getDetails(
      {
        placeId: place.place_id ?? "",
        fields: [
          "name",
          "geometry.location",
          "place_id",
          "formatted_address",
          "address_components",
          "utc_offset_minutes",
          "type"
        ]
      },
      (placeDetails) => {
        if (!placeDetails) return;
        const result = {
          ...placeDetails,
          formatted_address: place.description?.replace(", Australia", "") ?? ""
        };

        setSearch(result.formatted_address);
        if (typeof onChoose === "function") {
          onChoose(result, name);
        }
        field.onChange(result);
      }
    );
  };

  useDebounce(() => {
    if (search && search !== (field.value?.formatted_address ?? field.value)) {
      getPlacePredictions({ ...autocompleteOptions, input: search });
    }
    if (!search) {
      field.onChange(null);
    }
  }, search);

  useEffect(() => {
    if (error && scrollOnError) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [error]);
  useEffect(() => {
    setSearch(field.value?.formatted_address ?? field.value ?? "");
  }, [
    field.value?.formatted_address ? field.value.formatted_address : field.value
  ]);

  return (
    <Tag
      ref={ref}
      name={name}
      id={name}
      value={search}
      setValue={setSearch}
      placeholder=""
      {...rest}
      error={
        tag
          ? fieldState.error?.message ?? error
          : (Boolean(fieldState.error?.message) && fieldState.isTouched) ||
            error
      }
      onFocus={onFocus}
      onBlur={onBlur}
      autoComplete="off"
      select={
        <div className={cn(styles.select, show && styles.show)}>
          {isPlacePredictionsLoading ? (
            <Dots padding />
          ) : (
            <div className={styles.select__list}>
              {placePredictions.map((place) => (
                <div
                  className={styles["select__list-item"]}
                  key={place.place_id}
                  onMouseDown={(e) => {
                    onItemClick(place);
                  }}
                >
                  {place.description.replace(", Australia", "")}
                </div>
              ))}
            </div>
          )}
        </div>
      }
    />
  );
};

export default AutocompleteInput;

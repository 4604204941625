import React, { useState } from "react";
import cn from "classnames";

import type { ISetDriverProps } from "../components.types";
import type { IDriver } from "@interfaces";

import { SearchList } from "@ui";

import styles from "./styles.module.scss";
import { axiosInstance } from "@utils";
import { useOutsideClick } from "@hooks";
import { toast } from "react-toastify";

const SetDriver = ({
  type,
  customer,
  addDriver
}: ISetDriverProps): JSX.Element => {
  const [showList, setShowList] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  const toggleShowList = (): void => {
    setShowList((prev) => !prev);
  };

  const onClick = async (driver: IDriver): Promise<void> => {
    try {
      await axiosInstance.post(
        `customer/${customer.cid.toString()}/${type}-driver/${driver.did}`
      );
      addDriver(type, customer, driver);
    } catch (e) {
      toast.error(e.data?.message ?? "Something went wrong");
    }
    toggleShowList();
  };

  const onEmpty = (search: string): void => {
    if (!search) {
      setIsEmpty(true);
    }
  };

  const ref = useOutsideClick(toggleShowList, showList);
  return (
    <div className={styles.driver} ref={ref}>
      <div className={styles.driver__btn} onClick={toggleShowList}>{`Set ${
        type as string
      } Driver`}</div>
      {showList && !isEmpty ? (
        <SearchList<IDriver>
          className={styles.driver__list}
          type={`customer/${customer.cid}/suggest-${type}-drivers`}
          onClick={onClick}
          onEmpty={onEmpty}
        />
      ) : showList ? (
        <div className={cn(styles.driver__list, styles.driver__empty)}>
          This customer has had no bookings yet. <span>{type}</span> driver can
          only be selected from drivers of previous bookings.
        </div>
      ) : null}
    </div>
  );
};

export default SetDriver;

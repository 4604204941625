import React, { type ReactNode } from "react";
import cn from "classnames";

import type { IOrderInfoProps } from "../components.types";
import type { IOrder } from "@interfaces";
import { BagSvg, ChildSvg, UserSvg } from "@assets/svg";
import { getFullUserMobile, getFullUserName } from "@utils";
import styles from "./styles.module.scss";

const cols = [
  {
    title: "Agent",
    size: "md",
    render: (order: IOrder): ReactNode => (
      <>
        <p className={styles["table__row-text"]}>
          {getFullUserName(order.agent)}
        </p>
        <p className={styles["table__row-add"]}>
          {getFullUserMobile(order.agent)}
        </p>
      </>
    )
  },
  {
    title: "Customer",
    size: "md",
    render: (order: IOrder): ReactNode => (
      <>
        <p className={styles["table__row-text"]}>
          {getFullUserName(order.customer)}
        </p>
        <p className={styles["table__row-add"]}>
          {getFullUserMobile(order.customer)}
        </p>
        <p className={styles["table__row-add"]}>{order.customer?.email}</p>
      </>
    )
  },
  {
    title: "From/to",
    size: "xlg",
    render: (order: IOrder): ReactNode => (
      <ul>
        <li>{order.from_address}</li>
        <li>{order.to_address}</li>
        {order.stops.length > 0
          ? order.stops.map((stop) => (
              <li key={stop.place}>Stop: {stop.place}</li>
            ))
          : null}
      </ul>
    )
  },
  {
    title: "Vehicle",
    size: "lg",
    render: (order: IOrder): ReactNode => (
      <>
        <p className={styles["table__row-text"]}>{order.cartype.title}</p>
        <div className={styles["table__row-list"]}>
          <span>
            <UserSvg /> {order.pass_num}
          </span>
          <span>
            <BagSvg /> {order.luggage}
          </span>
          <span>
            <ChildSvg /> {order.baby_seat.match(/[0-9]+/g)?.length ?? 0}
          </span>
        </div>
      </>
    )
  },
  {
    title: "Cancellation Cost",
    size: "sm",
    render: (order: IOrder): ReactNode => (
      <>
        <p className={cn(styles["table__row-text"], styles.underline)}>
          $ {order.cost}
        </p>
        <p className={styles["table__row-add"]}>{order.payment_type}</p>
      </>
    )
  }
];
const OrderInfo = ({ order, className }: IOrderInfoProps): JSX.Element => {
  return (
    <div className={cn(styles.table, className)}>
      <div className={styles.table__header}>
        {cols.map((el) => (
          <div
            key={el.title}
            className={cn(styles["table__header-item"], styles[el.size])}
          >
            {el.title}
          </div>
        ))}
      </div>
      <div className={styles.table__info}>
        <div className={styles["table__info-date"]}>{order.tm_as_string}</div>
        <div className={styles["table__info-number"]}>№{order.oid}</div>
      </div>
      <div className={styles.table__row}>
        {cols.map((el) => (
          <div
            key={el.title}
            className={cn(styles["table__row-item"], styles[el.size])}
          >
            {el.render(order)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderInfo;

import React, { forwardRef } from "react";
import cn from "classnames";
import ReactCalendar from "react-calendar";

import { ArrowSvg } from "@assets/svg";

import type { ICalendarProps } from "@ui/ui.types";

import styles from "./styles.module.scss";
import "./calendar.scss";
import moment from "moment";

// eslint-disable-next-line
const Calendar = forwardRef<any, ICalendarProps>(
  (
    {
      className,
      date,
      onChange,
      selectRange = false,
      showNeighboringMonth = false,
      minDate = moment().subtract(12, "hours").toDate()
    },
    ref
  ): JSX.Element => {
    return (
      <ReactCalendar
        className={cn(styles.calendar, className)}
        prevLabel={<ArrowSvg />}
        nextLabel={<ArrowSvg />}
        value={date}
        onChange={(date) => {
          if (onChange !== undefined) onChange(date);
        }}
        ref={ref}
        locale="en"
        showNeighboringMonth={showNeighboringMonth}
        selectRange={selectRange}
        minDate={minDate}
      />
    );
  }
);

export default Calendar;

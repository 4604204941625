import React from "react";
import { Field, useField } from "formik";
import type { IInputFormikProps } from "@ui/ui.types";
import Input from "./index";

const InputFormik = ({ name, ...rest }: IInputFormikProps): JSX.Element => {
  const [field, meta] = useField(name);

  return (
    <Input
      asTag={Field}
      name={field.name}
      error={meta.error}
      touched={meta.touched}
      {...rest}
    />
  );
};

export default InputFormik;

/* eslint-disable @typescript-eslint/restrict-plus-operands */

import type { ISignupAddress } from "@interfaces";

export const getAddress = (place: google.maps.places.PlaceResult): string => {
  let street_number = "-";
  let route = "-";
  let locality = "-";
  let postal_code = "-";
  let sublocality = "-";
  let administrative_area_level_1 = "-";
  let country = "-";

  let from_addr = "";

  if (
    place.address_components === undefined ||
    place.geometry?.location === undefined
  ) {
    return "";
  }

  for (let i = 0; i < place.address_components.length; i++) {
    for (let j = 0; j < place.address_components[i].types.length; j++) {
      switch (place.address_components[i].types[j]) {
        case "street_number":
          street_number = place.address_components[i].long_name;
          break;
        case "route":
          route = place.address_components[i].long_name;
          break;
        case "locality":
          locality = place.address_components[i].long_name;
          break;
        case "postal_code":
          postal_code = place.address_components[i].long_name;
          break;
        case "sublocality":
          sublocality = place.address_components[i].long_name;
          break;
        case "administrative_area_level_1":
          administrative_area_level_1 = place.address_components[i].short_name;
          break;
        case "country":
          country = place.address_components[i].long_name;
          break;
      }
    }
  }

  from_addr = `lat=${
    typeof place.geometry.location.lat === "function"
      ? place.geometry.location.lat().toString()
      : place.geometry.location.lat.toString()
  }`;
  from_addr += `*lng=${
    typeof place.geometry.location.lng === "function"
      ? place.geometry.location.lng().toString()
      : place.geometry.location.lng.toString()
  }`;
  from_addr += "*postal_code=" + postal_code;
  from_addr += "*street_number=" + street_number;
  from_addr += "*street_address=" + route;
  from_addr += "*locality=" + locality;
  from_addr += "*sublocality=" + sublocality;
  from_addr += "*administrative_area_level_1=" + administrative_area_level_1;
  from_addr += "*country=" + country;
  from_addr += "*place=" + place.formatted_address;

  return from_addr;
};

export const getAddressObj = (
  place: google.maps.places.PlaceResult
): ISignupAddress => {
  const obj: ISignupAddress = {
    addr_unit: 0,
    addr_streetno: 0,
    addr_streetname: "",
    addr_suburb: "",
    addr_postcode: 0,
    premise: "",
    subpremise: ""
  };

  if (place.address_components !== undefined) {
    for (let i = 0; i < place.address_components.length; i++) {
      for (let j = 0; j < place.address_components[i].types.length; j++) {
        switch (place.address_components[i].types[j]) {
          case "street_number":
            obj.addr_streetno = parseInt(place.address_components[i].long_name);
            break;
          case "route":
            obj.addr_streetname = place.address_components[i].long_name;
            break;
          case "locality":
            obj.addr_suburb = place.address_components[i].long_name;
            break;
          case "premise":
            obj.premise = place.address_components[i].long_name;
            break;
          case "subpremise":
            obj.subpremise = place.address_components[i].long_name;
            break;
          case "postal_code":
            obj.addr_postcode = +place.address_components[i].long_name;
            break;
        }
      }
    }
  }

  return obj;
};
export const getLocationName = (
  place: google.maps.places.PlaceResult
): string => {
  let name = "";
  let area = "";

  if (place.address_components === undefined) {
    return "";
  }
  for (let i = 0; i < place.address_components.length; i++) {
    for (let j = 0; j < place.address_components[i].types.length; j++) {
      switch (place.address_components[i].types[j]) {
        case "locality":
          name = place.address_components[i].long_name;
          break;

        case "administrative_area_level_1":
          area = place.address_components[i].short_name;
          break;
      }
    }
  }
  return `${name} ${area}`;
};

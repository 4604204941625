import { events, luggages, passengers } from "@constants/card";
import { DATE_FORMAT, config } from "@constants/config";
import { EBookingType as EOrderType } from "@constants/history";
import {
  EBookingType,
  type IBookingForm,
  type IDetailedOrder,
  type IDriver,
  type ICustomer,
  type ITimePicker,
  type ICreateBookingForm,
  type TDateCalendar,
  type IContact
} from "@interfaces";
import type { IUser } from "@store/user/type";
import moment from "moment";
import axiosInstance from "./axios";
import { getAddress } from "./location";
import {
  setAreaError,
  setServerError,
  updateBooking
} from "@store/booking/reducer";
import type { AppDispatch } from "@store";

export const getBookingLabel = (
  index: number,
  bookingType: EBookingType
): string =>
  bookingType === EBookingType.RETURN
    ? index === 0
      ? "Booking"
      : "Return"
    : bookingType === EBookingType.MULTIPLE
    ? `Booking ${index + 1}`
    : "Booking";

export const getBookingInitialValues = (
  index: number,
  bookingType: EBookingType
): IBookingForm => ({
  from: null,
  to: null,
  passengers: passengers[0],
  luggage: luggages[0],
  date: undefined,
  time: undefined,
  estimatedTime: undefined,
  stopDate: undefined,
  stopTime: undefined,
  distance: undefined,
  duration: undefined,
  stops: [],
  index,
  label: getBookingLabel(index, bookingType),
  cityGroup: undefined,
  cityGroupDest: undefined,
  flighttype: undefined,
  seats: [],
  sign: "",
  comment: "",
  flight_no: "",
  repeats: [],
  rates: [],
  event: events[0]
});

const getPlace = async (
  address: string
): Promise<google.maps.places.PlaceResult | null> => {
  const apiKey = config.google_key;
  const formattedAddress = address;

  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    formattedAddress
  )}&key=${apiKey}&language=en&components=country:AU`;

  try {
    const resp = await fetch(apiUrl);
    const data = await resp.json();
    if (data.status === "OK" && data.results.length > 0) {
      const placeResult = data.results[0];
      return placeResult;
    } else {
      console.error("Помилка отримання PlaceResult", data);
      return null;
    }
  } catch (e) {
    console.error("Помилка отримання PlaceResult", e);
  }
  return null;
};

export const getEditBookingValues = async (
  order: IDetailedOrder
): Promise<IBookingForm> => {
  const from = await getPlace(order.from_address);
  const to = await getPlace(order.to_address);
  const stops = await Promise.all(
    order.stops?.map(async (el) => await getPlace(el.place)) ?? []
  );
  const calculated = await calculateJourney(
    from?.place_id ?? "",
    to?.place_id ?? "",
    stops.map((el) => el?.place_id ?? ""),
    moment(order.tm * 1000).toDate()
  );

  const date = moment(order.tm * 1000).toISOString();
  const time = {
    hour: moment(order.tm * 1000).format("hh"),
    minute: moment(order.tm * 1000).format("mm"),
    dayTime: moment(order.tm * 1000).format("A")
  };

  return {
    from,
    to,
    stops,
    passengers: {
      value: order.pass_num.toString(),
      label: order.pass_num.toString()
    },
    luggage: {
      value: order.luggage.toString(),
      label: order.luggage.toString()
    },
    date,
    time,
    estimatedTime: order.arrival_time
      ? {
          hour: moment(order.arrival_time * 1000).format("hh"),
          minute: moment(order.arrival_time * 1000).format("mm"),
          dayTime: moment(order.arrival_time * 1000).format("A")
        }
      : calculateEstimatedTime(date, calculated[1], time, undefined)[1],
    stopDate: order.endtm ? new Date(order.endtm * 1000) : undefined,
    stopTime: order.endtm
      ? {
          hour: moment(order.endtm * 1000).format("hh"),
          minute: moment(order.endtm * 1000).format("mm"),
          dayTime: moment(order.endtm * 1000).format("A")
        }
      : undefined,
    distance: calculated[0],
    duration: calculated[1],
    index: 0,
    label: `Booking №${order.oid}`,
    cityGroup: order.city_group,
    cityGroupDest: order.city_group_dest,
    car_type: order.cartype.id,
    flighttype:
      order.flight_no !== ""
        ? {
            label: order.flight_type ?? "",
            value: order.flight_type === "Domestic" ? 0 : 1
          }
        : undefined,
    seats:
      order.baby_seat.match(/[0-9]+/g)?.map((el) => ({
        label: `${el} y.o.`,
        value: el
      })) ?? [],
    sign: order.signboard_text,
    comment: order.comments,
    flight_no: order.flight_no,
    cost: order.cost,
    old_price: order.status === EOrderType.SUSPENDED ? order.opaid : order.cost,
    agent_booking_name: order.agent_booking_name,
    agent_earnings: +order.iagent.toFixed(2),
    rates: [],
    repeats: [],
    opayment: order.payment_type?.toLowerCase(),
    event: events.find((el) => el.value === order.event) ?? events[0]
  };
};

export const getBookingTotalCost = (bookings: IBookingForm[]): string => {
  return bookings
    .reduce<number>((prev, cur) => {
      const selectedRate = cur.rates?.find((el) => el.id === cur.car_type);
      const price =
        selectedRate?.price?.total === undefined
          ? cur.cost?.replace(",", "") ?? 0
          : selectedRate?.price?.total?.replace("$", "")?.replace(",", "");

      const schedulePrice = cur.repeats.reduce<number>((prev, curSchedule) => {
        const selectedRate = curSchedule.rates?.find(
          (el) => el.id === cur.car_type
        );
        return +(
          prev +
          Number(
            selectedRate?.price?.total?.replace("$", "")?.replace(",", "") ?? 0
          )
        ).toFixed(2);
      }, 0);
      return +(prev + (Number(price) + schedulePrice)).toFixed(2);
    }, 0)
    .toFixed(2);
};
export const getBookingTotalPreCost = (bookings: IBookingForm[]): string => {
  return bookings
    .reduce<number>((prev, cur) => {
      const selectedRate = cur.rates?.find((el) => el.id === cur.car_type);
      const price = selectedRate?.price?.pre_discount ?? 0;
      const schedulePrice = cur.repeats.reduce<number>((prev, curSchedule) => {
        const selectedRate = curSchedule.rates?.find(
          (el) => el.id === cur.car_type
        );
        return +(
          prev +
          Number(
            selectedRate?.price?.total?.replace("$", "")?.replace(",", "") ?? 0
          )
        ).toFixed(2);
      }, 0);
      return +(prev + (Number(price) + schedulePrice)).toFixed(2);
    }, 0)
    .toFixed(2);
};

export const getBookingTotalFee = (bookings: IBookingForm[]): number => {
  return bookings.reduce<number>((prev, cur) => {
    const selectedRate = cur.rates?.find((el) => el.id === cur.car_type);
    const fee = selectedRate?.price?.iagent ?? 0;
    const scheduleFee = cur.repeats.reduce<number>((prev, curSchedule) => {
      const selectedRate = curSchedule.rates?.find(
        (el) => el.id === cur.car_type
      );

      return +(prev + Number(selectedRate?.price?.iagent ?? 0)).toFixed(2);
    }, 0);
    return +(prev + ((Number(fee) ?? 0) + scheduleFee)).toFixed(2);
  }, 0);
};
export const calculateDistance = async (
  origin: string,
  destination: string,
  departureTime: Date
): Promise<google.maps.DirectionsLeg> => {
  const google = window.google;
  const directionsService = new google.maps.DirectionsService();

  return await new Promise<google.maps.DirectionsLeg>((resolve, reject) => {
    directionsService.route(
      {
        origin: { placeId: origin },
        destination: { placeId: destination },
        travelMode: google.maps.TravelMode.DRIVING,
        drivingOptions: {
          departureTime,
          trafficModel: google.maps.TrafficModel.PESSIMISTIC
        },
        provideRouteAlternatives: false,
        avoidTolls: false,
        avoidHighways: false,
        unitSystem: google.maps.UnitSystem.METRIC
        // region: country_code
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK && result !== null) {
          resolve(result.routes[0].legs[0]);
        } else {
          reject(new Error("Error"));
          console.error("error fetching directions", result, status);
        }
      }
    );
  });
};

const kilometerFormatter = new Intl.NumberFormat("en-AU", {
  style: "unit",
  unit: "kilometer",
  unitDisplay: "short",
  maximumFractionDigits: 2
});

const meterFormatter = new Intl.NumberFormat("en-AU", {
  style: "unit",
  unit: "meter",
  unitDisplay: "short",
  maximumFractionDigits: 2
});

export const formatDistanse = (distance: number): string => {
  if (distance >= 1000) {
    return kilometerFormatter.format(distance / 1000);
  } else {
    return meterFormatter.format(distance);
  }
};

export const formatDuration = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  let formattedTime = "";
  if (hours > 0) {
    formattedTime += hours.toString() + " h ";
  }
  if (minutes > 0) {
    formattedTime += minutes.toString() + " m ";
  }

  return formattedTime === "" ? "0 m" : formattedTime.trim();
};

export const getFullUserName = (
  user: IUser | IDriver | ICustomer | IContact
): string => {
  if (user === null) {
    return "";
  }
  const first_name = user.first_name.toLowerCase();
  const last_name = user.last_name.toLowerCase();

  return `${`${first_name.charAt(0).toUpperCase()}${first_name.slice(
    1
  )}`} ${`${last_name.charAt(0).toUpperCase()}${last_name.slice(1)}`}`;
};

export const getFullUserMobile = (
  user: IUser | IDriver | ICustomer
): string => {
  if (user === null) {
    return "";
  }
  return `+ ${user.mobile_country_code ?? ""} ${user.mobile ?? ""}`;
};

export const get24Hour = (time: ITimePicker | undefined): number => {
  if (time === undefined) {
    return 0;
  }
  return +moment(
    `${time.hour}:${time.minute} ${time.dayTime}`,
    "h:mm A"
  ).format("HH");
};

interface IError {
  from: string | undefined;
  to: string | undefined;
  date: string | undefined;
  time: string | undefined;
  refno?: string | undefined;
  flight_no?: string | undefined;
  flight_type?: string | undefined;
}
export const getBookingError = (
  bookings: Array<IBookingForm | ICreateBookingForm>,
  hourlyHire: boolean,
  getAirportError: boolean = false,
  user: IUser | null,
  customer: ICustomer
): string[] => {
  const errorObj = bookings.reduce<IError>(
    (prev, cur) => ({
      from: prev.from === undefined && cur.from === null ? "Pickup" : prev.from,
      to: prev.to === undefined && cur.to === null ? "Destination" : prev.to,
      refno:
        prev.refno === undefined &&
        user?.booking_reference_req &&
        !customer.booking_refno
          ? "Booking Reference"
          : prev.refno,
      date:
        prev.date === undefined &&
        (cur.date === undefined || hourlyHire
          ? cur.stopDate === undefined
          : false)
          ? "Date"
          : prev.date,
      time:
        prev.time === undefined &&
        (cur.time === undefined || hourlyHire
          ? cur.stopTime === undefined
          : false)
          ? "Time"
          : prev.time,
      ...(getAirportError && {
        flight_no:
          prev.flight_no === undefined &&
          "flight_no" in cur &&
          cur.from &&
          cur.to
            ? (getPlaceType(cur.from) === "airport" ||
                getPlaceType(cur.to) === "airport") &&
              !cur.flight_no
              ? "Flight number"
              : undefined
            : undefined,
        flight_type:
          prev.flight_type === undefined &&
          "flighttype" in cur &&
          cur.from &&
          cur.to
            ? (getPlaceType(cur.from) === "airport" ||
                getPlaceType(cur.to) === "airport") &&
              !cur.flighttype
              ? "Flight type"
              : undefined
            : undefined
      })
    }),
    {
      from: undefined,
      to: undefined,
      date: undefined,
      time: undefined,
      flight_no: undefined,
      flight_type: undefined
    }
  );
  return Object.values(errorObj).filter((el) => el !== undefined);
};

type TBookDateError = "less" | "diff";
export const getBookingDateError = (
  bookings: Array<IBookingForm | ICreateBookingForm>,
  hourlyHire: boolean
): TBookDateError | undefined => {
  const now = moment();
  for (let index = 0; index < bookings.length; index++) {
    const el = bookings[index];
    if (!el.date || !el.time) {
      return undefined;
    }
    const date = moment(
      `${moment(el.date.toString()).format(DATE_FORMAT)} ${el.time?.hour}:${
        el.time.minute
      } ${el.time.dayTime}`,
      "DD-MM-YYYY hh:mm A"
    );
    const diff = date.diff(now, "hours");

    if (diff < 12) {
      return "less";
    }
    if (hourlyHire && el.stopDate && el.stopTime) {
      const endDate = moment(
        `${moment(el.stopDate.toString()).format(DATE_FORMAT)} ${
          el.stopTime?.hour
        }:${el.stopTime.minute} ${el.stopTime.dayTime}`,
        "DD-MM-YYYY hh:mm A"
      );
      const diff = endDate.diff(date, "minutes");

      if (diff <= 0) {
        return "diff";
      }
    }
  }
  return undefined;
};

export const getScheduleBookingError = (
  bookings: ICreateBookingForm[]
): boolean => {
  const dates = Array.from(
    new Set(
      bookings.map((el) => moment(el.date?.toString()).format(DATE_FORMAT))
    )
  );
  const isEmptyDate = bookings.find((el) => !el.date || !el.time);

  if (dates.length !== 1 || isEmptyDate) {
    return true;
  }
  return false;
};

export const getCardTypeUrl = (type: string): string => {
  return `https://storage.googleapis.com/gpu-static/static/card-logos/${type}.svg`;
};

export const calculateEstimatedTime = (
  date: TDateCalendar,
  duration: number,
  time: ITimePicker | undefined,
  estimatedTime: ITimePicker | undefined
): [TDateCalendar, ITimePicker] => {
  const fullDate = moment(
    `${moment(date?.toString()).format(DATE_FORMAT)} ${
      time?.hour ?? estimatedTime?.hour
    }:${time?.minute ?? estimatedTime?.minute} ${
      time?.dayTime ?? estimatedTime?.dayTime
    }`,
    `${DATE_FORMAT} hh:mm A`
  );
  const roundedMinutes = Math.round((duration / 60 + 15) / 5) * 5;

  const newDate = time
    ? fullDate.add(roundedMinutes, "minutes")
    : fullDate.subtract(roundedMinutes, "minutes");
  return [
    newDate.toISOString(),
    {
      hour: newDate.format("hh"),
      minute: newDate.format("mm"),
      dayTime: newDate.format("A")
    }
  ];
};

export const calculateJourney = async (
  from: string,
  to: string,
  stops: string[] = [],
  departureTime: Date
): Promise<number[]> => {
  const road: string[] = [from, ...stops, to];

  const roadPromises: Array<Promise<google.maps.DirectionsLeg>> = [];
  road.forEach((el, index) => {
    if (road[index + 1] !== undefined) {
      roadPromises.push(
        calculateDistance(el ?? "", road[index + 1] ?? "", departureTime)
      );
    }
  });

  try {
    const result = await Promise.all(roadPromises);

    const calculated = result.reduce(
      (prev, cur) => {
        return {
          duration: prev.duration + (cur.duration_in_traffic?.value ?? 0),
          distance: prev.distance + (cur.distance?.value ?? 0)
        };
      },
      {
        duration: 0,
        distance: 0
      }
    );
    return [calculated.distance, calculated.duration];
  } catch (e) {
    return [0, 0];
  }
};

export const getNumberCost = (cost: string): number => {
  return Number(cost.replace(",", "")) ?? 0;
};

export const getPlaceType = (
  place: google.maps.places.PlaceResult | null
): string => {
  const formattedAddress = place?.formatted_address?.toLowerCase() ?? "";
  if (
    formattedAddress.includes("airport") ||
    formattedAddress.includes("terminal")
  ) {
    return "airport";
  } else {
    return "place";
  }
};

export const getCarTypes = async (
  clientBookingId: number,
  booking: IBookingForm,
  customer: ICustomer,
  hourlyHire: boolean,
  dispatch: AppDispatch
): Promise<void> => {
  const clearBookingRates = (): void => {
    dispatch(
      updateBooking({
        index: booking.index,
        rates: [],
        car_type: undefined,
        cityGroup: undefined,
        cityGroupDest: undefined
      })
    );
  };
  try {
    const resp = await axiosInstance.post(`orders/car-types`, {
      booking_number: booking.index + 1,
      client_booking_id: clientBookingId,
      start_date: moment(booking.date?.toString()).format(DATE_FORMAT),
      start_time_hh: booking.time?.hour ? get24Hour(booking.time) : 0,
      start_time_mm: booking.time?.minute ? +booking.time.minute : 0,
      arrival_time_hh: booking.estimatedTime?.hour
        ? get24Hour(booking.estimatedTime)
        : 0,
      arrival_time_mm: booking.estimatedTime?.minute
        ? +booking.estimatedTime.minute
        : 0,
      ...(hourlyHire && {
        end_time_hh: booking.stopTime?.hour ? get24Hour(booking.stopTime) : 0,
        end_time_mm: booking.stopTime?.minute ? +booking.stopTime.minute : 0,
        end_date: moment(booking.stopDate?.toString()).format(DATE_FORMAT)
      }),
      order_pt: hourlyHire ? 1 : 0,
      total_dist_value: booking.distance,
      pickup_type: getPlaceType(booking.from),
      dest_type: getPlaceType(booking.to),
      flighttype: booking?.flighttype?.value?.toString() ?? "-",
      flight_no: booking?.flight_no ?? "",
      pass_num: booking.passengers?.value ?? 1,
      luggage: booking.luggage?.value ?? 0,
      baby_seats: booking?.seats?.length ?? 0,
      child_age1: booking?.seats?.[0]?.value ?? "",
      child_age2: booking?.seats?.[1]?.value ?? "",
      child_age3: booking?.seats?.[2]?.value ?? "",
      opayment: "card",
      pickup_addr: booking.from ? getAddress(booking.from) : "",
      dest_addr: booking.to ? getAddress(booking.to) : "",
      predefined: 0,
      event: booking.event.value,
      stops: booking.stops
        .filter((el) => el)
        .map((stop) => ({
          name: stop?.formatted_address,
          value: stop ? getAddress(stop) : ""
        })),
      agent_booking_name: "",
      ...(customer.cid !== -1 && { cid: customer.cid }),
      uncalc_tax: 0,
      schedules: !hourlyHire
        ? booking.repeats.map((el) => ({
            start_date: moment(el.date?.toString()).format(DATE_FORMAT),
            start_time_hh: el.time?.hour ? get24Hour(el.time) : 0,
            start_time_mm: el.time?.minute ? +el.time.minute : 0
          }))
        : []
    });
    dispatch(setAreaError(false));
    dispatch(setServerError(false));

    if (resp.data?.length === 0) {
      dispatch(setAreaError(true));
      clearBookingRates();
      return;
    }

    const cartypes: ICarType[] = resp?.data?.cartypes;
    if (!cartypes) {
      clearBookingRates();
      return;
    }

    dispatch(
      updateBooking({
        index: booking.index,
        rates: cartypes,
        cityGroup: resp.data.c1 ?? resp.data.c2,
        cityGroupDest: resp.data.c2,
        car_type: cartypes.find(
          (cartype: ICarType) =>
            cartype.id === (booking.car_type ?? customer.vehicle_preference)
        )
          ? booking.car_type ?? customer.vehicle_preference
          : undefined,
        repeats: booking.repeats.map((el, index) => ({
          ...el,
          rates: resp.data.shedules[index]?.cartypes,
          error: resp.data.shedules[index]?.error
        })),
        errors: undefined
      })
    );

    const section = document.getElementById("bookingList");
    section?.scrollIntoView({ behavior: "smooth", block: "nearest" });
  } catch (e) {
    console.log("[fetchRates] error:", e);
    const errors = e?.data;
    if (errors) {
      dispatch(
        updateBooking({
          index: booking.index,
          errors
        })
      );
    } else {
      dispatch(setServerError(true));
    }
    clearBookingRates();
  }
};

export const getBookingDate = (
  date: TDateCalendar,
  time?: ITimePicker
): Date => {
  const fullDate = moment(
    `${moment(date?.toString()).format(DATE_FORMAT)} ${time?.hour}:${
      time?.minute
    } ${time?.dayTime}`,
    `${DATE_FORMAT} hh:mm A`
  );

  return new Date(
    fullDate.toISOString()
    // .replace("Z", "+11:00")
  );
};

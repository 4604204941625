import queryString from "query-string";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

type IQuery = Record<string, unknown>;
export const useQuery = (): IQuery => {
  const { search } = useLocation();

  return useMemo((): IQuery => {
    return queryString.parse(search) as IQuery;
  }, [search]);
};

import { EModalTypes } from "@constants/modal";
import { ModalLayout } from "@layouts";
import React, { useContext } from "react";

import styles from "./styles.module.scss";
import { ModalContext } from "@contexts";
import { CheckSvg } from "@assets/svg";

const CancelStatusModal = (): JSX.Element => {
  const modalContext = useContext(ModalContext);
  return (
    <ModalLayout
      type={EModalTypes.CANCEL_STATUS}
      className={styles.status}
      size="md"
      title="Booking cancelled"
    >
      <div
        className={styles.status__check}
        onClick={() => {
          modalContext?.handleClose();
        }}
      >
        <CheckSvg />
      </div>
      <div className={styles.status__info}>
        Booking №{modalContext?.data ?? 0} successfully cancelled
      </div>
    </ModalLayout>
  );
};

export default CancelStatusModal;

import { createSlice } from "@reduxjs/toolkit";
import {
  getAgent,
  getAgentExcludedDrivers,
  getAgentLogo,
  getAgentPreferredDrivers,
  logout
} from "./actions";
import { SLICE_NAMES } from "../actionTypes";

import type { UserState } from "./type";
import { config } from "@constants/config";
import { removeToken } from "@utils";

const initialState: UserState = {
  user: null,
  loading: true,
  showFeedbacks: false,
  logoUrl: "",
  excludedDrivers: [],
  preferredDrivers: [],
  preferredLoading: true,
  exludedLoading: true
};

const slice = createSlice({
  name: SLICE_NAMES.MY_USER,
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    updateUser(state, action) {
      state.user = {
        ...state.user,
        ...action.payload
      };
    },
    setShowFeedbacks(state, action) {
      state.showFeedbacks = action.payload;
    },
    setAgentLogo(state, action) {
      state.logoUrl = action.payload;
    },
    pushPreferredDriver(state, action) {
      state.preferredDrivers = [...state.preferredDrivers, action.payload];
      state.preferredLoading = false;
    },
    pushExcludedDriver(state, action) {
      state.excludedDrivers = [...state.excludedDrivers, action.payload];
      state.exludedLoading = false;
    },
    removePreferredDriver(state, action) {
      state.preferredDrivers = state.preferredDrivers.filter(
        (el) => el.did !== action.payload
      );
    },
    removeExcludedDriver(state, action) {
      state.excludedDrivers = state.excludedDrivers.filter(
        (el) => el.did !== action.payload
      );
    },
    setExcludedLoading(state, action) {
      state.exludedLoading = action.payload;
    },
    setPreferredLoading(state, action) {
      state.preferredLoading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAgent.fulfilled, (state, action) => {
      state.user = action.payload;
      state.loading = false;
    });
    builder.addCase(getAgentLogo.fulfilled, (state, action) => {
      state.logoUrl = action.payload.img_url ?? "";
    });
    builder.addCase(getAgentPreferredDrivers.fulfilled, (state, action) => {
      state.preferredDrivers = action.payload;
      state.preferredLoading = false;
    });
    builder.addCase(getAgentExcludedDrivers.fulfilled, (state, action) => {
      state.excludedDrivers = action.payload;
      state.exludedLoading = false;
    });
    builder.addCase(getAgent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAgent.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(logout.fulfilled, (state) => {
      removeToken();
      window.open(config.main_website, "_self");
    });
  }
});
export const {
  setUser,
  updateUser,
  setShowFeedbacks,
  setAgentLogo,
  pushExcludedDriver,
  pushPreferredDriver,
  removeExcludedDriver,
  removePreferredDriver,
  setExcludedLoading,
  setPreferredLoading
} = slice.actions;

export default slice.reducer;

import cn from "classnames";

import { CardLayout } from "@layouts";

import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import { selectUser, selectUserLogo } from "@store/user/selector";
import { useAppDispatch, useAppSelector } from "@hooks";
import {
  selectCustomerReviewStats,
  selectStatsLoading
} from "@store/review/selector";
import { PictureSvg, UserLogo } from "@assets/svg";
import { useState, type ChangeEvent } from "react";
import { toast } from "react-toastify";
import { axiosInstance } from "@utils";
import { setAgentLogo } from "@store/user/reducer";
import { useLocation } from "react-router-dom";
import { PROFILE_ROUTE } from "@constants/routes";
import { Dots, Spinner,  } from "@ui/Loader";

const WelcomeBack = (): JSX.Element => {
  const location = useLocation();
  const [Loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const userLogo = useSelector(selectUserLogo);
  const loading = useSelector(selectStatsLoading);
  const customerReviewStats = useAppSelector(selectCustomerReviewStats);

  const isProfile = location.pathname === `/${PROFILE_ROUTE}`;
  const uploadPhoto = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const file = e.target.files?.[0];
    setLoading(true)
    try {
      if (file !== undefined && user?.id !== undefined) {
        const formData = new FormData();
        formData.append("aid", user.id.toString());
        formData.append("file", file);
        const resp = await axiosInstance.post(`agent-logos`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        dispatch(setAgentLogo(resp.data.img_url));
      }
    } catch (e) {
      toast.error(e.data?.message ?? "Someting went wrong!");
    } finally {
      setLoading(false)
    }
  };

  return (
    <CardLayout className={cn(styles.welcome, isProfile && styles.profile)}>
      <div className={styles.welcome__title}>Welcome back!</div>
      <div className={cn(styles.welcome__info, styles.bar)}>
        <input
          type="file"
          id="user-logo"
          onChange={uploadPhoto}
          disabled={!isProfile}
          className={styles.logoUploadInput}
        />
        <label
          htmlFor="user-logo"
          className={cn(
            styles["welcome__info-image"],
            userLogo === "" && styles.empty
          )}
        >
          {Loading && (
            <div className={styles.loaderContainer}>
              <Spinner size="full" color="primary" />
            </div>
          )}
          <PictureSvg className={styles["welcome__info-picture"]} />
          {userLogo === "" ? <UserLogo /> : <img src={userLogo} />}
        </label>
        <div className={styles["welcome__info-right"]}>
          <div className={styles["welcome__info-subname"]}>{user?.company}</div>
        </div>
      </div>
      {!loading ? (
        <div
          className={cn(
            styles.welcome__text,
            (user?.agent_type === 0 || user?.is_sub) &&
              customerReviewStats.all_time.total_commision !== "" &&
              styles.bar
          )}
        >
          {customerReviewStats.all_time.ratedriver !== null ? (
            <>
              Hi {user?.company}, did you know that your customers have rated
              our service an average of{" "}
              <b>{customerReviewStats.all_time.ratedriver}</b> out of <b>5</b>{" "}
              for the{" "}
              <b>
                {Intl.NumberFormat("en-AU").format(
                  customerReviewStats.all_time.order_count ?? 0
                )}
              </b>{" "}
              bookings you have made with us!
            </>
          ) : (
            <>
              Welcome Aboard! If you need any assistance when making your first
              bookings please call us on <br /> <b>1300 648 688</b> and we would
              be happy to help!
            </>
          )}
        </div>
      ) : null}

      {(user?.agent_type === 0 || user?.is_sub) &&
      customerReviewStats.all_time.total_commision !== "" ? (
        <div className={styles.welcome__total}>
          <b>
            Your total commission $
            {customerReviewStats.all_time.total_commision}
          </b>
        </div>
      ) : null}
    </CardLayout>
  );
};

export default WelcomeBack;

import React, { useRef } from "react";
import cn from "classnames";
import type { IPopupTriangleLayoutProps } from "../layout.types";

import styles from "./styles.module.scss";

const PopupTriangleLayout = ({
  className,
  children,
  popup,
  show = true,
  triangle = true,
  size = "full",
  handleMouseLeave = () => {},
  onClick = () => {},
  align = "center",
  popupClassName,
  textAlign = "textLeft",
  ...rest
}: IPopupTriangleLayoutProps): JSX.Element => {
  const timerRef = useRef<NodeJS.Timeout>();

  return (
    <div
      className={cn(styles.popup, className)}
      {...rest}
      onMouseLeave={(e) => {
        timerRef.current = setTimeout(() => {
          handleMouseLeave();
        }, 300);
        if (rest?.onMouseLeave) rest?.onMouseLeave(e);
      }}
      onMouseEnter={(e) => {
        clearTimeout(timerRef.current);
        if (rest?.onMouseEnter) rest?.onMouseEnter(e);
      }}
      onClick={onClick}
    >
      {children}
      {show ? (
        <div
          className={cn(
            styles.popup__box,
            styles[align],
            styles[size],
            styles[textAlign],
            popupClassName
          )}
        >
          {popup}
          {triangle ? (
            <div className={cn(styles.popup__triangle, styles[align])}></div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default PopupTriangleLayout;

import cn from "classnames";
import { Button } from "@ui";

import type { IFooterProps } from "../components.types";

import styles from "./styles.module.scss";
import { footerLinks } from "@constants/config";

const Footer = ({ className }: IFooterProps): JSX.Element => {
  return (
    <footer className={cn(styles.footer, className)}>
      <div className="container">
        <div className={styles.footer__box}>
          <div className={styles.footer__menu}>
            <div className={cn(styles["footer__menu-item"], styles.mobile)}>
              © 2023 GPU
            </div>
            <a
              href={footerLinks.terms}
              target="_blank"
              rel="noreferrer"
              className={cn(styles["footer__menu-item"], styles.mobile)}
            >
              terms
            </a>
            <a
              href={footerLinks.support}
              target="_blank"
              rel="noreferrer"
              className={styles["footer__menu-item"]}
            >
              Support
            </a>
            <a
              href={footerLinks.services}
              target="_blank"
              rel="noreferrer"
              className={styles["footer__menu-item"]}
            >
              services
            </a>
            <a
              href={footerLinks.faq}
              target="_blank"
              rel="noreferrer"
              className={styles["footer__menu-item"]}
            >
              faq
            </a>
          </div>
          <Button className={styles.footer__btn} color="ghost" hSize="h-esm">
            Contact us
          </Button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

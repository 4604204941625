import React from "react";

import { Reviews, Stats } from "@modules/Feedback/widgets";
import { Header } from "@modules/Feedback/components";
import { WelcomeBack } from "@components";
import { MainLayout } from "@layouts";

const FeedbackPage = (): JSX.Element => {
  return (
    <MainLayout extra={<WelcomeBack />}>
      <Stats />
      <Header />
      <Reviews />
    </MainLayout>
  );
};

export default FeedbackPage;

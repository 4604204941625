import React from "react";

import { ModalLayout } from "@layouts";
import { EModalTypes } from "@constants/modal";

import styles from "./styles.module.scss";

const BookingConditionsModal = (): JSX.Element => {
  return (
    <ModalLayout
      className={styles.conditions}
      size="sm"
      type={EModalTypes.BOOKING_CONDITIONS}
    >
      <div className={styles.conditions__suptitle}>Booking conditions</div>
      <div className={styles.conditions__bar} />
      <b>
        Kerbside Pickup Procedure GPU Ride, GPU Standard Transfer & Premium:
      </b>
      <p>Airport Pickup -</p>
      <ul>
        <li>
          ✔️ 1 hour prior, our system will SMS driver/vehicle details & on
          arrival.
        </li>
        <li>
          ✔️ Your driver will SMS or call you to confirm pickup location outside
          the terminal.
        </li>
        <li>
          ✔️ Your fare includes complimentary wait time of 10 minutes (Domestic
          Flights) and 15 minutes (International Flights) from the time your
          driver is scheduled to meet you.
        </li>
      </ul>
      <p>Non Airport Pick Up -</p>
      <ul>
        <li>
          ✔️ 1 hour prior, our system will SMS driver/vehicle details & on
          arrival.
        </li>
        <li>
          ✔️ Your driver will SMS or call you to confirm pick up location.
        </li>
        <li>✔️ Your fare includes complimentary wait time of 5 minutes.</li>
      </ul>
      <div className={styles.conditions__bar} />

      <p>Meet and Greet Pickup Procedure - </p>
      <b>Business & Executive:</b>
      <p>Airport Pickup -</p>
      <ul>
        <li>✔️ 1 hour prior, our system will SMS driver/vehicle details. </li>
        <li>
          ✔️ Your driver will meet you in the arrival hall at the chauffeur
          meeting point with Signboard with exception of Sydney Domestic Airport
          where your driver will meet you directly outside the terminal doors in
          limousine pickup area.
        </li>
        <li>
          ✔️ Your fare includes complimentary wait time of 25 minutes (Domestic
          Flights) and 45 minutes (International Flights) from flight arrival.
        </li>
      </ul>
      <div className={styles.conditions__bar} />

      <p>
        We do not charge for flight delays but will charge a reasonable fee
        should our wait time exceed the above complimentary periods. Non Airport
        Pick Up - On arrival, your driver will SMS or call you. Your fare
        includes complimentary wait time of 10 minutes.
      </p>
      <p className={styles.conditions__note}>
        Please Note: If you are unable to locate your driver at pick up point,
        first call the driver on their contact number provided or call us on
        1300 648 688 or if overseas +61285790198.
      </p>
      <div className={styles.conditions__bar} />
      <p>
        {`If you leave the pick up address without attempting to contact us we
        will deem the service a "No Show" and a full cancellation charge will
        apply.`}
      </p>
      <div className={styles.conditions__bar} />

      <b>Premium Bookings:</b>
      <ul>
        <li>
          ✔️ Drivers will be allocated the day prior to the scheduled booking.
        </li>
        <li>
          ✔️ Vehicle supplied may be a sedan or people mover vehicle If a
          booking change is required within 24 hours of the scheduled booking
          time, change requests are not able to be received via email.
        </li>
        <li>
          ✔️ Booking changes within 24 hours must be updated online or directly
          with our call centre on 1300 648 688 or if overseas +61285790198.
        </li>
      </ul>
      <div className={styles.conditions__note}>
        Please Note: Full charge applies for cancellations within 4 hours of
        booked pickup time.
      </div>
    </ModalLayout>
  );
};

export default BookingConditionsModal;

import React from "react";
import cn from "classnames";

import type { ICardLayoutProps } from "../layout.types";

import styles from "./styles.module.scss";

const CardLayout = ({
  children,
  className,
  leftBar = false,
  title = "",
  extra,
  onClick = () => {}
}: ICardLayoutProps): JSX.Element => {
  return (
    <div
      className={cn(styles.card, leftBar ? styles["card--bar"] : "", className)}
      onClick={onClick}
    >
      {(title !== "" || extra !== undefined) && (
        <div className={styles.card__header}>
          <h1>{title}</h1>
          <div className={styles["card__header-extra"]}>{extra}</div>
        </div>
      )}
      {children}
    </div>
  );
};

export default CardLayout;

import React from "react";

import { SelectList, Filter } from "@ui";

import type { IHeaderProps } from "../components.types";

import styles from "./styles.module.scss";

const Header = ({
  list,
  active,
  setActive,
  setFilter,
  filter
}: IHeaderProps): JSX.Element => {
  return (
    <div className={styles.header}>
      <SelectList list={list} active={active} setActive={setActive} />
      <Filter
        setFilter={setFilter}
        filters={["date", "amount", "bookingNumber"]}
        filter={filter}
      />
    </div>
  );
};

export default Header;

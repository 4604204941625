import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAMES } from "../actionTypes";
import { fetchcustomerReviewStats } from "./actions";

const initialState: ReviewState = {
  customerReviewStats: {
    month: {
      ratedriver: null,
      customer_count: 0
    },
    all_time: {
      ratedriver: null,
      customer_count: 0,
      order_count: 0,
      total_commision: ""
    }
  },
  search: "",
  loading: true
};

const slice = createSlice({
  name: SLICE_NAMES.REVIEW,
  initialState,
  reducers: {
    setSearch(state, action) {
      state.search = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchcustomerReviewStats.fulfilled, (state, action) => {
      state.customerReviewStats = action.payload;
      state.loading = false;
    });
  }
});

export const { setSearch } = slice.actions;

export default slice.reducer;

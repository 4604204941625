import { configureStore } from "@reduxjs/toolkit";
import { SLICE_NAMES } from "./actionTypes";
import userReducer from "./user/reducer";
import lookupReducer from "./lookup/reducer";
import reviewReducer from "./review/reducer";
import bookingReducer from "./booking/reducer";

const store = configureStore({
  reducer: {
    [SLICE_NAMES.MY_USER]: userReducer,
    [SLICE_NAMES.LOOKUP]: lookupReducer,
    [SLICE_NAMES.REVIEW]: reviewReducer,
    [SLICE_NAMES.BOOKING]: bookingReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  devTools: process.env.REACT_APP_STAGE === "development"
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export default store;

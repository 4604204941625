import React, { useEffect, useState } from "react";

import { Empty, Table } from "@ui";
import { useInfiniteFetchData, useMobileResize } from "@hooks";

import type { ITableCol } from "@ui/ui.types";
import type {
  ITransaction,
  ITransactionRender,
  ITransactionTemp
} from "@interfaces";
import type { IByCardListProps } from "../components.types";

import styles from "./styles.module.scss";
import { CardLayout, InfiniteScroll, PopupTriangleLayout } from "@layouts";
import { downloadFile } from "@utils";
import { toast } from "react-toastify";
import { PaymentMethod } from "@components";
import ByCardMobileList from "./MobileList";

const ByCardList = ({ filter }: IByCardListProps): JSX.Element => {
  const [data, setData] = useState<ITransactionRender[]>([]);
  const isMobile = useMobileResize();

  const {
    list,
    hasMore,
    loading,
    nextPage,
    clearData,
    setData: setFetchData,
    setLoading
  } = useInfiniteFetchData<ITransaction>(
    `transactions?${
      filter.dateFrom !== undefined ? `fromDate=${filter.dateFrom}` : ""
    }${filter.dateTo !== undefined ? `&toDate=${filter.dateTo}` : ""}${
      filter.amountFrom !== "" ? `&amountFrom=${filter.amountFrom}` : ""
    }${filter.amountTo !== "" ? `&amountTo=${filter.amountTo}` : ""}${
      filter.bookingNumber ? `&oid=${filter.bookingNumber}` : ""
    }`
  );

  const handleData = (): void => {
    const transactions = list.map((el) => ({
      ...el,
      date: el.tm_as_string.split(", ")[0]
    }));
    const obj = transactions.reduce<ITransactionTemp>((prev, cur) => {
      return {
        ...prev,
        [cur.date]:
          prev[cur.date] === undefined ? [cur] : [...prev[cur.date], cur]
      };
    }, {});
    setData(
      Object.entries(obj).map<ITransactionRender>(([key, value]) => ({
        date: key,
        list: value
      }))
    );
  };

  const downloadInvoice = async (invno: string): Promise<void> => {
    if (!invno) return;
    try {
      await downloadFile(`invoices/${invno}/download`, `invoice-${invno}.pdf`);
    } catch (e) {
      toast.error(e.data?.message ?? "Something went wrong");
    }
  };

  const cols: ITableCol[] = [
    {
      dataIndex: "orders",
      render: (text: string) =>
        `Booking ${text.toString().replace(/,/g, ", ")}`,
      addDataIndex: "tm_as_string"
    },
    {
      dataIndex: "cc_num",
      render: (text: string, record: ITransaction) => (
        <PaymentMethod number={text} type={record.cc_type} logo={record.logo} />
      )
    },
    {
      dataIndex: "invno",
      render: (text: string) => (
        <PopupTriangleLayout
          className={styles.byCard__popup}
          popup="Download"
          show={Boolean(text)}
        >
          <div
            className={styles.byCard__download}
            onClick={() => {
              void downloadInvoice(text);
            }}
          >
            Invoice #{text}
          </div>
        </PopupTriangleLayout>
      ),
      color: "primary"
    },
    {
      dataIndex: "amount",
      align: "right",
      addDataIndex: ["status", "str"],
      render: (text: string) => `$ ${text}`
    }
  ];
  useEffect(() => {
    handleData();
  }, [list]);

  useEffect(() => {
    clearData();

    setLoading(true);
    setFetchData([]);
    setData([]);
  }, [filter]);

  const initLoading = loading && data.length === 0;

  return (
    <div className={styles.byCard}>
      {data.length === 0 && !loading ? (
        <CardLayout>
          <Empty title="No Invoices" />
        </CardLayout>
      ) : initLoading ? (
        <Table data={[]} loading cols={cols} />
      ) : (
        <InfiniteScroll
          length={data.reduce<number>((prev, cur) => prev + cur.list.length, 0)}
          getData={() => {
            nextPage();
          }}
          hasMore={hasMore}
        >
          {data.map((el, index) => (
            <div className={styles.byCard__list} key={index}>
              <div className={styles.byCard__title}>{el.date}</div>
              {!isMobile ? (
                <Table
                  data={el.list}
                  cols={cols}
                  loading={loading}
                  nextPage={nextPage}
                  empty="No invoices"
                />
              ) : (
                <ByCardMobileList data={el.list} />
              )}
            </div>
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default ByCardList;

import React, { useState, type KeyboardEvent, useEffect } from "react";
import cn from "classnames";

import { ArrowSvg, FilterSvg, SearchSvg } from "@assets/svg";
import { Button, DatePicker, SelectList } from "..";
import { Input } from "@modules/Booking/components";

import type { IFilterProps, ISelectListItem, TFilter } from "@ui/ui.types";
import type { ICustomer, TDateCalendar } from "@interfaces";

import styles from "./styles.module.scss";
import moment from "moment";
import { DATE_FORMAT } from "@constants/config";
import { useOutsideClick } from "@hooks";
import { CustomerList } from "@components";
import { getFullUserName } from "@utils";

const list = [
  { label: "Date", value: "date" },
  { label: "Amount", value: "amount" },
  { label: "Booking number", value: "bookingNumber" },
  { label: "Booking reference", value: "refno" },
  { label: "Name", value: "customer" }
];

const Filter = ({
  setFilter,
  filters,
  filter,
  className
}: IFilterProps): JSX.Element => {
  const filteredList = list.filter((el) =>
    filters.includes(el.value as TFilter)
  );
  const [active, setActive] = useState<ISelectListItem>(filteredList[0]);
  const [show, setShow] = useState(false);

  const toggleShow = (): void => {
    setShow((prev) => !prev);
  };

  const [dateFrom, setDateFrom] = useState<TDateCalendar>();
  const [dateTo, setDateTo] = useState<TDateCalendar>();
  const [amountFrom, setAmountFrom] = useState<string>("");
  const [amountTo, setAmountTo] = useState<string>("");
  const [customer, setCustomer] = useState<ICustomer>();
  const [bookingNumber, setBookingNumber] = useState<string>("");
  const [refno, setRefno] = useState<string>("");

  const onSearch = (): void => {
    setShow(false);
    setFilter({
      dateFrom:
        dateFrom === undefined
          ? undefined
          : moment(dateFrom?.toString()).format(DATE_FORMAT),
      dateTo:
        dateTo === undefined
          ? undefined
          : moment(dateTo?.toString()).format(DATE_FORMAT),
      bookingNumber,
      amountFrom,
      amountTo,
      customer,
      refno
    });
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  const clearFilters = (): void => {
    setDateFrom(undefined);
    setDateTo(undefined);
    setAmountFrom("");
    setAmountTo("");
    setCustomer(undefined);
    setBookingNumber("");
    setRefno("");
    setFilter({
      dateFrom: undefined,
      dateTo: undefined,
      amountFrom: "",
      amountTo: "",
      customer: undefined,
      bookingNumber: "",
      refno: ""
    });
  };

  const clearByType = (): void => {
    if (active.value === "date") {
      setDateFrom(undefined);
      setDateTo(undefined);
      setFilter((prev) => ({
        ...prev,
        dateFrom: undefined,
        dateTo: undefined
      }));
    }
    if (active.value === "amount") {
      setAmountFrom("");
      setAmountTo("");
      setFilter((prev) => ({
        ...prev,
        amountFrom: "",
        amountTo: ""
      }));
    }
    if (active.value === "bookingNumber") {
      setBookingNumber("");
      setFilter((prev) => ({
        ...prev,
        bookingNumber: ""
      }));
    }
    if (active.value === "customer") {
      setCustomer(undefined);
      setFilter((prev) => ({
        ...prev,
        customer: undefined
      }));
    }
    if (active.value === "refno") {
      setRefno("");
      setFilter((prev) => ({
        ...prev,
        refno: ""
      }));
    }
  };

  // eslint-disable-next-line
  const isFilter = Boolean(
    filter.dateFrom ??
      filter.dateTo ??
      (filter.amountFrom === "" ? null : true) ??
      (filter.amountTo === "" ? null : true) ??
      filter.customer ??
      (filter.bookingNumber === "" ? null : true) ??
      filter.refno
  );

  const ref = useOutsideClick(toggleShow, show);

  useEffect(() => {
    onSearch();
  }, [customer]);

  return (
    <div
      className={cn(styles.filter, show && styles.show, className)}
      ref={ref}
    >
      <div
        className={cn(styles.filter__btn, (show || isFilter) && styles.active)}
        onClick={toggleShow}
      >
        <FilterSvg />
        <div
          className={styles.popup}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <SelectList
            className={styles.popup__list}
            itemClassName={styles["popup__list-item"]}
            list={filteredList}
            active={active}
            setActive={setActive}
          />
          {active !== null ? (
            <>
              {active?.value === "date" ? (
                <div className={styles.popup__row}>
                  <DatePicker
                    label="from"
                    value={dateFrom}
                    onChange={setDateFrom}
                    minDate={new Date(0)}
                    className={styles["popup__row-item"]}
                  />
                  <DatePicker
                    label="to"
                    value={dateTo}
                    onChange={setDateTo}
                    minDate={new Date(0)}
                    className={styles["popup__row-item"]}
                    popupClassName={styles["popup__row-item--popup"]}
                  />
                  <div className={styles.popup__search} onClick={onSearch}>
                    <SearchSvg />
                  </div>
                </div>
              ) : null}
              {active?.value === "amount" ? (
                <div className={styles.popup__row}>
                  <Input
                    className={styles.popup__select}
                    label="from"
                    type="number"
                    placeholder="Amount"
                    setValue={setAmountFrom}
                    value={amountFrom}
                    inputSize="full"
                    onKeyUp={handleKeyPress}
                  />
                  <Input
                    className={styles.popup__select}
                    label="to"
                    placeholder="Amount"
                    type="number"
                    value={amountTo}
                    onKeyUp={handleKeyPress}
                    setValue={setAmountTo}
                    inputSize="full"
                  />
                  <div className={styles.popup__search} onClick={onSearch}>
                    <SearchSvg />
                  </div>
                </div>
              ) : null}
              {active?.value === "bookingNumber" ? (
                <div className={styles.popup__input}>
                  <SearchSvg className={styles.popup__searchSvg} />
                  <input
                    type="text"
                    placeholder="Search"
                    value={bookingNumber}
                    onChange={(e) => {
                      setBookingNumber(e.target.value);
                    }}
                    onKeyUp={handleKeyPress}
                  />
                  <ArrowSvg
                    className={styles.popup__arrowSvg}
                    onClick={onSearch}
                  />
                </div>
              ) : null}
              {active?.value === "refno" ? (
                <div className={styles.popup__input}>
                  <SearchSvg className={styles.popup__searchSvg} />
                  <input
                    type="text"
                    placeholder="Search"
                    value={refno}
                    onChange={(e) => {
                      setRefno(e.target.value);
                    }}
                    onKeyUp={handleKeyPress}
                  />
                  <ArrowSvg
                    className={styles.popup__arrowSvg}
                    onClick={onSearch}
                  />
                </div>
              ) : null}
              {active?.value === "customer" ? (
                <div className={styles.popup__input}>
                  <CustomerList
                    className={styles.header__search}
                    setCustomer={(customer) => {
                      setCustomer(customer);
                    }}
                    btnExtra={<SearchSvg />}
                    align="right"
                    popup={false}
                    initValue={
                      filter.customer ? getFullUserName(filter.customer) : ""
                    }
                    setFilter={setFilter}
                  />
                </div>
              ) : null}

              <Button
                className={styles.popup__clear}
                color="none"
                onClick={clearByType}
              >
                Clear
              </Button>
            </>
          ) : null}
        </div>
      </div>

      {isFilter ? (
        <div className={styles.filter__clear} onClick={clearFilters}>
          Clear
        </div>
      ) : null}
    </div>
  );
};

export default Filter;

import { useRef, useState } from "react";
import { axiosInstance } from "@utils";
import { useDebounce } from "./useDebounce";
import axios from "axios";

interface IData<Type> {
  data: Type | undefined;
  loading: boolean;
  clearData: () => void;
}

export const useFetchData = <T>(url: string, page?: number): IData<T> => {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(true);
  const urlRef = useRef<string>(url);

  const CancelToken = axios.CancelToken;
  let cancel: () => void;

  const clearData = (): void => {
    setData(undefined);
  };

  const fetchData = async (): Promise<void> => {
    try {
      urlRef.current = url;
      setLoading(true);
      const response = await axiosInstance.get(url, {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        })
      });
      if (urlRef.current === url) {
        setData(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useDebounce(
    () => {
      if (cancel) {
        cancel();
      }
      fetchData();
    },
    url,
    100
  );

  return {
    data,
    loading,
    clearData
  };
};

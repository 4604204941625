import {
  AllBookingsSvg,
  CustomersSvg,
  FeedbackSvg,
  InvoicesSvg,
  NewBookSvg
} from "../assets/svg";

export const BOOKING_ROUTE = "booking";
export const PAYMENT_ROUTE = `${BOOKING_ROUTE}/payment`;
export const HISTORY_ROUTE = "history";
export const CUSTOMERS_ROUTE = "customers";
export const INVOICES_ROUTE = "invoices";
export const FEEDBACK_ROUTE = "feedback";
export const PROFILE_ROUTE = "profile";
export const LOGIN_ROUTE = "login";
export const SIGNUP_ROUTE = "signup";
export const AGENTS_ROUTE = "agents";

export const NAVIGATION_MENU = [
  { title: "Book Now", to: BOOKING_ROUTE, icon: NewBookSvg },
  { title: "Customers", to: CUSTOMERS_ROUTE, icon: CustomersSvg },
  { title: "All Bookings", to: HISTORY_ROUTE, icon: AllBookingsSvg },
  { title: "Invoices", to: INVOICES_ROUTE, icon: InvoicesSvg },
  { title: "Feedback", to: FEEDBACK_ROUTE, icon: FeedbackSvg },
  { title: "Agents", to: AGENTS_ROUTE, icon: CustomersSvg }
];

import { Cookies } from "react-cookie";

export const removeToken = (): void => {
  const cookies = new Cookies();
  cookies.remove("access_token");
  cookies.remove("access_token", {
    path: "/",
    domain: ".gpu.travel"
  });
};

import React, { useState, type ChangeEvent } from "react";

import styles from "./styles.module.scss";
import { ChevronRightSvg, CloseGraySvg, SearchSvg } from "@assets/svg";

import { useAppDispatch } from "@hooks";

import { setSearch } from "@store/review/reducer";

const Header = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const [isSearchInput, setSearchInput] = useState(false);
  const [searchText, setSearchText] = useState("");

  function toggleSearchPanel(): void {
    setSearchInput(!isSearchInput);
    onClear();
  }
  function onChange(e: ChangeEvent<HTMLInputElement>): void {
    setSearchText(e.target.value);
  }
  function onSearch(value: string): void {
    dispatch(setSearch(value));
  }
  function onClear(): void {
    setSearchText("");
    onSearch("");
  }
  function onClickChevronRight(): void {
    onSearch(searchText);
  }
  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>): void {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      onSearch(searchText);
    }
  }
  return (
    <div className={styles.header}>
      <h1>Customers review</h1>
      <div className={styles.header__search} onClick={toggleSearchPanel}>
        <SearchSvg />
      </div>
      {isSearchInput && (
        <div
          className={styles["header__input-block"]}
          onKeyDown={handleKeyDown}
        >
          <div className={styles["search-input"]}>
            <SearchSvg className={styles["search-input__search"]} />
            <input
              value={searchText}
              placeholder="Search by booking number or name"
              onChange={onChange}
              autoFocus
            />
            <CloseGraySvg
              className={styles["search-input__clear"]}
              onClick={onClear}
            />
          </div>
          <div className={styles.header__arrow} onClick={onClickChevronRight}>
            <ChevronRightSvg />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;

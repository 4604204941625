import React, { type SyntheticEvent, useContext, useState } from "react";

import { ModalLayout } from "@layouts";
import { EModalTypes } from "@constants/modal";
import { ModalContext } from "@contexts";
import type { IAgreeData } from "../modals.types";
import { Button } from "@ui";

import styles from "./styles.module.scss";
const AgreeModal = (): JSX.Element => {
  const modalContext = useContext(ModalContext);
  const [loading, setLoading] = useState<boolean>(false);

  const data: IAgreeData = modalContext?.data;

  const onConfirm = async (e: SyntheticEvent): Promise<void> => {
    setLoading(true);
    await data.onConfirm();
    modalContext?.handleClose(e);
  };

  return (
    <ModalLayout className={styles.agree} type={EModalTypes.AGREE} size="lg">
      <div className={styles.agree__wrapper}>
        {data.title}
        {data.extra}
        {!data.removeBtns ? (
          <div className={styles.agree__buttons}>
            <Button
              className={styles["agree__buttons--item"]}
              color="ghost"
              onClick={(e) => {
                modalContext?.handleClose(e);
              }}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              className={styles["agree__buttons--item"]}
              onClick={onConfirm}
              isLoading={loading}
            >
              Confirm
            </Button>
          </div>
        ) : null}
      </div>
    </ModalLayout>
  );
};

export default AgreeModal;

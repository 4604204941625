import * as Yup from "yup";
export const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(4, "Password must be at least 4 characters")
});

export const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  first_name: Yup.string()
    .min(2, "First name must be at least 2 characters")
    .required("First name is required"),
  last_name: Yup.string()
    .min(2, "Last name must be at least 2 characters")
    .required("Last name is required"),
  password: Yup.string()
    .required("Password is required")
    .min(4, "Password must be at least 4 characters"),
  mobile: Yup.string().min(5).required("Mobile is required"),
  company: Yup.string().min(5).required("Company is required"),
  ABN: Yup.number().required("ABN is required"),
  terms: Yup.boolean().oneOf([true], "Must be checked").required(),
  address: Yup.object().required("Address is required"),
  invoice_email: Yup.string().when(
    "use_for_invoice",
    ([use_for_invoice], schema) => {
      return !use_for_invoice
        ? schema.email("Invalid email").required("Invoice Email is required")
        : schema;
    }
  )
});

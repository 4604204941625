import React, { useEffect, useState } from "react";
import cn from "classnames";
import moment from "moment";

import styles from "./styles.module.scss";
import { type IDatePickerProps } from "@ui/ui.types";
import { Calendar } from "..";
import { ArrowSvg } from "@assets/svg";
import { useOutsideClick, usePopupDirection } from "@hooks";
const DatePicker = ({
  label,
  value,
  onChange,
  error = false,
  arrow = true,
  className,
  popupClassName,
  disabled = false,
  showNeighboringMonth = false,
  minDate,
  scrollOnError = false,
  forceBottom
}: IDatePickerProps): JSX.Element => {
  const [show, setShow] = useState<boolean>(false);

  const {
    ref: calendarRef,
    position,
    handleScroll
  } = usePopupDirection(!disabled);
  const toggleShow = (): void => {
    handleScroll();
    setShow((prev) => !prev);
  };

  const ref = useOutsideClick(toggleShow, show);

  useEffect(() => {
    if (error && scrollOnError) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [error]);

  return (
    <div className={cn(styles.picker, className)} ref={ref}>
      <div
        className={styles.picker__btn}
        onClick={toggleShow}
        ref={calendarRef}
      >
        <b className={cn(error && styles.error)}>{label}</b>
        <p className={cn(value === undefined && styles.label)}>
          {value !== undefined
            ? moment(value?.toString()).format("DD MMM")
            : "Select date"}
        </p>
      </div>
      {!disabled ? (
        <div
          className={cn(
            styles["picker__calendar-inner"],
            show && styles.active,
            styles[forceBottom ? "bottom" : position],
            popupClassName
          )}
        >
          <Calendar
            className={cn(styles.picker__calendar)}
            date={value}
            onChange={(date) => {
              toggleShow();
              onChange(date);
            }}
            selectRange={false}
            showNeighboringMonth={showNeighboringMonth}
            minDate={minDate}
          />
        </div>
      ) : null}

      {arrow ? <ArrowSvg /> : null}
    </div>
  );
};

export default DatePicker;

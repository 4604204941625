import type { ICheckboxListFormikProps, ISelectListItem } from "@ui/ui.types";
import { useField } from "formik";
import React from "react";
import CheckboxList from ".";

const CheckboxListFormik = ({
  name,
  list,
  itemClassName,
  className,
  error,
  disableUncheck
}: ICheckboxListFormikProps): JSX.Element => {
  const [field, meta, helpers] = useField(name);

  const onCheck = (value: ISelectListItem): void => {
    helpers.setValue(value);
  };
  return (
    <CheckboxList
      list={list}
      checked={field.value}
      setChecked={onCheck}
      itemClassName={itemClassName}
      error={Boolean(meta.error) || error}
      className={className}
      disableUncheck={disableUncheck}
    />
  );
};

export default CheckboxListFormik;

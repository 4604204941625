import { PaymentMethod } from "@components";
import styles from "./styles.module.scss";
import { downloadFile } from "@utils";
import { toast } from "react-toastify";

interface IMobileListProps {
  // eslint-disable-next-line
  data: any[];
}
const ByCardMobileList = ({ data }: IMobileListProps): JSX.Element => {
  const downloadInvoice = async (invno: string): Promise<void> => {
    if (!invno) return;
    try {
      await downloadFile(`invoices/${invno}/download`, `invoice-${invno}.pdf`);
    } catch (e) {
      toast.error(e.data?.message ?? "Something went wrong");
    }
  };

  return (
    <div className={styles.container}>
      {data.map((el) => (
        <div key={el.id} className={styles.item}>
          <div className={styles.item__left}>
            <span className={styles.item__title}>Booking </span>
            {el.orders.map((order: string) => (
              <span key={order}>{order}</span>
            ))}
            <p className={styles.item__date}>{el.tm_as_string}</p>
            <p
              onClick={() => {
                void downloadInvoice(el.invno);
              }}
              className={styles.item__invoice}
            >
              Invoice #{el.invno}
            </p>
          </div>
          <div className={styles.item__right}>
            <PaymentMethod
              number={el.cc_num}
              type={el.cc_type}
              logo={el.logo}
              align="right"
            />
            <p className={styles.item__amount}>$ {el.amount}</p>
            <p className={styles.item__status}>{el.status.str}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
export default ByCardMobileList;

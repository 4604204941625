import React from "react";

import { Filter, SelectList } from "@ui";

import type { IHeaderProps } from "../components.types";

import styles from "./styles.module.scss";

const Header = ({
  active,
  setActive,
  types,
  setFilter,
  filter
}: IHeaderProps): JSX.Element => {
  return (
    <div className={styles.header__inner}>
      <p className={styles.mobile_title}>All bookings</p>
      <div className={styles.header}>
        <SelectList
          className={styles.mobile__select}
          list={types}
          active={active}
          setActive={setActive}
        />
        <Filter
          className={styles.header__filter}
          setFilter={setFilter}
          filter={filter}
          filters={["date", "bookingNumber", "customer", "refno"]}
        />
      </div>
    </div>
  );
};

export default Header;

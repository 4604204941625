import { useState, createContext, useMemo } from "react";

import {
  BookingConditionsModal,
  CitiesModal,
  RepeatModal,
  BookingModal,
  SetPayModal,
  AgreeModal,
  TermsAndCondModal,
  CancelStatusModal,
  EditCustomerModal,
  TrackOrderModal,
  EditAgentModal,
  CodeConfirmModal
} from "../modals";
import { EModalTypes } from "../constants/modal";

import type { SyntheticEvent } from "react";
import type { IValue, IModalProps, TData } from "./context.types";

export const ModalContext = createContext<IValue | null>(null);

const renderModalByType = (modalType: EModalTypes): JSX.Element | null => {
  switch (modalType) {
    case EModalTypes.CITIES:
      return <CitiesModal />;
    case EModalTypes.REPEAT:
      return <RepeatModal />;
    case EModalTypes.BOOKING_CONDITIONS:
      return <BookingConditionsModal />;
    case EModalTypes.BOOKING:
      return <BookingModal />;
    case EModalTypes.SET_PAY:
      return <SetPayModal />;
    case EModalTypes.AGREE:
      return <AgreeModal />;
    case EModalTypes.TERMS_AND_CONDITIONS:
      return <TermsAndCondModal />;
    case EModalTypes.CANCEL_STATUS:
      return <CancelStatusModal />;
    case EModalTypes.EDIT_CUSTOMER:
      return <EditCustomerModal />;
    case EModalTypes.TRACK_ORDER:
      return <TrackOrderModal />;
    case EModalTypes.EDIT_AGENT:
      return <EditAgentModal />;
    case EModalTypes.CONFIRM_CODE:
      return <CodeConfirmModal />;
    default:
      return null;
  }
};

const ModalProvider = ({ children }: IModalProps): JSX.Element => {
  const [modalType, setModalType] = useState<EModalTypes | null>(null);
  const [data, setData] = useState<TData>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleClose = (event: SyntheticEvent): void => {
    event?.stopPropagation();
    setData(null);
    setLoading(false);
    setModalType(null);
  };

  const handleOpen = (
    type: EModalTypes,
    event: SyntheticEvent,
    data: TData
  ): void => {
    event?.stopPropagation();
    setModalType(type);
    data !== undefined && setData(data);
  };

  const handleOpenNoneEvent = (type: EModalTypes, data: TData): void => {
    setModalType(type);
    data !== undefined && setData(data);
  };

  const value = useMemo(
    () => ({
      modalType,
      handleOpen,
      handleOpenNoneEvent,
      handleClose,
      data,
      setData,
      loading,
      setLoading
    }),
    [modalType, loading, data]
  );

  return (
    <ModalContext.Provider value={value}>
      {children}
      {modalType && renderModalByType(modalType)}
    </ModalContext.Provider>
  );
};

export default ModalProvider;

import Skeleton from "react-loading-skeleton";
import styles from "./styles.module.scss";
import { useMobileResize } from "@hooks";

const BookingSkeleton = (): JSX.Element => {
  const isMobile = useMobileResize(425);
  return (
    <>
      {Array.from({ length: 10 }).map((el, i) => (
        <div className={styles.container} key={i}>
          <div className={styles.header}>
            <Skeleton
              width={isMobile ? "70px" : "123px"}
              height="20px"
              borderRadius="30px"
            />
            <Skeleton
              width={isMobile ? "70px" : "123px"}
              height="20px"
              borderRadius="30px"
            />
          </div>
          <div className={styles.body}>
            <Skeleton
              width={isMobile ? "200px" : "281px"}
              height="20px"
              borderRadius="30px"
            />
            <Skeleton
              width={isMobile ? "200px" : "281px"}
              height="20px"
              borderRadius="30px"
            />
            <Skeleton
              width={isMobile ? "90px" : "131px"}
              height="20px"
              borderRadius="30px"
            />
          </div>
          <div className={styles.footer}>
            <Skeleton
              width={isMobile ? "70px" : "123px"}
              height="20px"
              borderRadius="30px"
            />
            <Skeleton
              width={isMobile ? "70px" : "123px"}
              height="20px"
              borderRadius="30px"
            />
          </div>
        </div>
      ))}
    </>
  );
};
export default BookingSkeleton;

import styles from "./styles.module.scss";
import type { ISetDriverProps } from "../components.types";
import type { IDriver } from "@interfaces";
import { axiosInstance } from "@utils";
import { toast } from "react-toastify";
import MobileSearchList from "@ui/MobileSearchList";

interface ISetMobileProps extends ISetDriverProps {
  placeholder: string;
}

const SetMobileDriver = ({
  type,
  customer,
  addDriver,
  placeholder
}: ISetMobileProps): JSX.Element => {
  const onClick = async (driver: IDriver): Promise<void> => {
    try {
      await axiosInstance.post(
        `customer/${customer.cid.toString()}/${type}-driver/${driver.did}`
      );
      addDriver(type, customer, driver);
    } catch (e) {
      toast.error(e.data?.message ?? "Something went wrong");
    }
  };
  return (
    <div className={styles.driver}>
      <MobileSearchList<IDriver>
        className={styles.driver__list}
        type={`customer/${customer.cid}/suggest-${type}-drivers`}
        onClick={onClick}
        placeholder={placeholder}
        popup
        typeAsText={type}
      />
    </div>
  );
};

export default SetMobileDriver;

import React, { useState } from "react";

import { ModalLayout } from "@layouts";
import { EModalTypes } from "@constants/modal";
import { Button, Checkbox, SelectList, Switch } from "@ui";

import type { ISelectListItem } from "@ui/ui.types";

import styles from "./styles.module.scss";

const list: ISelectListItem[] = [
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
  { label: "Sunday", value: "sunday" }
];

const SetPayModal = (): JSX.Element => {
  const [active, setActive] = useState<boolean>(false);
  const [days, setDays] = useState<ISelectListItem[]>(list);
  const [check, setCheck] = useState<boolean>(false);
  return (
    <ModalLayout className={styles.setPay} type={EModalTypes.SET_PAY}>
      <Switch
        className={styles.setPay__switch}
        active={active}
        setActive={setActive}
        id="allowPay"
      >
        Allow customer payment method on account
      </Switch>
      <div className={styles.setPay__bar} />
      {active ? (
        <>
          <div className={styles.setPay__title}>
            Choose cars for payment on account
          </div>
          <div className={styles.setPay__list}>
            <Checkbox
              className={styles.setPay__check}
              label="All"
              id="all"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="Prepaid Sedan Airport Transfer "
              id="Prepaid Sedan Airport Transfer "
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="Business Sedan"
              id="Business Sedan"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="Prepaid: Minivan Airport Transfer "
              id="Prepaid: Minivan Airport Transfer "
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="Business People Mover"
              id="Business People Mover"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="Talixo - Economy Sedan"
              id="Talixo - Economy Sedan"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="Executive People Mover"
              id="Executive People Mover"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="Talixo - Business Sedan"
              id="Talixo - Business Sedan"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="GPU Premium"
              id="GPU Premium"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="Talixo - Business Van"
              id="Talixo - Business Van"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="Group People Mover"
              id="Group People Mover"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="Talixo - First Class"
              id="Talixo - First Class"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="Business Sedan (English Speaking Driver)"
              id="Business Sedan (English Speaking Driver)"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="GPU People Mover"
              id="GPU People Mover"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="MPV"
              id="MPV"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="GPU Premium"
              id="GPU Premium"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="5 Passenger MPV"
              id="5 Passenger MPV"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="GPU SUV"
              id="GPU SUV"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="GPU Ride"
              id="GPU Ride"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="GPU Assist"
              id="GPU Assist"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="Business Sedan Toronto"
              id="Business Sedan Toronto"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="Mini bus"
              id="Mini bus"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="Prepaid GPU Standart Transfer"
              id="Prepaid GPU Standart Transfer"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="GPU Green"
              id="GPU Green"
              checked={check}
              setChecked={setCheck}
            />
            <Checkbox
              className={styles.setPay__check}
              label="MPTP Eligible GPU"
              id="MPTP Eligible GPU"
              checked={check}
              setChecked={setCheck}
            />
          </div>
          <div className={styles.setPay__bar} />
          <div className={styles.setPay__title}>
            Choose days for payment on account
          </div>
          <SelectList
            list={days}
            active={days}
            setActiveList={setDays}
            multiple
            all
            theme="ghost"
          />
          <div className={styles.setPay__bar} />

          <Button className={styles.setPay__btn} size="full">
            Apply
          </Button>
        </>
      ) : null}
    </ModalLayout>
  );
};

export default SetPayModal;

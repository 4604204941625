import React, { useEffect } from "react";

import styles from "./styles.module.scss";

import { useAppSelector, useInfiniteFetchData, useMobileResize } from "@hooks";
import { InfiniteScroll } from "@layouts";

import { selectSearch } from "@store/review/selector";
import RatingWithStars from "@modules/Feedback/components/RatingWithStarts";
import { useLocation, useParams } from "react-router-dom";
const DATA_SIZE = 15;
const Reviews = (): JSX.Element => {
  const { fid } = useParams();
  const location = useLocation();

  const search = useAppSelector(selectSearch);
  const isMobile = useMobileResize();

  const { list, hasMore, nextPage, loading } =
    useInfiniteFetchData<ICustomerReview>(
      `customer-polls?${
        search !== ""
          ? isNaN(parseInt(search))
            ? `customer_name=${search}`
            : `booking_number=${search}`
          : ""
      }`,
      DATA_SIZE,
      location.state
    );

  useEffect(() => {
    if (fid) {
      const el = document.getElementById(fid);
      el?.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
      el?.classList.toggle(styles.hover);
      setTimeout(() => {
        el?.classList.toggle(styles.hover);
      }, 2000);
    }
  }, []);

  return (
    <div className={styles.reviews}>
      {!loading && list.length === 0 ? (
        <h1 className={styles["reviews__no-data"]}>
          {search.length > 0 ? "No result" : "No reviews"}
        </h1>
      ) : (
        <InfiniteScroll
          length={list.length}
          getData={() => {
            nextPage();
          }}
          hasMore={hasMore}
          loading={loading}
        >
          {list.map((el, index) => {
            const rating = isNaN(el.ratedriver / 10) ? 0 : el.ratedriver / 10;
            return (
              <div
                className={styles.review}
                style={{ borderBottomWidth: index + 1 === list.length ? 0 : 1 }}
                key={`${el.postdate}-${index}`}
                id={el.id.toString()}
              >
                <div className={styles.review__left}>
                  <div
                    className={styles.review__number}
                  >{`Booking ${el.oid}`}</div>
                  <div className={styles.review__date}>{el.postdate}</div>
                </div>
                <div className={styles.review__right}>
                  <div className={styles.review__top}>
                    <span>
                      <b className={styles["review__top-name"]}>
                        {el.customer_name}
                      </b>
                      {/* <p>28 May</p> */}
                    </span>
                    {!isMobile && (
                      <RatingWithStars
                        className={styles.review__stars}
                        value={rating}
                        id={el.id}
                      />
                    )}
                  </div>
                  <div className={styles.review__text}>{el.comments}</div>
                  {isMobile && (
                    <div className={styles.mobile_stars}>
                      <RatingWithStars
                        className={styles.review__stars}
                        value={rating}
                        id={el.id}
                      />
                    </div>
                  )}
                  {el.action_customer ? (
                    <div className={styles.review__action}>
                      <div className={styles["review__action-avatar"]}>NH</div>
                      <div className={styles["review__action-right"]}>
                        <b className={styles["review__action-title"]}>
                          GPU response
                        </b>
                        <div className={styles["review__action-text"]}>
                          {el.action_customer}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default Reviews;

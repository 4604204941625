import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "@utils";

export const getCountries = createAsyncThunk("countries/get", async () => {
  const response = await axiosInstance.get(`countries`);
  return response.data;
});
export const getCitites = createAsyncThunk("cities/get", async () => {
  const response = await axiosInstance.get(`cities`);
  return response.data;
});
export const getCarTypes = createAsyncThunk("car-types/get", async () => {
  const response = await axiosInstance.get(`car-types`);
  return response.data;
});
export const getCountryCodes = createAsyncThunk(
  "country-codes/get",
  async () => {
    const response = await axiosInstance.get(`country-codes`);
    return response.data;
  }
);

import { type ITimePicker } from "@interfaces";
import { type ITimePickerFormikProps } from "@ui/ui.types";
import React from "react";
import TimePicker from ".";
import { useField } from "formik";

const TimePickerFormik = ({
  name,
  error,
  onChange: onValueChange,
  ...rest
}: ITimePickerFormikProps): JSX.Element => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(name);

  const onChange = (date: ITimePicker): void => {
    if (onValueChange) {
      onValueChange(date);
      return;
    }
    helpers.setValue(date);
  };
  return (
    <TimePicker
      {...rest}
      value={field.value}
      onChange={onChange}
      // eslint-disable-next-line
      error={error || (Boolean(meta.error) && meta.touched)}
    />
  );
};

export default TimePickerFormik;

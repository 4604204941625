import React, { useContext } from "react";
import { Button } from "@ui";
import { ModalContext } from "@contexts";
import { EModalTypes } from "@constants/modal";
import styles from "./styles.module.scss";
import type { IAgentHeaderProps, ISubAgent } from "@interfaces";

const Header = ({ data, setData }: IAgentHeaderProps): JSX.Element => {
  const modalContext = useContext(ModalContext);

  function onAddAgent(agent: ISubAgent): void {
    setData((prev) => [...prev, agent]);
  }

  return (
    <div className={styles.container}>
      <h1>Agents</h1>
      <Button
        className={styles.add}
        hSize="h-esm"
        rounded="rounded-lg"
        color="primary-no-gradient"
        onClick={(e) => {
          modalContext?.handleOpen(EModalTypes.EDIT_AGENT, e, {
            customer: {},
            onAddAgent
          });
        }}
      >
        Add Agent
      </Button>
    </div>
  );
};

export default Header;

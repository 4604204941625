import { type ISelectListItem } from "@ui/ui.types";
import moment from "moment";

function years(count: number, startYear?: number): number[] {
  const yearList = [];
  const year = startYear ?? new Date().getFullYear();
  for (let i = 0; i < count; i += 1) {
    yearList.push(Number(year) - i);
  }
  return yearList.sort((a, b) => a - b);
}

export const months = moment.monthsShort().map((el, index) => ({
  label: el,
  value: index
}));

export const cardYears = years(100);

export const hours = Array.from(Array(12).keys()).map((el) =>
  `00${el + 1}`.slice(-2)
);
export const minutes = Array.from(Array(12).keys()).map((el) =>
  `00${el * 5}`.slice(-2)
);

export const dayTimes = ["AM", "PM"];
export const passengers: ISelectListItem[] = Array.from(Array(11).keys()).map(
  (el) => ({
    label: (el + 1).toString(),
    value: (el + 1).toString()
  })
);
export const luggages: ISelectListItem[] = Array.from(Array(9).keys()).map(
  (el) => ({
    label: el.toString(),
    value: el.toString()
  })
);

export const childAges: ISelectListItem[] = Array.from(Array(8).keys()).map(
  (el) => ({
    label: `${el} y.o.`,
    value: el
  })
);

export const events = [
  {
    label: "No",
    value: 0
  },
  {
    label: "Yes",
    value: 1
  }
];

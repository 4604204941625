import React, { useEffect } from "react";

import { Table } from "@ui";
import { useInfiniteFetchData, useMobileResize } from "@hooks";
import { downloadFile } from "@utils";

import type { ITableCol } from "@ui/ui.types";
import type { IInvoice } from "@interfaces";
import type { IByCardListProps } from "../components.types";

import styles from "./styles.module.scss";
import { PopupTriangleLayout } from "@layouts";
import { toast } from "react-toastify";
import ByAccMobileList from "./ByAccMobileList";

const ByAccList = ({ filter }: IByCardListProps): JSX.Element => {
  const isMobile = useMobileResize();

  const { list, loading, nextPage, hasMore, clearData, setData, setLoading } =
    useInfiniteFetchData<IInvoice>(
      `invoices?itype=agent${
        filter.dateFrom !== undefined ? `&fromDate=${filter.dateFrom}` : ""
      }${filter.dateTo !== undefined ? `&toDate=${filter.dateTo}` : ""}${
        filter.amountFrom !== "" ? `&fromAmount=${filter.amountFrom}` : ""
      }${filter.amountTo !== "" ? `&toAmount=${filter.amountTo}` : ""}${
        filter.bookingNumber ? `&order_id=${filter.bookingNumber}` : ""
      }`,
      30
    );

  const downloadInvoice = async (invno: string): Promise<void> => {
    if (!invno) return;
    try {
      await downloadFile(`invoices/${invno}/download`, `invoice-${invno}.pdf`);
    } catch (e) {
      toast.error(e.data?.message ?? "Something went wrong");
    }
  };

  const cols: ITableCol[] = [
    {
      dataIndex: "idate"
    },
    {
      dataIndex: "invno",
      render: (text: string) => (
        <PopupTriangleLayout
          className={styles.byAcc__popup}
          popup="Download"
          show={Boolean(text)}
        >
          <div
            className={styles.byAcc__download}
            onClick={() => {
              void downloadInvoice(text);
            }}
          >
            {" "}
            Invoice #{text}
          </div>
        </PopupTriangleLayout>
      ),
      color: "primary"
    },
    {
      dataIndex: "cost",
      align: "right",
      render: (text: string) => `$ ${text}`
    }
  ];

  useEffect(() => {
    clearData();

    setLoading(true);
    setData([]);
  }, [filter]);
  return (
    <div className={styles.byAcc}>
      {!isMobile ? (
        <Table
          data={list}
          cols={cols}
          loading={loading}
          infinite
          nextPage={nextPage}
          hasMore={hasMore}
          empty="No invoices"
        />
      ) : (
        <ByAccMobileList
          data={list}
          nextPage={nextPage}
          hasMore={hasMore}
          loading={loading}
        />
      )}
    </div>
  );
};

export default ByAccList;

import React from "react";

import styles from "./styles.module.scss";
import type { IMainLayoutProps } from "../layout.types";

const MainLayout = ({ children, extra }: IMainLayoutProps): JSX.Element => {
  return (
    <div className={styles.main}>
      <div className={styles.main__left}>{children}</div>
      <div className={styles.main__right}>{extra}</div>
    </div>
  );
};

export default MainLayout;

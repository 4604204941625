import { ModalLayout } from "@layouts";
import React, { useContext, useState } from "react";

import styles from "./styles.module.scss";
import { EModalTypes } from "@constants/modal";
import { Form, FormikProvider, useFormik } from "formik";
import type { ICustomer } from "@interfaces";
import { ModalContext } from "@contexts";
import { Button, InputLabelFormik } from "@ui";
import { toast } from "react-toastify";
import { AddSubAccountSchema, axiosInstance } from "@utils";

const EditAgentModal = (): JSX.Element => {
  const modalContext = useContext(ModalContext);
  const { agent, onAddAgent, editAgent } = modalContext?.data;
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (values: ICustomer): Promise<void> => {
    setLoading(true);

    try {
      let resp;
      if (agent === undefined) {
        resp = await axiosInstance.post(`agents/sub-account/add`, {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password
        });
        if (onAddAgent) {
          onAddAgent(resp.data.data);
        }
      } else {
        resp = await axiosInstance.put(`agents/sub-account/${values.id}`, {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password
        });
        if (editAgent) {
          editAgent(resp.data.data);
        }
      }

      const successMsg = agent === undefined ? "Added" : "Updated";
      toast.success(successMsg);
      modalContext?.handleClose();
    } catch (e) {
      toast.error(e.data?.message ?? "Something went wrong");
    }
    setLoading(false);
  };

  const formik = useFormik<ICustomer>({
    initialValues: {
      ...agent
    },
    onSubmit,
    validationSchema: AddSubAccountSchema
  });

  return (
    <ModalLayout
      className={styles.agent}
      type={EModalTypes.EDIT_AGENT}
      title={agent === undefined ? "Add Agent" : "Update Agent"}
    >
      <FormikProvider value={formik}>
        <Form className={styles.agent__form}>
          <InputLabelFormik
            className={styles["agent__form-input"]}
            label="First name"
            required
            name="first_name"
          />
          <InputLabelFormik
            className={styles["agent__form-input"]}
            label="Last name"
            name="last_name"
            required
          />

          <InputLabelFormik
            className={styles["agent__form-input"]}
            label="Email"
            name="email"
            required
          />

          <InputLabelFormik
            className={styles["agent__form-input"]}
            label="Password"
            name="password"
            type="password"
            required
          />

          <InputLabelFormik
            className={styles["agent__form-input"]}
            label="Confirm Password"
            name="password_confirmation"
            type="password"
            required
          />

          <Button
            className={styles.agent__button}
            color="ghost"
            type="submit"
            size="md"
            isLoading={loading}
          >
            {agent === undefined ? "Add" : "Update"}
          </Button>
        </Form>
      </FormikProvider>
    </ModalLayout>
  );
};

export default EditAgentModal;

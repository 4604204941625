import React, { useContext } from "react";
import { toast } from "react-toastify";

import { Table } from "@ui";
import { ClearSvg } from "@assets/svg";

import { ModalContext } from "@contexts";
import { EModalTypes } from "@constants/modal";

import type { IListProps } from "../widgets.types";
import type { ICustomer, IDriver } from "@interfaces";
import type { ReactNode } from "react";
import { EDriver } from "@interfaces";
import type { ITableCol } from "@ui/ui.types";

import styles from "./styles.module.scss";
import { axiosInstance, getFullUserMobile, getFullUserName } from "@utils";
import { SetDriver } from "@modules/Customers/components";
import { PaymentMethod } from "@components";
import type { ICard } from "interfaces/card";

const List = ({
  data,
  loading,
  nextPage,
  hasMore,
  setData
}: IListProps): JSX.Element => {
  const modalContext = useContext(ModalContext);

  const deleteDriver = async (
    type: EDriver,
    cid: number,
    did: number
  ): Promise<void> => {
    const driverType = `${type as string}_drivers`;
    try {
      setData(
        data.map((customer: ICustomer) =>
          customer.cid === cid
            ? {
                ...customer,
                [driverType]: customer[driverType]?.filter(
                  (driver: IDriver) => driver.did !== did
                )
              }
            : customer
        )
      );
      await axiosInstance.delete(
        `customer/${cid}/${`${type as string}-driver`}/${did}`
      );
    } catch (e) {
      toast.error(`Something went wrong`);
    }
  };

  const addDriver = (
    type: EDriver,
    customer: ICustomer,
    driver: IDriver
  ): void => {
    const driverType = `${type as string}_drivers`;

    setData(
      data.map((el) =>
        el.cid === customer.cid &&
        el[driverType].find(
          (currentDriver: IDriver) => currentDriver.did === driver.did
        ) === undefined
          ? {
              ...el,
              [driverType]: [...el[driverType], driver]
            }
          : el
      )
    );
  };

  const deleteCustomer = async (customer: ICustomer): Promise<void> => {
    try {
      await axiosInstance.delete(`customers/${customer.cid}`);
      setData(data.filter((el) => el.cid !== customer.cid));
      toast.success("Deleted");
    } catch (e) {
      toast.error(`Something went wrong`);
    }
  };

  const onUpdateCustomer = (customer: ICustomer): void => {
    setData((prev) => {
      return prev.map((el) =>
        el.cid === customer.cid ? { ...el, ...customer } : el
      );
    });
  };

  const cols: ITableCol[] = [
    {
      dataIndex: "first_name",
      render: (text: string, record: ICustomer) => (
        <>
          <p>{getFullUserName(record)}</p>
          <p>{getFullUserMobile(record)}</p>
          <p>{record.company_name}</p>
          <div
            className={styles.list__edit}
            onClick={(e) => {
              modalContext?.handleOpen(EModalTypes.EDIT_CUSTOMER, e, {
                customer: record,
                onUpdateCustomer
              });
            }}
          >
            Edit details
          </div>
        </>
      )
    },
    {
      dataIndex: "credit_card",
      color: "primary",
      render: (text: ICard) =>
        text ? <PaymentMethod number={text.number} type={text.type} /> : null
    },
    {
      dataIndex: "app_booking",
      btnTitle: "Set Pay by account",
      onBtnClick: (e) => toast.info("Coming Soon"),
      // onBtnClick: (e) => modalContext?.handleOpen(e, EModalTypes.SET_PAY),
      render: (text) => (text ? "Allowed" : "")
    },
    {
      dataIndex: "preferred_drivers",
      render: (text, data: ICustomer) => (
        <>
          <SetDriver
            type={EDriver.preferred}
            customer={data}
            addDriver={addDriver}
          />
          {Array.isArray(text)
            ? text.map((el: IDriver) => (
                <p className={styles.list__btn} key={el.did}>
                  {el.display_name ?? getFullUserName(el)}
                  <ClearSvg
                    className={styles.list__clear}
                    onClick={(e) => {
                      modalContext?.handleOpen(EModalTypes.AGREE, e, {
                        onConfirm: (): void => {
                          void deleteDriver(
                            EDriver.preferred,
                            data.cid,
                            el.did
                          );
                        },
                        title: `Are you sure you want to delete ${
                          el.display_name ?? getFullUserName(el)
                        } from the preferred driver list?`
                      });
                    }}
                  />
                </p>
              ))
            : ""}
        </>
      )
    },
    {
      dataIndex: "excluded_drivers",
      render: (text, data: ICustomer) => (
        <>
          <SetDriver
            type={EDriver.excluded}
            customer={data}
            addDriver={addDriver}
          />
          {Array.isArray(text)
            ? text.map((el: IDriver) => (
                <p className={styles.list__btn} key={el.did}>
                  {el.display_name ?? getFullUserName(el)}
                  <ClearSvg
                    className={styles.list__clear}
                    onClick={(e) => {
                      modalContext?.handleOpen(EModalTypes.AGREE, e, {
                        onConfirm: (): void => {
                          void deleteDriver(EDriver.excluded, data.cid, el.did);
                        },
                        title: `Are you sure you want to delete ${
                          el.display_name ?? getFullUserName(el)
                        } from the excluded driver list?`
                      });
                    }}
                  />
                </p>
              ))
            : ""}
        </>
      )
    },
    {
      action: (customer: ICustomer): ReactNode => (
        <ClearSvg
          className={styles.list__clear}
          onClick={(e) => {
            modalContext?.handleOpen(EModalTypes.AGREE, e, {
              onConfirm: (): void => {
                void deleteCustomer(customer);
              },
              title: `Are you sure to delete ${getFullUserName(
                customer
              )} and all related data?`
            });
          }}
        />
      )
    }
  ];
  return (
    <div className={styles.list}>
      <Table
        data={data}
        cols={cols}
        size="sm"
        clear
        loading={loading}
        nextPage={nextPage}
        hasMore={hasMore}
        infinite
        empty="No customers yet"
      />
    </div>
  );
};

export default List;

import React from "react";

import InfiniteScrollComponent from "react-infinite-scroll-component";
import { Dots } from "@ui";

import type { IInfiniteScrollProps } from "../layout.types";

import styles from "./styles.module.scss";

const InfiniteScroll = ({
  children,
  length,
  getData,
  hasMore,
  loading = false,
  scrollableTarget
}: IInfiniteScrollProps): JSX.Element => {
  return length === 0 && loading ? (
    <Dots padding />
  ) : (
    <InfiniteScrollComponent
      dataLength={length}
      next={getData}
      hasMore={hasMore}
      loader={<Dots padding />}
      className={styles.infinite}
      scrollableTarget={scrollableTarget}
    >
      {children}
    </InfiniteScrollComponent>
  );
};

export default InfiniteScroll;

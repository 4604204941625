import { Navigate, createBrowserRouter } from "react-router-dom";
import {
  BookingPage,
  History,
  Root,
  Customers,
  Invoices,
  Feedback,
  PaymentPage,
  ProfilePage,
  LoginPage,
  SignupPage,
  AgentsPage
} from "../pages";
import {
  BOOKING_ROUTE,
  CUSTOMERS_ROUTE,
  FEEDBACK_ROUTE,
  HISTORY_ROUTE,
  INVOICES_ROUTE,
  LOGIN_ROUTE,
  PAYMENT_ROUTE,
  PROFILE_ROUTE,
  SIGNUP_ROUTE,
  AGENTS_ROUTE
} from "@constants/routes";
import type { ReactNode } from "react";
import type { RouteObject } from "react-router-dom";

type Router = ReturnType<typeof createBrowserRouter>;

const privateRoute = (
  path: string,
  element: ReactNode,
  isLoggedIn: boolean
): RouteObject => {
  return {
    path,
    element: isLoggedIn ? element : <Navigate to={`/${LOGIN_ROUTE}`} />
  };
};

export const router = (isLoggedIn: boolean): Router => {
  return createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      children: [
        privateRoute(`${BOOKING_ROUTE}/:oid`, <BookingPage />, isLoggedIn),
        privateRoute(BOOKING_ROUTE, <BookingPage />, isLoggedIn),
        privateRoute(`${PAYMENT_ROUTE}/:oid`, <PaymentPage />, isLoggedIn),
        privateRoute(PAYMENT_ROUTE, <PaymentPage />, isLoggedIn),
        privateRoute(HISTORY_ROUTE, <History />, isLoggedIn),
        privateRoute(
          `${HISTORY_ROUTE}/${BOOKING_ROUTE}/:oid`,
          <BookingPage />,
          isLoggedIn
        ),
        privateRoute(CUSTOMERS_ROUTE, <Customers />, isLoggedIn),
        privateRoute(INVOICES_ROUTE, <Invoices />, isLoggedIn),
        privateRoute(`${FEEDBACK_ROUTE}/:fid`, <Feedback />, isLoggedIn),
        privateRoute(FEEDBACK_ROUTE, <Feedback />, isLoggedIn),
        privateRoute(AGENTS_ROUTE, <AgentsPage />, isLoggedIn),
        privateRoute(PROFILE_ROUTE, <ProfilePage />, isLoggedIn),
        {
          path: LOGIN_ROUTE,
          element: <LoginPage />
        },
        {
          path: SIGNUP_ROUTE,
          element: <SignupPage />
        }
      ]
    }
  ]);
};

import React, { useState } from "react";

import { Header } from "@modules/Customers/components";
import { List, MobileList } from "@modules/Customers/widgets";
import { useInfiniteFetchData } from "@hooks";
import type { ICustomer } from "@interfaces";
import { MainLayout } from "@layouts";
import { WelcomeBack } from "@components";

const CustomersPage = (): JSX.Element => {
  const [search, setSearch] = useState<string>("");
  const { list, loading, nextPage, hasMore, setData } =
    useInfiniteFetchData<ICustomer>(
      `customers?details=1${search !== "" ? `&search=${search}` : ""}`
    );
  const [driverOpen, setDriverOpen] = useState<boolean>(false);

  return (
    <MainLayout extra={<WelcomeBack />}>
      <Header
        setSearch={setSearch}
        changeTitle={driverOpen}
        setDriverOpen={setDriverOpen}
      />
      <List
        data={list ?? []}
        loading={loading}
        nextPage={nextPage}
        hasMore={hasMore}
        setData={setData}
      />
      <MobileList
        data={list ?? []}
        loading={loading}
        nextPage={nextPage}
        hasMore={hasMore}
        setData={setData}
        setDriverOpen={setDriverOpen}
        isDriverOpen={driverOpen}
      />
    </MainLayout>
  );
};

export default CustomersPage;

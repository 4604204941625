import axios from "axios";
import { Cookies } from "react-cookie";
import { DEFAULT_URL } from "@constants/config";

import type {
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosResponse
} from "axios";
import { removeToken } from "./user";
import { LOGIN_ROUTE, SIGNUP_ROUTE } from "@constants/routes";
const cookies = new Cookies();

const axiosInstance = axios.create({
  baseURL: DEFAULT_URL ?? "",
  withCredentials: true
});

axiosInstance.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    const token: string = cookies.get("access_token");
    config.baseURL = `${config.baseURL ?? ""}/api/`;
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  async function (error) {
    return await Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (response: AxiosResponse) => {
    if (response.status === 200 || response.status === 201) {
      return response;
    }

    if (response.data?.status === "failed") {
      console.error(response.config.url, response.data.data);
      return await Promise.reject(new Error(response.data.errors));
    }

    console.error("Unknown server response:", response.data.data);
    return await Promise.reject(new Error("An error occurred."));
  },
  async (error: AxiosError) => {
    if (error.response?.status === 401) {
      removeToken();
      if (
        !(
          location.pathname === `/${LOGIN_ROUTE}` ||
          location.pathname === `/${SIGNUP_ROUTE}`
        )
      ) {
        window.location.href = `${location.origin}/${LOGIN_ROUTE}`;
      }
    }
    // console.error(
    //   error.response.config.url,
    //   `Error code: ${error.response.status}`,
    //   `Server error message: ${logError(error.response)}`
    // );

    return await Promise.reject(error.response);
  }
);

export default axiosInstance;

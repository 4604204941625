import React, { useState } from "react";
import cn from "classnames";

import styles from "./SelectList.module.scss";
import type { ISelectListItem, ISelectListProps } from "../ui.types";
import { PopupTriangleLayout } from "@layouts";

const SelectList = ({
  list,
  active,
  setActive = () => {},
  setActiveList = () => {},
  className,
  itemClassName,
  multiple = false,
  all = false,
  theme = "primary",
  disable = false,
  overflow = "auto",
  onDisable = () => {},
  ...rest
}: ISelectListProps): JSX.Element => {
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const onClick = (current: ISelectListItem) => () => {
    const isActive = Array.isArray(active)
      ? current.active === true
      : current.value !== undefined
      ? current.value === active?.value
      : current.label === active?.label;
    if (isActive) {
      return;
    }
    if (disable) {
      onDisable();
      return;
    }
    if (selectAll) {
      setSelectAll(false);
    }
    if (multiple && Array.isArray(active)) {
      setActiveList(
        active.map<ISelectListItem>((el) => {
          return el.value === current.value
            ? { ...el, active: el.active !== true }
            : el;
        })
      );
    } else {
      setActive(current);
    }
  };

  const selectAllItems = (): void => {
    setSelectAll(!selectAll);
    if (multiple && Array.isArray(active)) {
      setActiveList(active.map((el) => ({ ...el, active: !selectAll })));
    }
  };
  return (
    <div className={cn(styles.list, className, styles[overflow])}>
      {all ? (
        <div
          className={cn(
            styles.list__item,
            styles[theme],
            selectAll && styles.active
          )}
          onClick={selectAllItems}
        >
          All
        </div>
      ) : null}
      {list.map((el) => {
        const isActive = Array.isArray(active)
          ? el.active === true
          : el.value !== undefined
          ? el.value === active?.value
          : el.label === active?.label;
        return (
          <PopupTriangleLayout
            className={cn(
              styles.list__item,
              styles[theme],
              disable && styles.disable,
              isActive && styles.active,
              itemClassName
            )}
            popupClassName={styles["list__item-popup"]}
            {...rest}
            id={el.id}
            onClick={(e) => {
              if (rest.onClick) {
                rest.onClick(e);
              }
              onClick(el)();
            }}
            popup={el.hint}
            show={el.hint !== undefined}
            key={el.label}
            textAlign="textCenter"
          >
            {el.label}
          </PopupTriangleLayout>
        );
      })}
    </div>
  );
};

export default SelectList;

import React from "react";
import CountryCodeSelect from ".";
import { useField } from "formik";
import type { ICountryCodeSelectFormikProps } from "../components.types";

const CountryCodeSelectFormik = ({
  name,
  ...rest
}: ICountryCodeSelectFormikProps): JSX.Element => {
  // eslint-disable-next-line
  const [field, _meta, helpers] = useField(name);

  return (
    <CountryCodeSelect
      {...rest}
      value={field.value}
      setValue={(el) => {
        helpers.setValue(el);
      }}
    />
  );
};

export default CountryCodeSelectFormik;

import type { ForwardedRef } from "react";
import { forwardRef } from "react";
import cn from "classnames";

// ui
import SpinnerButton from "../SpinnerButton";

// types
import type { IButtonProps } from "../ui.types";

// styles
import styles from "./Button.module.scss";

const Button = forwardRef(
  (
    {
      children,
      className,
      rounded = "rounded-none",
      isLoading = false,
      disabled = false,
      size = "auto",
      hSize = "h-sm",
      color = "primary",
      textLoading,
      ...rest
    }: IButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => (
    <button
      ref={ref}
      className={cn(
        styles.button,
        styles[rounded],
        styles[size],
        styles[hSize],
        styles[color],
        textLoading && styles["text-loading"],
        className
      )}
      disabled={disabled || isLoading}
      {...rest}
    >
      {isLoading ? <SpinnerButton /> : children}
    </button>
  )
);

Button.displayName = "Button";

export default Button;

import React from "react";
import cn from "classnames";

import styles from "./styles.module.scss";
import type { IPaymentMethodProps } from "components/components.types";
import { getCardTypeUrl } from "@utils";

// eslint-disable-next-line
interface ICardTypeObj {
  [key: string]: string;
}

const cardTypes: ICardTypeObj = {
  "American Express": "american_express",
  MasterCard: "mastercard",
  Visa: "visa",
  Discover: "discover"
};

const PaymentMethod = ({
  number,
  type,
  logo,
  align = "left"
}: IPaymentMethodProps): JSX.Element => {
  if (number === "") {
    return <></>;
  }
  return (
    <div className={cn(styles.payment, styles[align])}>
      <div className={styles.payment__top}>
        {logo === undefined ? (
          <img src={getCardTypeUrl(cardTypes[type] ?? "visa")} alt="logo" />
        ) : (
          <img src={logo} alt="logo" />
        )}

        <div className={styles.payment__card}>{number}</div>
      </div>
      <div className={styles.payment__type}>{type}</div>
    </div>
  );
};

export default PaymentMethod;

import React, { useEffect, useState } from "react";
import { useQuery } from "@hooks";
import { axiosInstance } from "@utils";
import { Dots } from "@ui/Loader";
import type { IContact } from "@interfaces";
import { Error, Form } from "@modules/Signup/widgets";

const SignupPage = (): JSX.Element => {
  const { refcode, account } = useQuery();
  const [loading, setLoading] = useState<boolean>(false);
  const [contact, setContact] = useState<IContact>();
  const [showForm, setShowForm] = useState<boolean>(false);

  const getContactData = async (): Promise<void> => {
    setLoading(true);
    try {
      if (
        refcode !== undefined &&
        account !== undefined &&
        (account === "booking" || account === "head")
      ) {
        const resp = await axiosInstance.get(
          `crm/agent/get-by-ref-code?detail=1&refcode=${refcode}`
        );
        if (resp.data?.status !== "draft" && account === "head") {
          setShowForm(false);
          setLoading(false);
          return;
        }
        setContact(resp.data);
        setShowForm(true);
      } else {
        setShowForm(false);
      }
    } catch (e) {}
    setLoading(false);
  };

  useEffect(() => {
    if (refcode !== undefined) {
      getContactData();
    } else {
      setShowForm(true);
    }
  }, []);
  if (loading) {
    return <Dots />;
  }
  return (
    <div className="signup-container">
      <h1 className="center">Sign up</h1>
      {showForm ? (
        <Form contact={contact} />
      ) : (
        <Error setShowForm={setShowForm} />
      )}
    </div>
  );
};

export default SignupPage;

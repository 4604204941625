import React from "react";
import type { ISelectFormikProps } from "../components.types";
import { useField } from "formik";
import Select from ".";
import { type ISelectListItem } from "@ui/ui.types";

const SelectFormik = ({ name, ...rest }: ISelectFormikProps): JSX.Element => {
  // eslint-disable-next-line
  const [field, _meta, helpers] = useField(name);
  const onChange = (value: ISelectListItem): void => {
    helpers.setValue(value);
  };
  return <Select {...rest} value={field.value} onChange={onChange} />;
};

export default SelectFormik;

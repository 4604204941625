import React, {
  forwardRef,
  useState,
  useEffect,
  type FocusEvent,
  createRef
} from "react";
import cn from "classnames";
import { passwordStrength } from "check-password-strength";

import {
  BoldCheckIcon,
  ClearSvg,
  EyeSvg,
  OpenEyeSvg,
  UnionIcon
} from "@assets/svg";

import type { IInputLabelProps } from "../ui.types";
// import type { ChangeEvent } from "react";

import styles from "./InputLabel.module.scss";

const InputLabel = forwardRef<HTMLInputElement, IInputLabelProps>(
  (
    {
      label = "",
      type = "text",
      error = "",
      className,
      id,
      value = "",
      setValue = () => {},
      asTag: Tag = "input",
      touched = true,
      extra,
      extraBtn,
      placeholder,
      required = false,
      autocomplete = false,
      isValue = false,
      select,
      onBlur: onBlurProp,
      scrollOnError = false,
      clearFormikValue = () => {},
      setPasswordStrength,
      ...rest
    },
    ref
  ): JSX.Element => {
    const [focused, setFocused] = useState<boolean>(false);
    const [showPass, setShowPass] = useState<boolean>(false);
    const boxRef = createRef<HTMLDivElement>();

    const toggleShowPass = (): void => {
      setShowPass((prev) => !prev);
    };
    const onBlur = (e: FocusEvent<HTMLInputElement>): void => {
      setFocused(false);
      if (onBlurProp) onBlurProp(e);
    };

    const clearValue = (): void => {
      clearFormikValue();
      setValue("");
    };
    const onFocus = (): void => {
      setFocused(true);
    };

    useEffect(() => {
      setFocused(Boolean(value));
    }, [value]);

    useEffect(() => {
      if (error && scrollOnError) {
        boxRef?.current?.scrollIntoView({ behavior: "smooth" });
      }
    }, [error]);

    // --> RENDER
    const showError =
      error !== undefined && error.length > 0 && touched && !scrollOnError;
    const passwordStrengthResult =
      type === "password" && value.length > 0 ? passwordStrength(value) : null;
    const showPasswordStrength =
      passwordStrengthResult && typeof setPasswordStrength === "function";
    const strengthIcon = [
      <UnionIcon className={styles.strength_icon} key="too weak" />,
      <UnionIcon className={styles.strength_icon} key="weak" />,
      <BoldCheckIcon
        className={cn(styles.strength_icon, styles.yellow_icon)}
        key="medium"
      />,
      <BoldCheckIcon className={styles.strength_icon} key="strong" />
    ];

    useEffect(() => {
      if (
        typeof setPasswordStrength === "function" &&
        type === "password" &&
        value.length > 0
      ) {
        setPasswordStrength(passwordStrength(value));
      }
    }, [type, value, setPasswordStrength]);

    function getPasswordStrengthError(): string {
      const result: string[] = [];
      if (passwordStrengthResult) {
        if (passwordStrengthResult.length < 8) {
          result.push("at least 8 characters long");
        }
        if (!passwordStrengthResult.contains.includes("uppercase")) {
          result.push("1 uppercase");
        }
        if (!passwordStrengthResult.contains.includes("lowercase")) {
          result.push("1 lowercase");
        }
        if (!passwordStrengthResult.contains.includes("symbol")) {
          result.push("symbol");
        }
        if (!passwordStrengthResult.contains.includes("number")) {
          result.push("number");
        }
      }
      return result.length > 0
        ? `Your password must be have ${result.join(", ")}`
        : "";
    }
    return (
      <div
        className={cn(
          styles.input,
          (focused || value !== "" || extra !== undefined || isValue) &&
            styles.label,
          (focused || value !== "") && styles.focused,
          error !== "" && touched ? styles.error : "",
          className
        )}
        ref={boxRef}
      >
        <span onClick={onFocus}>
          {label}
          {required ? "*" : ""}
        </span>
        <div className={styles.input__box}>
          {extra !== undefined ? (
            <div className={styles["input__box-extra"]}>{extra}</div>
          ) : null}

          <Tag
            ref={ref}
            type={showPass ? "text" : type}
            onFocus={onFocus}
            onBlur={onBlur}
            id={id}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValue(e.currentTarget.value);
            }}
            placeholder={placeholder}
            value={value}
            autoComplete="new-password"
            {...rest}
          />
        </div>
        <div className={styles.input__btn}>
          {type === "password" ? (
            showPass ? (
              <OpenEyeSvg className={styles.clear} onClick={toggleShowPass} />
            ) : (
              <EyeSvg className={styles.clear} onClick={toggleShowPass} />
            )
          ) : (
            <ClearSvg className={styles.clear} onClick={clearValue} />
          )}
          {extraBtn}
        </div>

        {showError ? (
          <div className={styles.input__error}>{error}</div>
        ) : showPasswordStrength ? (
          <div className={styles.password_strength}>
            <p>Password strength:</p>
            {strengthIcon[passwordStrengthResult.id]}
            <p>{passwordStrengthResult.value}</p>
            {passwordStrengthResult.id < 2 && (
              <p className={styles.color_danger}>
                {getPasswordStrengthError()}
              </p>
            )}
          </div>
        ) : null}
        {select}
      </div>
    );
  }
);

export default InputLabel;

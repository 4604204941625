import React, { useEffect, useState } from "react";
import cn from "classnames";

import styles from "./styles.module.scss";
import { type ITimePickerProps } from "@ui/ui.types";
import { ArrowSvg } from "@assets/svg";
import { dayTimes, hours, minutes } from "@constants/card";
import { useOutsideClick, usePopupDirection } from "@hooks";
import { PopupTriangleLayout } from "@layouts";
const TimePicker = ({
  label,
  value,
  onChange,
  error = false,
  className,
  disabled = false,
  arrow = true,
  scrollOnError = false,
  popop,
  theme = "none"
}: ITimePickerProps): JSX.Element => {
  const [hour, setHour] = useState<string | undefined>(value?.hour);
  const [minute, setMinute] = useState<string | undefined>(value?.minute);
  const [dayTime, setDayTime] = useState<string | undefined>(value?.dayTime);

  const [show, setShow] = useState<boolean>(false);
  const {
    ref: popupRef,
    position,
    handleScroll
  } = usePopupDirection(!disabled);

  const toggleShow = (): void => {
    if (!disabled) {
      handleScroll();
      setShow((prev) => !prev);
    }
  };

  const ref = useOutsideClick(toggleShow, show);

  useEffect(() => {
    if (error && scrollOnError) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [error]);

  useEffect(() => {
    if (
      (hour ?? value?.hour) &&
      (minute ?? value?.minute) &&
      (dayTime ?? value?.dayTime)
    ) {
      onChange({
        hour: hour ?? value?.hour,
        minute: minute ?? value?.minute,
        dayTime: dayTime ?? value?.dayTime
      });
    }
  }, [hour, minute, dayTime]);

  useEffect(() => {
    if (value) {
      setHour(value.hour);
      setMinute(value.minute);
      setDayTime(value.dayTime);
    }
  }, [value]);

  return (
    <PopupTriangleLayout
      className={cn(styles.picker, className)}
      show={!!popop && !show}
      popup={popop}
    >
      <div
        className={cn(styles.picker, styles.border, styles[theme], className)}
        onClick={toggleShow}
        ref={ref}
      >
        <div className={styles.picker__btn} ref={popupRef}>
          <b className={cn(error && styles.error)}>{label}</b>
          <p className={cn(value === undefined && styles.label)}>
            {value === undefined
              ? "Select time"
              : `${value.hour}:${value.minute} ${value.dayTime}`}
          </p>
        </div>
        {!disabled ? (
          <div
            className={cn(
              styles.picker__calendar,
              show && styles.active,
              styles[position]
            )}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className={styles.picker__list}>
              {hours.map((el) => (
                <div
                  key={el}
                  className={cn(
                    styles["picker__list--item"],
                    el === hour && styles.active
                  )}
                  onClick={() => {
                    setHour(el);
                  }}
                >
                  {el}
                </div>
              ))}
            </div>
            <div className={styles.picker__list}>
              {minutes.map((el) => (
                <div
                  key={el}
                  className={cn(
                    styles["picker__list--item"],
                    el === minute && styles.active
                  )}
                  onClick={() => {
                    setMinute(el);
                  }}
                >
                  {el}
                </div>
              ))}
            </div>
            <div className={styles.picker__list}>
              {dayTimes.map((el) => (
                <div
                  key={el}
                  className={cn(
                    styles["picker__list--item"],
                    el === dayTime && styles.active
                  )}
                  onClick={() => {
                    setDayTime(el);
                  }}
                >
                  {el}
                </div>
              ))}
            </div>
          </div>
        ) : null}

        {arrow ? <ArrowSvg /> : null}
      </div>
    </PopupTriangleLayout>
  );
};

export default TimePicker;

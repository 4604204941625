import React from "react";
import cn from "classnames";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { SwitchForm, InputLabelForm } from "@getpickedupgpu/gpu2.shared";

import { Dots } from "@ui";

import { ClearSvg } from "@assets/svg";
import {
  selectExcludedLoading,
  selectExludedDrivers,
  selectPreferredDrivers,
  selectPreferredLoading,
  selectUser
} from "@store/user/selector";
import { axiosInstance, getFullUserName } from "@utils";
import SetAgentDriver from "../SetAgentDriver";
import { EDriver } from "@interfaces";
import { useAppDispatch } from "@hooks";
import {
  removeExcludedDriver,
  removePreferredDriver
} from "@store/user/reducer";

import styles from "./styles.module.scss";
import { useFormContext } from "react-hook-form";

const Preferences = (): JSX.Element => {
  const { control } = useFormContext();
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();
  const preferredDrivers = useSelector(selectPreferredDrivers);
  const exludedDrivers = useSelector(selectExludedDrivers);
  const preferredLoading = useSelector(selectPreferredLoading);
  const exludedLoading = useSelector(selectExcludedLoading);

  const deleteDriver = async (type: EDriver, did: number): Promise<void> => {
    try {
      if (type === EDriver.excluded) {
        dispatch(removeExcludedDriver(did));
      } else {
        dispatch(removePreferredDriver(did));
      }
      await axiosInstance.delete(
        `agents/${user?.id}/delete-${type}-driver-for-customers`,
        {
          data: {
            did
          }
        }
      );
    } catch (e) {
      toast.error(`Something went wrong`);
    }
  };
  return (
    <div className={styles.preferences}>
      <SwitchForm
        control={control}
        className={cn(styles.preferences__item, styles.preferences__switch)}
        name="send_invoice"
        id="invoice"
      >
        Send client invoice in details email
      </SwitchForm>

      <InputLabelForm
        className={styles.settings__input}
        control={control}
        label="Enter additional email for copy invoice"
        name="invoice_email"
        rules={{
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid email address"
          }
        }}
      />

      <SwitchForm
        control={control}
        className={cn(styles.preferences__item, styles.preferences__switch)}
        id="email"
        name="use_invoice_email_only"
      >
        Use only email above for invoices
      </SwitchForm>
      <SwitchForm
        control={control}
        className={cn(styles.preferences__item, styles.preferences__switch)}
        id="receive"
        name="receive_reconfirmed"
      >
        Receive reconfirmation emails for bookings
      </SwitchForm>
      <SwitchForm
        control={control}
        className={cn(styles.preferences__item, styles.preferences__switch)}
        id="calendar"
        name="ics"
      >
        Receive calendar invites for bookings
      </SwitchForm>
      <SwitchForm
        control={control}
        className={cn(styles.preferences__item, styles.preferences__switch)}
        id="prefered"
        name="preferred_type"
      >
        Preferred drivers only
      </SwitchForm>
      <div
        className={cn(
          styles.preferences__item,
          styles.preferences__switch,
          styles.preferences__list
        )}
      >
        <SetAgentDriver type={EDriver.preferred} />
        {preferredDrivers?.map((el) => (
          <span key={el.did}>
            {el.display_name ?? getFullUserName(el)}
            <ClearSvg
              onClick={() => {
                deleteDriver(EDriver.preferred, el.did);
              }}
            />
          </span>
        ))}
        {preferredLoading ? <Dots /> : null}
      </div>
      <div
        className={cn(
          styles.preferences__item,
          styles.preferences__switch,
          styles.preferences__list
        )}
      >
        <SetAgentDriver type={EDriver.excluded} />

        {exludedDrivers?.map((el) => (
          <span key={el.did}>
            {el.display_name ?? getFullUserName(el)}
            <ClearSvg
              onClick={() => {
                deleteDriver(EDriver.excluded, el.did);
              }}
            />
          </span>
        ))}
        {exludedLoading ? <Dots /> : null}
      </div>
    </div>
  );
};

export default Preferences;

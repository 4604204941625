import { type RefObject, useEffect, useState, useRef } from "react";

interface IReturn {
  ref: RefObject<HTMLDivElement>;
  position: "top" | "bottom";
  handleScroll: () => void;
}
export const usePopupDirection = (condition: boolean = true): IReturn => {
  const ref = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<"top" | "bottom">("bottom");

  const handleScroll = (): void => {
    const bounds = ref.current?.getBoundingClientRect();
    const height = window.innerHeight;

    setPosition((bounds?.top ?? 0) * 2 < height - 150 ? "bottom" : "top");
  };

  useEffect(() => {
    if (condition) {
      handleScroll();
      // window.addEventListener("scroll", handleScroll);
    }
    // return () => {
    //   window.removeEventListener("scroll", handleScroll);
    // };
  }, [condition]);

  return {
    ref,
    position,
    handleScroll
  };
};

import React, { useState } from "react";
import cn from "classnames";

import type { IInputProps } from "@ui/ui.types";

import styles from "./styles.module.scss";
import { EyeSvg } from "@assets/svg";

const Input = ({
  className,
  asTag: Tag = "input",
  formik = false,
  password = false,
  error = "",
  touched,
  prepend,
  append,
  ...rest
}: IInputProps): JSX.Element => {
  const [showPass, setShowPass] = useState<boolean>(false);

  const toggleShowPass = (): void => {
    setShowPass((prev) => !prev);
  };
  return (
    <div className={cn(styles.input__wrapper, className)}>
      <div className={styles.flex}>
        {prepend}
        <Tag
          className={styles.input}
          type={password && !showPass ? "password" : "text"}
          {...rest}
        />
        {append}
      </div>
      {error !== "" && typeof touched === "boolean" && touched ? (
        <div className={styles.input__error}>{error}</div>
      ) : null}
      {password ? (
        <EyeSvg className={styles.input__eye} onClick={toggleShowPass} />
      ) : null}
    </div>
  );
};

export default Input;

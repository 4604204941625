import React from "react";
import cn from "classnames";

import styles from "./styles.module.scss";

import type { RatingWithStarsProps } from "../components.types";

export default function RatingWithStars({
  value,
  className,
  showValue = true,
  id
}: RatingWithStarsProps): JSX.Element {
  const arr = Array.from({ length: 5 }).map((el, index) => {
    const diff = value - index;
    return diff > 1 ? 100 : diff > 0 ? Math.round(diff * 100) : 0;
  });

  return (
    <div className={cn(styles.stars, className)}>
      {arr.map((el, index) => (
        <svg
          key={index}
          width="34"
          height="33"
          viewBox="0 0 34 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              id={`gradient-fill-${id}-${index}`}
              x1="0"
              x2="1"
              y1="0"
              y2="0"
            >
              <stop className="stop1" offset={`${el}%`} stopColor="#12bf66" />
              <stop className="stop2" offset={`${el}%`} stopColor="#b0b0b0" />
            </linearGradient>
          </defs>
          <path
            fill={`url(#gradient-fill-${id}-${index})`}
            d="M33.2219 14.6985C33.8904 14.0555 34.1263 13.1119 33.838 12.2346C33.549 11.3574 32.7963 10.7312 31.8715 10.5983L23.6489 9.41899C23.2987 9.36865 22.9961 9.15185 22.8397 8.83841L19.1636 1.48422C18.7509 0.657989 17.9131 0.144531 16.9795 0.144531C16.0465 0.144531 15.2087 0.657989 14.796 1.48422L11.1192 8.83841C10.9628 9.15185 10.6595 9.36865 10.3093 9.41899L2.08678 10.5989C1.16266 10.7312 0.409899 11.3574 0.120899 12.2346C-0.167421 13.1119 0.0685389 14.0555 0.736979 14.6985L6.6863 20.4224C6.93994 20.6667 7.05622 21.0184 6.99638 21.3621L5.59218 29.4452C5.43442 30.3533 5.80502 31.2534 6.5605 31.7957C7.3153 32.3387 8.2979 32.4091 9.12546 31.9789L16.479 28.1625C16.7925 28.0001 17.1665 28.0001 17.4799 28.1625L24.8341 31.9789C25.1932 32.1655 25.5821 32.2574 25.9691 32.2574C26.4716 32.2574 26.9721 32.1024 27.3991 31.7957C28.1546 31.2534 28.5252 30.3533 28.3674 29.4452L26.9625 21.3627C26.9027 21.0184 27.019 20.6674 27.2726 20.4231L33.2219 14.6985Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="white"
            d="M17.0949 9.35024C16.9073 9.02909 17.0191 8.61868 17.3444 8.43356C17.6698 8.24843 18.0856 8.3587 18.2731 8.67985L20.275 12.1076C20.4559 12.1668 20.7457 12.2401 21.1354 12.3234C22.0424 12.5174 23.4595 12.7601 25.3811 13.0502C25.7523 13.1063 26.0072 13.4487 25.9504 13.8152C25.8936 14.1816 25.5467 14.4332 25.1754 14.3772C23.2286 14.0832 21.7878 13.8364 20.8474 13.6354C20.3579 13.5307 19.997 13.4372 19.7523 13.3493C19.5869 13.2899 19.4595 13.2296 19.3495 13.1456C19.2018 13.0328 19.0907 12.8747 19.0505 12.6989L17.0949 9.35024Z"
          />
        </svg>
      ))}
      {showValue ? <b>{value ?? "0"}</b> : null}
    </div>
  );
}

import React from "react";

import { Button } from "@ui";
import type { IErrorProps } from "../widgets.types";

import styles from "./styles.module.scss";
import { config } from "@constants/config";

const Error = ({ setShowForm }: IErrorProps): JSX.Element => {
  return (
    <div className={styles.error}>
      <div className={styles.error__message}>
        This link does not exist. Please contact GPU{" "}
        <a href={`tel:+${config?.contact?.phone}`}>+{config?.contact?.phone}</a>
      </div>
      <Button
        size="md"
        onClick={() => {
          setShowForm(true);
        }}
      >
        OK
      </Button>
    </div>
  );
};

export default Error;

import React from "react";
import cn from "classnames";

import type { IBookingStatusProps } from "../components.types";

import styles from "./styles.module.scss";
const BookingStatus = ({
  status,
  className
}: IBookingStatusProps): JSX.Element => {
  return (
    <div className={cn(styles.status, className, styles[status])}>{status}</div>
  );
};

export default BookingStatus;

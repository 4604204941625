import { SLICE_NAMES } from "@store/actionTypes";
import { createSelector } from "reselect";

const selectLookupStore = (state: {
  [key in SLICE_NAMES]: LookupState;
}): LookupState => state[SLICE_NAMES.LOOKUP];

export const selectCities = createSelector(
  selectLookupStore,
  (state) => state.cities
);
export const selectCountries = createSelector(selectLookupStore, (state) =>
  state.countries.map((el) => ({ label: el.name, value: el.country_id }))
);

export const selectCarTypes = createSelector(
  selectLookupStore,
  (state) => state.cartypes
);
export const selectCarTypesList = createSelector(selectLookupStore, (state) =>
  state.cartypes.map((el) => ({
    label: el.title,
    value: el.id
  }))
);

export const selectCarTypesLoading = createSelector(
  selectLookupStore,
  (state) => state.carTypesLoading
);
export const selectCountryCodes = createSelector(
  selectLookupStore,
  (state) => state.countryCodes
);

import { CardLayout, InfiniteScroll } from "@layouts";
import styles from "./styles.module.scss";
import { downloadFile } from "@utils";
import { toast } from "react-toastify";
import Empty from "@ui/Empty";
import Skeleton from "react-loading-skeleton";

interface IMobileListProps {
  // eslint-disable-next-line
  data: any[];
  nextPage?: () => void;
  hasMore: boolean;
  loading: boolean;
}
const ByAccMobileList = ({
  data,
  nextPage = () => {},
  hasMore,
  loading
}: IMobileListProps): JSX.Element => {
  const downloadInvoice = async (invno: string): Promise<void> => {
    if (!invno) return;
    try {
      await downloadFile(`invoices/${invno}/download`, `invoice-${invno}.pdf`);
    } catch (e) {
      toast.error(e.data?.message ?? "Something went wrong");
    }
  };
  if (data.length === 0 && !loading) {
    return (
      <CardLayout>
        <Empty title="No invoices" />
      </CardLayout>
    );
  }
  return (
    <>
      {!loading ? (
        <div className={styles.container}>
          <InfiniteScroll
            length={data.length}
            getData={() => {
              nextPage();
            }}
            hasMore={hasMore}
          >
            {data.map((el) => {
              return (
                <div key={el.idate} className={styles.row}>
                  <p className={styles.row__date}>{el.idate}</p>
                  <div className={styles.row__item}>
                    <p
                      className={styles["row__item-invoice"]}
                      onClick={() => {
                        downloadInvoice(el.invno);
                      }}
                    >
                      Invoice #{el.invno}
                    </p>
                    <p className={styles["row__item-cost"]}>$ {el.cost}</p>
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      ) : (
        <>
          {Array.from({ length: 10 }).map((el, i) => (
            <div className={styles.loading} key={i}>
              <Skeleton width="100px" height="17px" borderRadius="20px" />
              <div className={styles.loading__flex}>
                <Skeleton width="120px" height="17px" borderRadius="20px" />
                <Skeleton width="80px" height="17px" borderRadius="20px" />
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};
export default ByAccMobileList;

import { ModalLayout } from "@layouts";
import React, { useContext, useState } from "react";
import cn from "classnames";
import { MptpCard } from "@getpickedupgpu/gpu2.shared";

import styles from "./styles.module.scss";
import { EModalTypes } from "@constants/modal";
import { Form, FormikProvider, useFormik } from "formik";
import type { ICustomer } from "@interfaces";
import { ModalContext } from "@contexts";
import { Button, Checkbox, CheckboxFormik, InputLabelFormik } from "@ui";
import { CountryCodeSelectFormik } from "@modules/Booking/components";
import { toast } from "react-toastify";
import { CustomerSchema, axiosInstance } from "@utils";
import SelectFormik from "@modules/Booking/components/Select/SelectFormik";
import { selectCarTypesList } from "@store/lookup/selector";
import { useSelector } from "react-redux";
import { MinusSvg, PlusSvg } from "@assets/svg";
import { DEFAULT_URL, config } from "@constants/config";

const EditCustomerModal = (): JSX.Element => {
  const cars = useSelector(selectCarTypesList);
  const modalContext = useContext(ModalContext);
  const { customer, onUpdateCustomer } = modalContext?.data;
  const [loading, setLoading] = useState<boolean>(false);
  const [showMptp, setShowMptp] = useState<boolean>(!!customer?.mtp_account);

  const onSubmit = async (values: ICustomer): Promise<void> => {
    setLoading(true);

    try {
      const resp = await axiosInstance.put(`customers/${customer.cid}`, {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        alternate_email: values.alternate_email,
        company_name: values.company_name,
        mobile: values.mobile,
        booking_refno: values.booking_refno,
        mobile_country_code: values.code?.code ?? 61,
        customer_notes: values.customer_notes,
        vehicle_preference: values?.vehicle_preference?.value,
        is_show_booking_comments: values.is_show_booking_comments,
        is_set_booking_reference: values.is_set_booking_reference,
        comment: values.comment,
        mptp_code: values.mptp_code,
        mptp_date: values.mptp_date
      });
      if (onUpdateCustomer) {
        onUpdateCustomer({
          ...resp.data,
          vehicle_preference: cars.find(
            (el) => el.value === resp.data.vehicle_preference
          )
        });
      }
      if (customer.mtp_account && !values.mptp_code) {
        await axiosInstance.post(`customers/${customer?.cid}/delete-mptp`);
        onUpdateCustomer({
          cid: customer.cid,
          mtp_account: undefined
        });
      }
      toast.success("Updated");
      modalContext?.handleClose();
    } catch (e) {
      toast.error(e.data?.message ?? "Something went wrong");
    }
    setLoading(false);
  };

  const formik = useFormik<ICustomer>({
    initialValues: {
      ...customer,
      vehicle_preference: {
        label: customer.vehicle_preference?.title
          ? customer.vehicle_preference.title
          : customer.vehicle_preference?.label,
        value: customer.vehicle_preference?.id
          ? customer.vehicle_preference.id
          : customer.vehicle_preference?.value
      }
    },
    onSubmit,
    validationSchema: CustomerSchema
  });

  return (
    <ModalLayout className={styles.customer} type={EModalTypes.EDIT_CUSTOMER}>
      <FormikProvider value={formik}>
        <Form className={styles.customer__form}>
          <InputLabelFormik
            className={styles["customer__form-input"]}
            label="First name"
            required
            name="first_name"
          />
          <InputLabelFormik
            className={styles["customer__form-input"]}
            label="Last name"
            name="last_name"
            required
          />
          <InputLabelFormik
            className={styles["customer__form-input"]}
            label="Company name"
            name="company_name"
          />
          <div className={styles["customer__form-row"]}>
            <CountryCodeSelectFormik
              name="code"
              className={cn(styles["customer__form-select"])}
            />
            <InputLabelFormik
              className={styles["customer__form-input"]}
              label="Phone Number"
              name="mobile"
              extra={`+${formik.values.code?.code ?? 61}`}
            />
          </div>

          <InputLabelFormik
            className={styles["customer__form-input"]}
            label="Email"
            name="email"
            required
            extraBtn={
              typeof formik.values.alternate_email !== "string" ? (
                <PlusSvg
                  className={styles.plus_btn}
                  onClick={() => {
                    formik.setFieldValue("alternate_email", "");
                  }}
                />
              ) : null
            }
          />
          {typeof formik.values.alternate_email === "string" && (
            <InputLabelFormik
              className={styles["customer__form-input"]}
              label="Alternate email address"
              name="alternate_email"
              extraBtn={
                <MinusSvg
                  className={styles.plus_btn}
                  onClick={() => {
                    formik.setFieldValue("alternate_email", undefined);
                  }}
                />
              }
            />
          )}
          <InputLabelFormik
            className={styles["customer__form-input"]}
            label="Booking comment"
            name="comment"
          />
          <CheckboxFormik
            className={styles["customer__form-check"]}
            name="is_show_booking_comments"
            label="Insert these booking comments on all future bookings"
          />
          <InputLabelFormik
            className={styles["customer__form-input"]}
            label="Customer notes"
            name="customer_notes"
          />
          <InputLabelFormik
            className={styles["customer__form-input"]}
            label="Booking reference"
            name="booking_refno"
          />
          <CheckboxFormik
            className={styles["customer__form-check"]}
            name="is_set_booking_reference"
            label="Save booking reference for the next bookings"
          />
          <SelectFormik
            name={`vehicle_preference`}
            label="Set vehicle preference for all bookings"
            defaultValue={"Select vehicle"}
            list={cars}
            className={styles.select}
          />

          {!customer.mptp_code ? (
            <Checkbox
              id="mptp"
              checked={showMptp}
              setChecked={(checked) => {
                if (!checked) {
                  formik.setValues({
                    ...formik.values,
                    mptp_code: undefined,
                    mptp_date: undefined
                  });
                }
                setShowMptp(checked);
              }}
              className={styles["customer__form-check"]}
              label="MPTP customer"
            />
          ) : null}

          {showMptp ? (
            <div className={styles.customer__mptp}>
              <MptpCard
                customer={formik.values}
                updateCustomer={(data: Partial<ICustomer>) => {
                  onUpdateCustomer({
                    cid: customer.cid,
                    mtp_account: {
                      ...customer?.mtp_account,
                      member_number: data.mptp_code,
                      tm_valid: data.mptp_date
                    }
                  });
                }}
                config={{
                  ...config,
                  default_url: DEFAULT_URL
                }}
              />
            </div>
          ) : null}

          <Button
            className={styles.customer__button}
            color="ghost"
            type="submit"
            size="md"
            isLoading={loading}
          >
            Update
          </Button>
        </Form>
      </FormikProvider>
    </ModalLayout>
  );
};

export default EditCustomerModal;

import { SLICE_NAMES } from "@store/actionTypes";
import { createSelector } from "reselect";
import type { UserState } from "./type";

const selectUserStore = (state: {
  [key in SLICE_NAMES]: UserState;
}): UserState => state[SLICE_NAMES.MY_USER];

export const selectUser = createSelector(
  selectUserStore,
  (state) => state.user
);

export const selectShowFeedback = createSelector(
  selectUserStore,
  (state) => state.showFeedbacks
);

export const selectIsUserLoading = createSelector(
  selectUserStore,
  (state) => state.loading
);
export const selectUserLogo = createSelector(
  selectUserStore,
  (state) => state.logoUrl
);
export const selectExludedDrivers = createSelector(
  selectUserStore,
  (state) => state.excludedDrivers
);
export const selectPreferredDrivers = createSelector(
  selectUserStore,
  (state) => state.preferredDrivers
);
export const selectPreferredLoading = createSelector(
  selectUserStore,
  (state) => state.preferredLoading
);
export const selectExcludedLoading = createSelector(
  selectUserStore,
  (state) => state.exludedLoading
);

import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAMES } from "../actionTypes";
import type { BookingState } from "./type";
import type { IBookingForm } from "@interfaces";

const initialState: BookingState = {
  customer: {
    cid: -1,
    first_name: "",
    last_name: "",
    mobile: "",
    booking_refno: "",
    company_name: "",
    number: "",
    email: "",
    mobile_country_code: "",
    email_confirmed: false,
    preferred_drivers: [],
    preferred_drivers_count: 0,
    excluded_drivers: [],
    excluded_drivers_count: 0,
    app_booking: null,
    saveRef: false,
    showBook: false,
    customer_notes: "",
    comment: "",
    vehicle_preference: undefined
  },
  customerCoupon: null,
  bookings: [],
  paymentStatus: undefined,
  edit: false,
  showDetail: false,
  submited: false,
  refreshCount: 0,
  error: "",
  areaError: false,
  isPayment: false,
  activeBooking: 0,
  carTypesLoading: false,
  hourlyHire: false,
  clientBookingId: 0,
  serverError: false
};

const slice = createSlice({
  name: SLICE_NAMES.BOOKING,
  initialState,
  reducers: {
    setBookingCustomer(state, action) {
      state.customer = action.payload;
    },
    setBookings(state, action) {
      state.bookings = action.payload;
    },
    pushBooking(state, action) {
      state.bookings = [...state.bookings, action.payload];
    },
    removeBooking(state, action) {
      state.bookings = state.bookings.filter(
        (el) => el.index !== action.payload
      );
    },
    updateBookings(state, action) {
      state.bookings = state.bookings.map((booking) => {
        const newBooking = action.payload.find(
          (el: IBookingForm) => el.index === booking.index
        );
        return {
          ...booking,
          ...newBooking
        };
      });
    },
    updateBooking(state, action) {
      state.bookings = state.bookings.map((booking) => ({
        ...booking,
        ...(booking.index === action.payload.index && action.payload)
      }));
    },
    setPaymentStatus(state, action) {
      state.paymentStatus = action.payload;
    },
    updatePaymentStatus(state, action) {
      state.paymentStatus = { ...state.paymentStatus, ...action.payload };
    },
    updateBookingRate(state, action) {
      state.bookings = state.bookings.map((booking) =>
        booking.index === action.payload.id
          ? {
              ...booking,
              rates: booking.rates.map((rate) =>
                rate.id === action.payload.rate.id ? action.payload.rate : rate
              )
            }
          : booking
      );
    },
    setAreaError(state, action) {
      state.areaError = action.payload;
    },
    setIsPayment(state, action) {
      state.isPayment = action.payload;
    },
    clearAllBookingData(state) {
      state.customer = initialState.customer;
      state.bookings = initialState.bookings;
      state.paymentStatus = initialState.paymentStatus;
      state.edit = false;
      state.showDetail = false;
      state.error = "";
      state.refreshCount = +state.refreshCount + 1;
      state.hourlyHire = false;
      state.clientBookingId = 0;
      state.activeBooking = 0;
      state.customerCoupon = null;
      state.areaError = false;
      state.serverError = false;
    },
    setBookingEdit(state, action) {
      state.edit = action.payload;
    },
    setShowDetails(state, action) {
      state.showDetail = action.payload;
    },
    setBookingSubmited(state, action) {
      state.submited = action.payload;
    },
    setBookingError(state, action) {
      state.error = action.payload;
    },
    setActiveBooking(state, action) {
      state.activeBooking = action.payload;
      state.error = "";
    },
    setCartypeLoading(state, action) {
      state.carTypesLoading = action.payload;
    },
    setHourlyHire(state, action) {
      state.hourlyHire = action.payload;
    },
    setClientBookingId(state, action) {
      state.clientBookingId = action.payload;
    },
    setCustomerCoupon(state, action) {
      state.customerCoupon = action.payload;
    },
    setServerError(state, action) {
      state.serverError = action.payload;
    }
  }
});
export const {
  setBookingCustomer,
  setBookings,
  updateBookingRate,
  pushBooking,
  removeBooking,
  updateBookings,
  updateBooking,
  setPaymentStatus,
  updatePaymentStatus,
  clearAllBookingData,
  setBookingEdit,
  setShowDetails,
  setBookingSubmited,
  setBookingError,
  setIsPayment,
  setActiveBooking,
  setCartypeLoading,
  setHourlyHire,
  setClientBookingId,
  setCustomerCoupon,
  setAreaError,
  setServerError
} = slice.actions;

export default slice.reducer;

import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import cn from "classnames";

import { Footer, Header, Feedbacks } from "@components";
import { BOOKING_ROUTE, LOGIN_ROUTE, SIGNUP_ROUTE } from "@constants/routes";
import { useInitAction } from "@hooks";

import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import {
  selectIsUserLoading,
  selectShowFeedback,
  selectUser
} from "@store/user/selector";
import { ModalProvider } from "@contexts";
import { usePlacesWidget } from "react-google-autocomplete";
import { config } from "@constants/config";
import { Dots } from "@ui/Loader";

const Root = (): JSX.Element => {
  const user = useSelector(selectUser);
  const loading = useSelector(selectIsUserLoading);
  const showFeedbacks = useSelector(selectShowFeedback);
  const location = useLocation();
  const navigate = useNavigate();

  useInitAction();

  useEffect(() => {
    if (
      location.pathname === "/" ||
      (user && location.pathname.includes(LOGIN_ROUTE))
    ) {
      if (!loading) {
        navigate(`/${BOOKING_ROUTE}`);
      }
    }
  }, [user?.id, loading]);

  const isSignup = location.pathname === `/${SIGNUP_ROUTE}`;

  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: config.google_key,
    language: "en-AU",
    libraries: ["places"]
  });
  return loading ? (
    <Dots padding />
  ) : (
    <ModalProvider>
      <div className={styles.root} ref={ref}>
        <Header className={styles.root__header} />
        <div className={cn(styles.root__content, isSignup && styles.signup)}>
          <div className={cn("container", styles.root__container)}>
            <Outlet />
          </div>
        </div>
        {showFeedbacks ? <Feedbacks /> : null}

        <Footer className={styles.root__footer} />
      </div>
    </ModalProvider>
  );
};

export default Root;

import React from "react";
import cn from "classnames";

import styles from "./styles.module.scss";
import type { IDotsProps } from "@ui/ui.types";

const Dots = ({ padding = false }: IDotsProps): JSX.Element => {
  return (
    <div className={cn(styles.dots, padding && styles.padding)}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Dots;

import { SLICE_NAMES } from "@store/actionTypes";
import { createSelector } from "reselect";
import type { RootState } from "@store";

const selectReviewStore = (state: RootState): ReviewState =>
  state[SLICE_NAMES.REVIEW];

export const selectCustomerReviewStats = createSelector(
  selectReviewStore,
  (state) => state.customerReviewStats
);

export const selectSearch = createSelector(
  selectReviewStore,
  (state) => state.search
);

export const selectStatsLoading = createSelector(
  selectReviewStore,
  (state) => state.loading
);

import React, { useState } from "react";

import styles from "./styles.module.scss";
import type { ISetAgentDriverProps } from "../components.types";
import { useAppDispatch, useOutsideClick } from "@hooks";
import SearchList from "@ui/SearchList";
import { EDriver, type IDriver } from "@interfaces";
import { axiosInstance } from "@utils";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectUser } from "@store/user/selector";
import {
  pushExcludedDriver,
  pushPreferredDriver,
  setExcludedLoading,
  setPreferredLoading
} from "@store/user/reducer";
const SetAgentDriver = ({ type }: ISetAgentDriverProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const [showList, setShowList] = useState<boolean>(false);
  const toggleShowList = (): void => {
    setShowList((prev) => !prev);
  };

  const onClick = async (driver: IDriver): Promise<void> => {
    try {
      if (type === EDriver.excluded) {
        dispatch(setExcludedLoading(true));
      } else {
        dispatch(setPreferredLoading(true));
      }
      await axiosInstance.post(
        `agents/${user?.id}/set-${type}-driver-for-customers`,
        {
          did: driver.did
        }
      );
      if (type === EDriver.excluded) {
        dispatch(pushExcludedDriver(driver));
      } else {
        dispatch(pushPreferredDriver(driver));
      }
    } catch (e) {
      toast.error(e.data?.message ?? "Something went wrong");
    }
    toggleShowList();
  };
  const ref = useOutsideClick(toggleShowList, showList);

  if (!user) return <></>;
  return (
    <div className={styles.driver} ref={ref}>
      <div className={styles.driver__btn} onClick={toggleShowList}>
        Set {type} driver for all customers
      </div>
      {showList ? (
        <SearchList<IDriver>
          className={styles.driver__list}
          type={`drivers`}
          onClick={onClick}
          align="top"
        />
      ) : null}
    </div>
  );
};

export default SetAgentDriver;

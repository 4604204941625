import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { Button, Dots, SelectList } from "@ui";
import { CardLayout } from "@layouts";
import {
  Preferences,
  ResetPassword,
  Settings
} from "@modules/Profile/components";
import { axiosInstance } from "@utils";

import { selectUser } from "@store/user/selector";
import { setUser } from "@store/user/reducer";

import type { ISelectListItem } from "@ui/ui.types";
import type { ReactNode } from "react";
import type { IUserUpdate } from "@store/user/type";

import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { selectCountryCodes } from "@store/lookup/selector";
import { getCountryCodes } from "@store/lookup/actions";
import { useAppDispatch } from "@hooks";

enum EProfile {
  SETTINGS = "settings",
  PREFERENCES = "preferences",
  PASSWORD = "password"
}
let list = [
  { label: "Profile settings", value: EProfile.SETTINGS },
  { label: "Preferences", value: EProfile.PREFERENCES },
  { label: "Password", value: EProfile.PASSWORD }
];

const renderTab = (tab: ISelectListItem): ReactNode => {
  switch (tab.value) {
    case EProfile.SETTINGS:
      return <Settings />;
    case EProfile.PREFERENCES:
      return <Preferences />;
    case EProfile.PASSWORD:
      return <ResetPassword />;
    default:
      return null;
  }
};

const Profile = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const codes = useSelector(selectCountryCodes);
  const dispatch = useAppDispatch();

  if (user?.is_sub) {
    list = list.filter((nav) => {
      if (nav.label === "Password") {
        return true;
      } else {
        return false;
      }
    });
  }
  const [tab, setTab] = useState<ISelectListItem>(list[0]);

  const methods = useForm<IUserUpdate>({
    mode: "all"
  });

  const onSubmit = async (values: IUserUpdate): Promise<void> => {
    setLoading(true);
    try {
      if (tab.value === EProfile.PASSWORD) {
        if (user?.is_sub) {
          await axiosInstance.put(`agents/sub-account/${user?.id}`, {
            password: values.password
          });
        } else {
          await axiosInstance.post(`agents/${user?.id}/reset-password`, {
            password: values.password,
            password_confirmation: values.password_confirmation
          });
        }

        toast.success("Password Changed");
        setLoading(false);
        methods.setValue("password", "");
        methods.setValue("password_confirmation", "");
        return;
      }

      const response = await axiosInstance.put(`agents/update`, {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        mobile: values.mobile,
        company: values.company,
        ABN: values.ABN,
        mobile_country_code:
          typeof values.code !== "string" ? values.code?.code : values.code,
        addr_streetname:
          typeof values.addr_streetname === "string"
            ? values.addr_streetname
            : values.addr_streetname?.formatted_address,
        address_raw: JSON.stringify(values.addr_streetname),
        invoice_email: values.invoice_email,

        send_invoice: Number(values.send_invoice),
        use_invoice_email_only: Number(values.use_invoice_email_only),
        preferred_type: Number(values.preferred_type),
        ics: Number(values.ics),
        receive_reconfirmed: Number(values.receive_reconfirmed)
      });
      dispatch(setUser(response.data));
      toast.success("Updated");
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const checkKeyDown = (
    // eslint-disable-next-line
    e: any
  ): void => {
    if (e.key === "Enter") e.preventDefault();
  };

  useEffect(() => {
    if (codes.length === 0) {
      dispatch(getCountryCodes());
    }
    if (user) {
      methods.reset({
        ...user,
        addr_streetname: user?.full_address ?? "",
        code: user?.mobile_country_code ?? ""
      });
    }
  }, [user?.id]);

  if (!user || !codes?.length) {
    return <Dots />;
  }

  return (
    <div>
      <h1 className={styles.profile__title}>Profile</h1>

      <CardLayout className={styles.profile__card} leftBar>
        <FormProvider {...methods}>
          <form
            className={styles.profile__form}
            onSubmit={methods.handleSubmit(onSubmit)}
            onKeyDown={checkKeyDown}
            id="profile-form"
          >
            <SelectList active={tab} setActive={setTab} list={list} />
            {renderTab(tab)}
            <div className={styles.profile__actions}>
              <Button
                className={styles.profile__cancel}
                type="button"
                color="none"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel edit
              </Button>
              <Button
                className={styles.profile__btn}
                color="ghost"
                type="submit"
                isLoading={loading}
              >
                Update
              </Button>
            </div>
          </form>
        </FormProvider>
      </CardLayout>
    </div>
  );
};

export default Profile;

import React, { useState } from "react";
import cn from "classnames";

import type { ISelectProps } from "../components.types";
import { ArrowSvg } from "@assets/svg";

import styles from "./styles.module.scss";
import { useOutsideClick, usePopupDirection } from "@hooks";

const Select = ({
  className,
  label,
  defaultValue,
  value,
  border = false,
  innerClassName,
  onChange,
  list = []
}: ISelectProps): JSX.Element => {
  const [show, setShow] = useState<boolean>(false);

  const { ref: popupRef, position, handleScroll } = usePopupDirection();
  const toggleShow = (): void => {
    handleScroll();
    setShow((prev) => !prev);
  };

  const ref = useOutsideClick(toggleShow, show);

  return (
    <div className={cn(styles.select, innerClassName)} ref={ref}>
      <div
        className={cn(styles.select__btn, className, border && styles.border)}
        onClick={toggleShow}
        ref={popupRef}
      >
        <div className={styles["select__btn-label"]}>{label}</div>
        <div
          className={cn(
            styles["select__btn-value"],
            Boolean(value) && styles.active
          )}
        >
          {value?.label ?? defaultValue}
        </div>
        <div className={styles["select__btn-arrow"]}>
          <ArrowSvg />
        </div>
        <div
          className={cn(
            styles.select__list,
            show && styles.active,
            styles[position]
          )}
        >
          {list.map((el) => (
            <div
              key={el.value}
              className={cn(
                styles["select__list--item"],
                el.value === value?.value && styles.active
              )}
              onClick={() => {
                if (onChange !== undefined) onChange(el);
              }}
            >
              {el.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Select;

import React, { useContext, useState } from "react";
import cn from "classnames";
import moment from "moment";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";

import { ModalLayout } from "@layouts";
import { EModalTypes } from "@constants/modal";
import {
  Button,
  DatePickerFormik,
  MultipleCalendar,
  TimePickerFormik
} from "@ui";
import { MinusSvg } from "@assets/svg";

import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import {
  seelctHourlyHire,
  selectBookingCustomer,
  selectBookings,
  selectClientBookingId
} from "@store/booking/selector";
import type {
  IBookingRepeatForm,
  IRepeatDate,
  IRepeatForm,
  TDateCalendar
} from "@interfaces";
import { useAppDispatch } from "@hooks";
import { ModalContext } from "@contexts";
import { DATE_FORMAT, MAX_BOOKINGS_IN_ORDER } from "@constants/config";
import { RepeatSchema, getCarTypes } from "@utils";
import { toast } from "react-toastify";

const sortDates = (dates: IRepeatForm[]): IRepeatForm[] => {
  const sortedDates = dates.sort((a, b) => {
    const dateA = new Date(a.date?.toString() ?? 0).getTime();
    const dateB = new Date(b.date?.toString() ?? 0).getTime();
    return dateA - dateB;
  });

  return sortedDates;
};

const RepeatModal = (): JSX.Element => {
  const modalContext = useContext(ModalContext);
  const dispatch = useAppDispatch();
  const bookings = useSelector(selectBookings);
  const clientBookingId = useSelector(selectClientBookingId);
  const customer = useSelector(selectBookingCustomer);

  const hourlyHire = useSelector(seelctHourlyHire);
  const [loading, setLoading] = useState<boolean>(false);
  const initDates = bookings.reduce<IRepeatDate[]>(
    (prev, cur) => [
      ...prev,
      { date: cur.date, disabled: true },
      ...(cur.repeats?.map((el) => ({ date: el.date })) ?? [])
    ],
    []
  );

  const [dates, setDates] = useState<IRepeatDate[]>(
    Array.from(new Set(initDates))
  );
  const onSubmit = async (values: IBookingRepeatForm): Promise<void> => {
    setLoading(true);
    const cartypesPromises = values.bookings.map(async (el) => {
      return await getCarTypes(
        clientBookingId,
        el,
        customer,
        hourlyHire,
        dispatch
      );
    });
    await Promise.all(cartypesPromises);
    setLoading(false);
    modalContext?.handleClose();
  };

  const formik = useFormik<IBookingRepeatForm>({
    initialValues: { bookings },
    onSubmit,
    validationSchema: RepeatSchema
  });

  const removed = (date: TDateCalendar): void => {
    formik.values.bookings.forEach((el, index) => {
      if (
        moment(el.date?.toString()).format(DATE_FORMAT) ===
        moment(date?.toString()).format(DATE_FORMAT)
      ) {
        return;
      }
      formik.setFieldValue(
        `bookings[${index}].repeats`,
        el.repeats.filter(
          (d) =>
            moment(d.date?.toString()).format(DATE_FORMAT) !==
            moment(date?.toString()).format(DATE_FORMAT)
        )
      );
    });
  };

  const added = (date: TDateCalendar): void => {
    const bookingsAmount = formik.values.bookings.reduce<number>(
      (prev, cur) => prev + cur.repeats.length + 1,
      0
    );
    if (
      bookingsAmount + formik.values.bookings.length >=
      MAX_BOOKINGS_IN_ORDER
    ) {
      setDates((prev) =>
        prev.filter(
          (el) =>
            moment(el.date?.toString()).format(DATE_FORMAT) !==
            moment(date?.toString()).format(DATE_FORMAT)
        )
      );
      toast.info(`Max bookings amount is ${MAX_BOOKINGS_IN_ORDER}`);
      return;
    }
    formik.values.bookings.forEach((el, index) => {
      if (
        moment(el.date?.toString()).format(DATE_FORMAT) ===
          moment(date?.toString()).format(DATE_FORMAT) ||
        el.repeats.find(
          (el) =>
            moment(el.date?.toString()).format(DATE_FORMAT) ===
            moment(date?.toString()).format(DATE_FORMAT)
        )
      ) {
        return;
      }
      void formik.setFieldValue(
        `bookings[${index}].repeats`,
        sortDates([
          ...(el.repeats ?? []),
          {
            date,
            time: el.time,
            stopDate: date,
            stopTime: el.stopTime
          }
        ])
      );
    });
  };

  return (
    <ModalLayout className={styles.repeat} type={EModalTypes.REPEAT}>
      <FormikProvider value={formik}>
        <Form>
          <div className={styles.repeat__wrapper}>
            <h1 className={styles.repeat__title}>Repeat booking</h1>
            <MultipleCalendar
              className={styles.repeat__calendar}
              dates={dates}
              setDates={setDates}
              removedFromList={removed}
              addedToList={added}
            />

            <div className={styles.repeat__list}>
              {bookings.map((el, index) => (
                <div className={styles.booking} key={el.index}>
                  <h1 className={cn(styles.booking__title, styles.active)}>
                    {el.label}
                  </h1>
                  <p>{`${moment(el.date?.toString()).format("DD MMM")} ${
                    el.time?.hour ?? ""
                  }:${el.time?.minute ?? ""}${
                    hourlyHire
                      ? ` - ${moment(el.stopDate?.toString()).format(
                          "DD MMM"
                        )} ${el.stopTime?.hour ?? ""}:${
                          el.stopTime?.minute ?? ""
                        }`
                      : ""
                  }`}</p>
                  <p>From: {el.from?.formatted_address}</p>
                  {el.stops?.length > 0 ? (
                    <p>
                      Stop:{" "}
                      {el.stops.map(
                        (stop, index) =>
                          `${index !== 0 ? ", " : ""}${stop?.formatted_address}`
                      )}
                    </p>
                  ) : null}

                  <p>To: {el.to?.formatted_address}</p>

                  <FieldArray
                    name={`bookings[${index}].repeats`}
                    render={(arrayHelpers) => {
                      return (
                        <>
                          <div className={styles.booking__row} key={index}>
                            <DatePickerFormik
                              className={styles["booking__row-item"]}
                              key={`bookings[${index}].date`}
                              name={`bookings[${index}].date`}
                              label="Date"
                              arrow={false}
                              disabled
                            />
                            <TimePickerFormik
                              className={cn(
                                styles["booking__row-item"],
                                styles["no-border"]
                              )}
                              name={`bookings[${index}].time`}
                              key={`bookings[${index}].time`}
                              label="Time"
                              arrow={false}
                              disabled
                            />
                          </div>
                          {hourlyHire ? (
                            <div className={styles.booking__row} key={index}>
                              <DatePickerFormik
                                className={styles["booking__row-item"]}
                                key={`bookings[${index}].stopDate`}
                                name={`bookings[${index}].stopDate`}
                                label="Stop Date"
                                arrow={false}
                                disabled
                              />
                              <TimePickerFormik
                                className={cn(
                                  styles["booking__row-item"],
                                  styles["no-border"]
                                )}
                                name={`bookings[${index}].stopTime`}
                                key={`bookings[${index}].stopTime`}
                                label="Stop Time"
                                arrow={false}
                                disabled
                              />
                            </div>
                          ) : null}
                          {formik.values.bookings[index].repeats?.map(
                            (el, repeatIndex) => (
                              <>
                                <div
                                  className={styles.booking__row}
                                  key={repeatIndex}
                                >
                                  <DatePickerFormik
                                    className={cn(
                                      styles["booking__row-item"],
                                      styles.first
                                    )}
                                    key={`bookings[${index}].repeats[${repeatIndex}].date`}
                                    name={`bookings[${index}].repeats[${repeatIndex}].date`}
                                    label="Date"
                                    arrow={false}
                                    error={!!el.error}
                                    disabled
                                  />
                                  <TimePickerFormik
                                    className={cn(
                                      styles["booking__row-item"],
                                      styles["no-border"]
                                    )}
                                    name={`bookings[${index}].repeats[${repeatIndex}].time`}
                                    key={`bookings[${index}].repeats[${repeatIndex}].time`}
                                    label="Time"
                                    arrow={false}
                                  />
                                  <div
                                    className={styles["booking__row-btn"]}
                                    onClick={() => {
                                      arrayHelpers.remove(repeatIndex);
                                    }}
                                  >
                                    <MinusSvg />
                                  </div>
                                </div>
                                {hourlyHire ? (
                                  <div
                                    className={styles.booking__row}
                                    key={repeatIndex}
                                  >
                                    <DatePickerFormik
                                      className={cn(
                                        styles["booking__row-item"]
                                      )}
                                      key={`bookings[${index}].repeats[${repeatIndex}].stopDate`}
                                      name={`bookings[${index}].repeats[${repeatIndex}].stopDate`}
                                      label="Stop Date"
                                      arrow={false}
                                      disabled
                                    />
                                    <TimePickerFormik
                                      className={cn(
                                        styles["booking__row-item"],
                                        styles["no-border"]
                                      )}
                                      name={`bookings[${index}].repeats[${repeatIndex}].stopTime`}
                                      key={`bookings[${index}].repeats[${repeatIndex}].stopTime`}
                                      label="Stop Time"
                                      arrow={false}
                                    />
                                  </div>
                                ) : null}
                              </>
                            )
                          )}
                        </>
                      );
                    }}
                  />
                </div>
              ))}
            </div>
            <Button size="full" type="submit" isLoading={loading}>
              Confirm
            </Button>
          </div>
        </Form>
      </FormikProvider>
    </ModalLayout>
  );
};

export default RepeatModal;

import React from "react";

import styles from "./styles.module.scss";

import { CardLayout } from "@layouts";
import { useAppSelector } from "@hooks";

import { selectCustomerReviewStats } from "@store/review/selector";
import RatingWithStars from "@modules/Feedback/components/RatingWithStarts";

const Stats = (): JSX.Element => {
  const customerReviewStats = useAppSelector(selectCustomerReviewStats);

  // --> RENDER
  function renderCardLayout(
    statItem: ICustomerReviewStatItem,
    id: string
  ): JSX.Element {
    return (
      <>
        <RatingWithStars
          value={
            typeof statItem.ratedriver === "string"
              ? +Number(statItem.ratedriver).toFixed(1)
              : 0
          }
          id={id}
        />
        <p>{`${statItem.customer_count} reviews`}</p>
      </>
    );
  }
  return (
    <div className={styles.stats}>
      <div className={styles.stats__block}>
        <h1>This month reviews</h1>
        <CardLayout className={styles["stats__block-card"]}>
          {renderCardLayout(customerReviewStats.month, "month")}
        </CardLayout>
      </div>
      <div className={styles.stats__block}>
        <h1>All time reviews</h1>
        <CardLayout className={styles["stats__block-card"]}>
          {renderCardLayout(customerReviewStats.all_time, "all")}
        </CardLayout>
      </div>
    </div>
  );
};

export default Stats;

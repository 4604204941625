import { useEffect, useState } from "react";

export const useMobileResize = (size = 1100): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const handleResize = (): void => {
    setIsMobile(window.innerWidth < size);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile;
};

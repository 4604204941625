import React from "react";
import cn from "classnames";

import { EmptyComments, LongArrowSvg } from "@assets/svg";

import type { IFeedbackProps } from "../components.types";

import styles from "./styles.module.scss";
import { useInfiniteFetchData } from "@hooks";
import { RatingWithStars } from "@modules/Feedback/components";
import Skeleton from "react-loading-skeleton";
import { useDrag } from "@use-gesture/react";
import { useNavigate } from "react-router-dom";
import { FEEDBACK_ROUTE } from "@constants/routes";

const Feedback = ({
  className,
  feedback,
  loading = false,
  empty = false,
  list = [],
  onNext,
  onPrev
}: IFeedbackProps): JSX.Element => {
  const navigate = useNavigate();
  const bind = useDrag((state) => {
    const x = state.movement[0];
    const active = state.active;
    if (active) return;
    if (x > 50) {
      onNext();
    }
    if (x < 50) {
      onPrev();
    }
  });
  return (
    <div
      className={cn(
        styles.feedback,
        empty && styles.empty,
        className,
        feedback?.action_customer && styles.lg
      )}
      {...bind()}
      style={{ touchAction: "none" }}
      onClick={() => {
        if (feedback)
          navigate(`/${FEEDBACK_ROUTE}/${feedback.id}`, {
            state: list
          });
      }}
    >
      {empty ? (
        <div className={styles.feedback__empty}>
          <EmptyComments />
          <div className={styles["feedback__empty-title"]}>No feedback yet</div>
          <div className={styles["feedback__empty-info"]}>
            Customer feedback specific to your account will show here after they
            travel.
          </div>
        </div>
      ) : (
        <>
          <RatingWithStars
            value={(feedback?.ratedriver ?? 0) / 10}
            className={styles.feedback__stars}
            id={feedback?.id ?? 0}
          />
          <div className={styles.feedback__text}>
            {loading ? (
              <Skeleton width="100%" height={50} />
            ) : (
              feedback?.comments
            )}
          </div>
          <b className={styles.feedback__name}>
            {loading ? (
              <Skeleton width="60%" />
            ) : (
              feedback?.customer_name.toLocaleLowerCase()
            )}
          </b>
          <div className={styles.feedback__info}>
            {loading ? (
              <Skeleton width="80%" />
            ) : (
              `Booking ${feedback?.oid}, ${feedback?.postdate}`
            )}
          </div>
          {feedback?.action_customer ? (
            <div className={styles.feedback__action}>
              <div className={styles["feedback__action-avatar"]}>NH</div>
              <div className={styles["feedback__action-right"]}>
                <b className={styles["feedback__action-title"]}>GPU response</b>
                <div className={styles["feedback__action-text"]}>
                  {feedback.action_customer}
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

const Feedbacks = (): JSX.Element => {
  const { list, hasMore, nextPage, loading, setData } =
    useInfiniteFetchData<ICustomerReview>(`customer-polls?`);

  const initLoading = loading && list.length === 0;
  const onNext = (): void => {
    if (initLoading) return;

    setData((prev) => {
      const newFeedbacks = [];
      for (let index = 0; index < prev.length; index++) {
        if (prev[index].show === false && prev[index + 1]?.show !== false) {
          newFeedbacks.push({ ...prev[index], show: true });
        } else {
          newFeedbacks.push(prev[index]);
        }
      }
      return newFeedbacks;
    });
  };

  const onPrev = (): void => {
    if (initLoading) return;

    setData((prev) => {
      let changed = false;

      return prev.map((el, index) => {
        if (el.show !== false && !changed) {
          if (list.length - index < 5 && !loading && hasMore) {
            nextPage();
          }
          changed = true;
          return { ...el, show: false };
        }
        return el;
      });
    });
  };

  return (
    <div className={styles.feedbacks}>
      <div className="container">
        <h1 className={styles.feedbacks__title}>Your last feedbacks</h1>
      </div>
      <div className={styles.feedbacks__box}>
        {!initLoading && list.length === 0 ? null : (
          <div className={styles.feedbacks__arrows}>
            <LongArrowSvg className={styles.left} onClick={onNext} />
            <LongArrowSvg onClick={onPrev} />
          </div>
        )}
        <div className={styles.feedbacks__list}>
          {!initLoading && list.length === 0 ? (
            <Feedback
              className={styles.feedbacks__empty}
              empty
              onNext={onNext}
              onPrev={onPrev}
            />
          ) : null}
          {initLoading ? (
            <>
              <Feedback loading={initLoading} onNext={onNext} onPrev={onPrev} />
              <Feedback loading={initLoading} onNext={onNext} onPrev={onPrev} />
              <Feedback loading={initLoading} onNext={onNext} onPrev={onPrev} />
            </>
          ) : (
            list.map((el) => (
              <Feedback
                className={cn(
                  el.show === true ? styles.show : "",
                  el.show === false ? styles.hide : ""
                )}
                feedback={el}
                loading={initLoading}
                onNext={onNext}
                onPrev={onPrev}
                key={el.id}
                list={list}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Feedbacks;

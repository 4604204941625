import cn from "classnames";
import React, { useContext } from "react";
import styles from "./styles.module.scss";
import { slide as Menu } from "react-burger-menu";
import {
  BOOKING_ROUTE,
  LOGIN_ROUTE,
  NAVIGATION_MENU,
  PROFILE_ROUTE
} from "@constants/routes";
import { NavLink } from "react-router-dom";
import { useAppDispatch } from "@hooks";
import { useSelector } from "react-redux";
import { selectUser } from "@store/user/selector";
import { logout } from "@store/user/actions";
import { clearAllBookingData } from "@store/booking/reducer";
import { ModalContext } from "@contexts";
import { EModalTypes } from "@constants/modal";
import { footerLinks } from "@constants/config";

export default function Sidebar(): JSX.Element {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const modalContext = useContext(ModalContext);

  const onLogout = (): void => {
    void dispatch(logout());
  };
  const btn = document.getElementById("react-burger-cross-btn") as HTMLElement;

  return (
    <div className={styles.burger}>
      <Menu
        overlayClassName={styles.overlay}
        customBurgerIcon={
          <div className={cn(styles.btn, styles.mobile)}>
            <span />
            <span />
            <span />
          </div>
        }
      >
        <div className={styles.menu_container}>
          <div
            className={cn(styles.profile, styles.popup)}
            onClick={() => {
              if (btn) btn.click();
            }}
          >
            <div className={styles.avatar}></div>
            <div className={styles.name}>{user?.first_name}</div>
          </div>
          <div className={styles.menu}>
            {NAVIGATION_MENU.filter((nav) => {
              if (user?.is_sub && nav.title === "Agents") return false;
              else return true;
            }).map(({ to, title, icon: Icon }) => (
              <NavLink
                className={({ isActive }) => (isActive ? styles.active : "")}
                to={to}
                key={to}
                onClick={() => {
                  if (btn) btn.click();
                  if (to === BOOKING_ROUTE) {
                    dispatch(clearAllBookingData());
                  }
                }}
              >
                <Icon /> {title}
              </NavLink>
            ))}
          </div>
          <div className={styles.menu}>
            <NavLink
              className={({ isActive }) => (isActive ? styles.active : "")}
              to={`/${PROFILE_ROUTE}`}
              onClick={() => {
                if (btn) btn.click();
              }}
            >
              Profile
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? styles.active : "")}
              to={`/${LOGIN_ROUTE}`}
              onClick={() => {
                onLogout();
                if (btn) btn.click();
              }}
            >
              Logout
            </NavLink>
          </div>
          <div className={styles.list}>
            <div
              className={styles.link}
              onClick={(e) => modalContext?.handleOpen(EModalTypes.CITIES, e)}
            >
              Explore cities we serve
            </div>
            <a href={footerLinks.faq} target="_blank" rel="noreferrer">
              FAQ
            </a>
            <a href={footerLinks.support} target="_blank" rel="noreferrer">
              Support
            </a>
            <a href={footerLinks.services} target="_blank" rel="noreferrer">
              services
            </a>
          </div>
        </div>
      </Menu>
    </div>
  );
}

import { WelcomeBack } from "@components";
import { useAppDispatch } from "@hooks";
import { MainLayout } from "@layouts";
import { Profile } from "@modules/Profile/widgets";
import {
  getAgentExcludedDrivers,
  getAgentPreferredDrivers
} from "@store/user/actions";
import { selectUser } from "@store/user/selector";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const ProfilePage = (): JSX.Element => {
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (user) {
      dispatch(getAgentExcludedDrivers(user?.aid));
      dispatch(getAgentPreferredDrivers(user?.aid));
    }
  }, [user?.id]);
  return (
    <MainLayout extra={<WelcomeBack />}>
      <Profile />
    </MainLayout>
  );
};

export default ProfilePage;

import React from "react";
import cn from "classnames";

import styles from "./styles.module.scss";
import { ModalLayout } from "@layouts";
import { EModalTypes } from "@constants/modal";

type Tlist = "lower-alpha" | "upper-alpha" | "upper-roman" | "decimal" | "none";

interface IList {
  title?: string;
  text?: string;
  list?: Tlist | undefined;
  data: Array<IList | string>;
}

interface IListProps {
  data: Array<IList | string>;
  list?: Tlist | undefined;
}

const data: IList[] = [
  {
    title: "HOW GPU WORKS",
    data: [
      "GPU provides a booking service and platform for Customers to obtain transport services from independent drivers and businesses who hold all necessary licences and approvals to provide transport services who have been engaged by GPU as its subcontractors.",
      "When you use the Application to place a booking for transport services, you offer to contract with GPU for the provision of those services in accordance with your booking. GPU may accept your offer and subcontract the performance of those services to a Driver."
    ]
  },
  {
    title: "DEFINITIONS AND INTERPRETATION",
    list: "decimal",
    data: [
      {
        title: "DEFINITIONS",
        data: [
          "In this agreement:",
          "Account means a Personal Account or Business Account as the context requires;",
          "Application means GPU's software which facilitates the booking of Driver Services by you, whether accessed online through GPU's website or through a downloaded application;",
          "Authorisations means all licences, accreditations, approvals and endorsements required to provide the Driver Services in the area or jurisdiction in which the Driver Services are to be provided;",
          "Available Vehicle has the meaning given in clause 5(e);",
          "Booking means your offer to GPU for the GPU to provide the Driver Services (through GPU's subcontract with the Driver) in accordance with the Booking Information;",
          "Booking Conditions refers to the particular operational information and relevant conditions relating to a specific Booking (including wait times and locations, cancellation information and other specific instructions relating to the Booking) which is notified to you via the Application at the time of confirming a Booking;",
          "Booking Information means the information provided by a Customer which sets out the parameters for the provision of the Driver Services; Business Account means an Account maintained by a business for its employees or nominated users;",
          "Claim means any claim, cause of action, whether known or unknown, right of set off, complaint, cost, demand, damages, debt due, judgment, enforceable award or order, for payment of any amount or any other relief howsoever arising;",
          "Device means any personal electronic device (including a desktop computer) from which the Application can be accessed;",
          "Driver means a third party transport service operator who provides Driver Services to Customers as GPU's subcontractor; Driver Services means the private hire passenger transportation services provided by GPU to the Customer through GPU's subcontract with the Driver in accordance with the Booking Information;",
          "Driver Services Fee means that part of the Quoted Fare determined in accordance with clause 5(c);",
          "Feedback Rating means the score given by you as a measure of the your satisfaction with the Driver Services provided; GPU Services means the acceptance of a Booking from the Customer and the arrangement for the provision of Driver Services to be provided by the Driver as GPU's subcontractor, as undertaken by GPU in accordance with this agreement;",
          "GPU Services Fee means that part of the Quoted Fare determined in accordance with clause 5(c) which is attributable to the GPU Services provided by GPU;",
          "GST means goods and services tax imposed under the GST Act; GST Act means the A New Tax System (Goods and Services Tax) Act 1999 (Cth), any regulation made pursuant to any such Act and as amended from time to time;",
          "Intellectual Property means all industrial and intellectual property rights both in Australia and throughout the world, whether registered or not and whether now or devised in the future, and for the duration of the rights including any:",
          {
            list: "lower-alpha",
            data: [
              "patents, copyright, registered or unregistered marks or service marks, trade names, brand names, indications of source or appellations of origin, registered designs and commercial names and designations, circuit layouts and database rights;",
              "ideas, processes, inventions, discoveries, trade secrets, know-how, computer software (both source code and object code), confidential information and scientific, technical and product information; and",
              "right to apply for or renew the registration of any such rights;"
            ]
          },
          "Law means:",
          {
            list: "lower-alpha",

            data: [
              "any statute, regulation, ordinance or proclamation, council regulation or by-law;",
              "any order, official policy, directive, request, requirement or guideline of any government agency, present or future, whether or not having the force of law; or",
              "duties and obligations arising under common law to third parties; Nominated Account means the bank account or credit card account nominated by you from which the Quoted Fare will be deducted;"
            ]
          },
          "Prohibited Goods means:",
          {
            list: "lower-alpha",
            data: [
              "items that may pose a danger to health, safety, or property while being transported (such as explosives, radioactive materials, flammable gases and solids, and toxic substances);",
              "any goods that cannot be shipped via regular or registered mail or via standard commercial carrier; or",
              "perishable goods of any kind;"
            ]
          },
          "Quoted Fare means the aggregate of:",
          {
            list: "lower-alpha",
            data: [
              "the Driver Services Fee, in consideration for the provision of the Driver Services; and",
              "the GPU Services Fee in consideration for the provision of the GPU Services."
            ]
          }
        ]
      },
      {
        title: "INTERPRETATION",
        data: [
          "In this agreement, unless the context otherwise requires:",
          {
            list: "lower-alpha",
            data: [
              "headings are for convenience only and do not affect the interpretation of this agreement;",
              "words importing the singular include the plural and vice versa;",
              "words importing a gender include any gender;",
              "a reference to a part, clause, party, annexure,  exhibit or schedule is a reference to a part, clause, party, annexure, exhibit or schedule to, this agreement;",
              "a reference to a person includes any individual or individuals, corporation, partnership or other body, entity or other legal person;",
              "including or includes means including or includes (as the case may be) without limitation;",
              "time is a reference to time in the jurisdiction in which the Driver Services are provided; and",
              "where a payment or other act must be made or done on a day that is not a Business Day, then it must be made or done on the next Business Day."
            ]
          }
        ]
      }
    ]
  },
  {
    title: "TERM",
    data: [
      "This agreement commences on the date you first make use of the Application and it continues until it is terminated in accordance with clause 12."
    ]
  },
  {
    title: "GPU APPLICATION",
    list: "decimal",
    data: [
      {
        title: "ELIGIBILITY",
        data: [
          "You warrant and agree that:",
          {
            list: "lower-alpha",
            data: [
              "you are legally capable of entering into binding contracts under applicable Law including, without limitation, this agreement;",
              "you are 18 years old or older; and",

              {
                list: "upper-roman",
                text: "any information you input into the Application:",
                data: [
                  "will be dealt with by GPU in accordance with GPU's privacy policy as amended from time to time; and",
                  'may be disclosed to any interested party in the account  should the "business and/or account" payment option be selected, or the listed holder or administrator of the Customer\'s Account, and may be reviewable by the Customer.'
                ]
              }
            ]
          }
        ]
      },
      {
        title: "LICENCE TO USE THE APPLICATION",
        data: [
          {
            list: "lower-alpha",
            data: [
              "Subject to the terms and conditions of this agreement, GPU grants you a non-transferable and non-exclusive licence to use the Application.",
              {
                text: "You acknowledge that:",
                list: "upper-roman",
                data: [
                  "you may use the Application only on a Device owned and controlled by you;",
                  "use of the Application is restricted to you;",
                  "you may access and use the Application on your Device only for the purposes of making Bookings for Driver Services; and",

                  {
                    text: "you must not:",
                    list: "upper-alpha",
                    data: [
                      "alter, merge, modify or adapt the Application in any way, including but not limited to disassembling or decompiling;",
                      "loan, rent, lease or licence the Application or any copy; or",
                      "transfer the Application, or any copy, in whole or in part, in any way, including but not limited to disassembling or decompiling, except as expressly provided for in this agreement."
                    ]
                  }
                ]
              },
              "The provision and subsequent use of the Application is subject to the reliability and availability of third party service providers including software providers and network service providers.",
              "All Intellectual Property related to the Application or created during the Term of this agreement is the sole property of GPU.",
              "GPU will not be liable for any direct or indirect loss suffered by you as a result of a reduced level of service caused by any third party.",
              {
                list: "upper-roman",
                text: "GPU is not responsible for any loss, cost, expense or damage caused by any disruption to a Booking or the GPU Service or the operation or failure of the Application caused by:",
                data: [
                  "any disruption to the connection to the internet or GPU's server;",
                  "any cancellation or non-appearance or misinformation provided by you or the Driver;",
                  "any system failure by the Device that you use to access the Application to properly connect to and run the Application;",
                  "any disruption to the Application; or",
                  "any other event beyond reasonable control of GPU including but not limited to any hacking of or virus attack on the Application."
                ]
              }
            ]
          }
        ]
      },
      {
        title: "ESTABLISHING AN ACCOUNT",
        data: [
          {
            list: "lower-alpha",
            data: [
              "In order to place Bookings for Driver Services, you must apply to set up either a Personal Account or a Business Account through the Application.",

              {
                text: "In order to set up a Personal Account, you are required to provide GPU with certain information, which may include:",
                list: "upper-roman",
                data: [
                  "your name, telephone number and email address; and",
                  "your credit/debit card details."
                ]
              },

              {
                text: "In order to set up a Business Account, you will be required to provide GPU with certain information, which may include:",
                list: "upper-roman",
                data: [
                  "your business' name, telephone number, physical address and email address;",
                  "your business' credit/debit or other account facility card details; and",
                  "(if applicable) any specific instructions regarding who within your organisation is authorised to place Bookings and maximum spend limits."
                ]
              },
              "If you establish a Business Account, you warrant that each end user of the Driver Services will comply with the terms of this agreement to the extent applicable.",
              "GPU reserves the right to terminate your Account at any time immediately and without notice to you."
            ]
          }
        ]
      }
    ]
  },
  {
    title: "BOOKING PROCESS",
    data: [
      {
        list: "lower-alpha",
        data: [
          {
            text: "After accessing the Application and establishing an Account, you may book transport services by inputting the following information into the Application on your Device (Booking Information):",
            list: "upper-roman",
            data: [
              "your name, telephone number, email address and other personal information required;",
              "your intended collection, destination points or general work area locations;",
              "the time and duration you wish the Driver Services to be provided;",
              "the number of passengers to be transported; and",
              "the number of pieces of luggage to be transported."
            ]
          },
          "If you are booking Driver Services on behalf of another party, it is your responsibility to ensure that party is aware and agrees to the terms of this agreement as are applicable to them and you warrant to GPU and the Driver that the end user of the Driver Services agrees to be bound by the terms of this agreement.",

          {
            list: "upper-roman",
            text: "Depending on the availability of suitable vehicles or Drivers, GPU will provide a quote (Quoted Fare) for the cost of the Driver Services to be provided in accordance with the Booking Information you have submitted. The Quoted Fare is calculated by software based upon, among other factors:",
            data: [
              "the total duration of the Driver Services;",
              "the distance of the intended journey;",
              "the general level of demand for transport services;",
              "any applicable fuel levy and related transport expenses; and",
              "the tolls and charges imposed along that route, and is fully inclusive of GST. The Quoted Fare may be a fixed total amount or alternatively it may be a range, a per kilometre or per minute rate, at GPU's discretion."
            ]
          },
          "If you accept the Quoted Fare, you make a formal offer to pay the quoted price for the services in accordance with the information you provided (Booking).",
          "If GPU accepts your Booking, in its absolute discretion, GPU may locate an available vehicle in the area of the collection point and confirm with you via the Application that the vehicle is available to provide Driver Services in accordance with your Booking (Available Vehicle).",

          "GPU may decline your Booking (even if GPU has already notified you that your Booking has been accepted) in its absolute discretion or when GPU cannot locate an Available Vehicle. If GPU declines your Booking, GPU will attempt to notify you via the Application or the contact details you provide.",

          {
            text: "If GPU accepts your Booking, GPU will provide you with information through the Application which may include:",
            list: "upper-roman",
            data: [
              "the Driver's Feedback Rating (if any);",
              "the Driver's name; and",
              "the Driver's estimated time of arrival at the specified pickup destination or general work area."
            ]
          },
          "If GPU accepts your Booking, GPU will provide the Driver with the Booking Information through the Application to enable the Driver to provide you with the Driver Services."
        ]
      }
    ]
  },
  {
    title: "BOOKING CANCELLATIONS AND REFUNDS",
    data: [
      "You must comply with the bookings, cancellation and refund processes set out in the GPU Booking Conditions for each individual Booking. The Booking Conditions form part of this agreement."
    ]
  },
  {
    title: "DRIVER SERVICES",
    list: "decimal",
    data: [
      {
        title: "GENERAL",
        data: [
          {
            list: "lower-alpha",
            data: [
              "Once GPU has provided your Booking Information to a Driver, the Driver will provide you with the Driver Services as GPU's subcontractor.",
              {
                list: "upper-roman",
                text: "The Driver agrees that they will:",
                data: [
                  "maintain all necessary Authorisations to provide the Driver Services;",
                  "perform the Driver Services in a safe, prompt and efficient manner and to your reasonable satisfaction;",
                  "exercise reasonable care, skill and judgement; and",
                  "conduct themselves professionally at all times in carrying out its obligations under this agreement including while providing the Driver Services."
                ]
              }
            ]
          }
        ]
      },
      {
        title: "SUBCONTRACTING, ASSIGNMENT AND OFFLOADING",
        data: [
          {
            list: "lower-alpha",

            data: [
              "The Driver may assign or 'offload' (submit the Booking back into the GPU system for acceptance by an alternate driver) the Driver Services specified in a Booking at any time and at the Driver's sole discretion.",
              "If a Driver assigns or 'offloads' the Driver Services associated with a Booking to an alternate driver, the alternate driver stands in the place of the Driver and this agreement will apply to the alternate driver as though they were the Driver in every material respect."
            ]
          }
        ]
      }
    ]
  },
  {
    title: "PAYMENT",
    list: "decimal",
    data: [
      {
        title: "PERSONAL ACCOUNTS",
        data: [
          {
            list: "lower-alpha",
            data: [
              "If GPU accepts a Booking from you made through a Personal Account, payment will be deducted from the credit/debit card specified in the Personal Account information at the time GPU accepts the Booking.",
              "If there are insufficient funds to be deducted from the credit/debit card provided, the Booking may be cancelled and/or GPU may continue to attempt to charge the credit/debit card provided until payment is made in full. GPU reserves its rights to pursue any debts owed by you using whatever means it sees fit, subject to applicable Laws."
            ]
          }
        ]
      },
      {
        title: "BUSINESS ACCOUNT",
        data: [
          {
            list: "lower-alpha",
            data: [
              "If you have established a Business Account, GPU will invoice you for all Bookings made via that Account weekly. You agree to pay each invoice rendered to you in full within 30 days of the date receiving it. Default interest will be charged on overdue invoices at a rate of 12% per annum, calculated daily. ",
              "If payment of an invoice is not received by the due date, your ability to request Bookings may be temporarily suspended until the amount owing is paid in full."
            ]
          }
        ]
      },
      {
        title: "GENERAL",
        data: [
          {
            list: "lower-alpha",
            data: [
              "Any disputes to charges to an Account must be made in writing to accounts@gpu.travel within 7 days of the invoice date. Disputes are not able to be lodged after this date, and full charges will apply.",
              "Any variations to Driver Services made at your instruction or the instruction of any passenger or contact person with whom the Driver directly communicates may incur extra charges. If such extra charges are incurred, the Customer must pay these charges regardless of whether the variations were made at the Customer's direction.",
              "Any inaccurate details given to GPU resulting in a lower Quoted Fare will result in the difference being charged to you at the time of the journey."
            ]
          }
        ]
      }
    ]
  },
  {
    title: "YOUR OBLIGATIONS",
    list: "decimal",
    data: [
      {
        title: "GENERAL",
        data: [
          "You must:",
          {
            list: "lower-alpha",
            data: [
              "provide the Driver with all necessary information and documentation to provide the Driver Services;",
              "comply with the Booking Conditions for each individual Booking;",
              "ensure that any goods to be transported are not Prohibited Goods;",
              "ensure that all passengers and end users of the Driver Services are aware of their obligations under this agreement and agree to be bound by them;",
              "attend at the pickup location at the time specified in the Booking Information;",
              "allow the Driver to transport you and any additional passengers and goods (to the maximum capacity of the Driver's vehicle) to the destination specified in the Booking;",
              "provide an accurate Feedback Rating for the Driver at the completion of the Driver Services; and",
              "not allow any damage or soiling to occur to the Driver's vehicle. If you fail to do so, you acknowledge that GPU may charge you a soiling and damage fee referable to the severity of the soiling or damage, which is to be notified to the Customer by GPU and collected from the Customer by GPU."
            ]
          }
        ]
      },
      {
        title: "WARRANTY ABOUT INFORMATION PROVIDED",
        data: [
          "The Customer warrants to GPU and the Driver that all information provided by the Customer in connection with the Booking is true and correct in all material respects."
        ]
      }
    ]
  },
  {
    title: "LIMITATION OF LIABILITY",
    list: "decimal",
    data: [
      {
        title: "INSURANCE",
        data: [
          "Whilst GPU requires Drivers to maintain adequate insurance coverage in relation to the Driver Services, GPU is in no way responsible for the maintenance of any insurance covering the Customer or their property."
        ]
      },
      {
        title: "PROVISION OF INSURANCE DETAILS IN CASE OF INCIDENT",
        data: [
          {
            list: "lower-alpha",
            data: [
              "In the case of an accident or other incident involving the Driver Services, GPU may, in its sole discretion, provide the details of any applicable insurance policy held by the Driver to the Customer in order to assist the Customer or its insurer in making a claim under that policy.",
              "It is your responsibility to review and confirm the accuracy of all such insurances and make any enquiries of the Driver that you consider appropriate."
            ]
          }
        ]
      },

      {
        title: "NO EXCLUSION OF NON-EXCLUDABLE GUARANTEES",
        data: [
          "No party to this agreement excludes or limits the application of any provision of any statute (including the Competition and Consumer Act 2010 (Cth)) where to do so would contravene that statute or cause any part of this agreement to be void."
        ]
      },
      {
        title: "EXCLUSION OF LIABILITY ",
        data: [
          {
            list: "lower-alpha",
            data: [
              {
                text: "GPU excludes:",
                list: "upper-roman",
                data: [
                  "from this agreement all conditions, warranties and terms implied by statute, general law, international convention or custom, except any implied condition or warranty the exclusion of which would contravene any statute or cause this clause to be void; and",
                  "all liability for consequential or indirect damages arising out of or in connection with this agreement (even if GPU knew they were possible or they were otherwise foreseeable) including without limitation, loss of profits and damage suffered as a result of Claims by any third person."
                ]
              },
              "Without limiting clause 10.4(a), GPU will not be liable to you for any software or other fault with respect to the Application or GPU's website.",
              "GPU accepts no responsibility for the content on any website operated by a third party (including but not limited to any websites that may be accessed using a link on the Application or GPU's website).",
              "GPU is not liable for the acts or omissions of the Driver in relation to the performance of the Driver Services, including but not limited to any failure by to arrive at any location at the estimated arrival time.",

              {
                list: "upper-roman",
                text: "The entire liability of GPU to you in respect of any Claim, damage, loss whatsoever or breach of this agreement, whether or not arising out of negligence, is limited to, at GPU's election:",
                data: [
                  "where the Claim, damage or loss relates to re-performing of the GPU Services or Driver Services, re-performing the GPU Services or Driver Services (or equivalent services); or",
                  "in any other circumstance, the lesser of the Quoted Fare in respect of which the Claim, loss or damage arises or the Quoted Fare in respect of the last Booking made by you."
                ]
              },
              "In no circumstance will GPU or any of its employees, agents or subcontractors be liable to you for any consequential loss, including but not limited to, missed flights, business impact, loss of profit etc."
            ]
          }
        ]
      },
      {
        title: "FRAUD AND NEGLIGENCE",
        data: [
          "Nothing in this agreement shall exclude or in any way limit either party's liability for fraud, death or personal injury caused by its negligence or any other liability to the extent the same may not be excluded or limited as a matter of law."
        ]
      }
    ]
  },
  {
    title: "INDEMNITY",
    list: "decimal",
    data: [
      {
        title: "INDEMNITY",
        data: [
          "You will at all times indemnify GPU and its officers, employees and agents (those indemnified) from and against any loss or costs (including legal costs and expenses) or liability incurred or suffered directly or indirectly by any of those indemnified arising out of or in connection with any of the following:",
          {
            list: "lower-alpha",
            data: [
              "any act or omission by you in connection with this agreement; and/or ",
              "a breach of this agreement by you,"
            ]
          },
          "including but not limited to any Claim made by a Driver against GPU arising from or in connection with the provision of Driver Services by that Driver to you."
        ]
      },
      {
        title: "REDUCTION OF INDEMNITY",
        data: [
          "Your liability under the indemnity in clause 11.2 will be reduced to the extent to which any action, proceeding, claim or demand arises out of any negligence or other wrongful act or omission of GPU."
        ]
      },
      {
        title: "SURVIVAL OF INDEMNITY",
        data: [
          "Any indemnity given under this agreement survives termination (including as a result of expiry of this agreement) and applies to claims which may have occurred but are not notified during the term of this agreement. "
        ]
      }
    ]
  },
  {
    title: "TERMINATION AND SUSPENSION",
    list: "lower-alpha",

    data: [
      "GPU may terminate this agreement immediately at any time without notice by ceasing to provide you with access to the Application.",
      "GPU may, in its absolute discretion, suspend the provision of Services to you indefinitely at any time without notice.",

      {
        text: "If GPU or you terminate this agreement for any reason, you must immediately:",
        list: "upper-roman",
        data: [
          "cease using the Application;",
          "fulfil any outstanding obligations that you have under this agreement at the time of termination; and",
          "return to GPU or destroy (at GPU's discretion) any Intellectual Property or copies of any Intellectual Property which you have in your possession or control."
        ]
      },
      "Termination of this agreement by GPU is in addition to any other rights or remedies available to GPU pursuant to this agreement or at law."
    ]
  },
  {
    title: "GENERAL",
    list: "decimal",
    data: [
      {
        title: "ENTIRE AGREEMENT",
        data: [
          "This agreement constitutes the entire understanding of the parties and supersedes all prior offers, agreements, understandings and communications between the parties."
        ]
      },
      {
        title: "NO MERGER",
        data: [
          "The covenants, conditions, provisions and warranties contained in this agreement do not merge or terminate upon completion of the transactions contemplated in this agreement but to the extent that they have not been fulfilled and satisfied or are capable of having effect, remain in full force and effect."
        ]
      },
      {
        title: "SEVERABILITY",
        data: [
          "If any term or condition of this agreement is void or unenforceable, it shall be read down so as to be enforceable or, if it cannot be so read down, it shall be severed from this agreement without affecting the enforceability of the remaining terms or conditions."
        ]
      },
      {
        title: "RIGHTS CUMULATIVE",
        data: [
          "The rights of GPU in this agreement are cumulative with and do not exclude any rights given by law independently of this agreement."
        ]
      },
      {
        title: "WAIVER",
        data: [
          "Failure by any party to enforce any of its rights pursuant to this agreement shall not be construed as a waiver of any of the rights."
        ]
      },
      {
        title: "NO ASSIGNMENT",
        data: [
          "The Customer must not assign all or any part of their rights or obligations under this agreement without the prior written consent of GPU, which GPU may give in its absolute sole discretion."
        ]
      },
      {
        title: "NOTICES",
        data: [
          "A notice, demand or other communication given or made under this agreement must be in writing, signed by or on behalf of the party giving it and may be served on a party by being delivered or sent by prepaid post to the party's address, or may be sent by electronic mail to the party's address as notified by that party."
        ]
      },
      {
        title: "FURTHER ASSURANCE",
        data: [
          "Each party must do all things and execute all further documents necessary to give full effect to this agreement and refrain from doing anything that might hinder the performance of this agreement."
        ]
      },
      {
        title: "GOVERNING LAW AND JURISDICTION ",
        data: [
          "This agreement is governed by the laws of the State or Territory of Australia in which the Driver Services are provided to you. Each party irrevocably submits to the jurisdiction of the courts of that jurisdiction (and the courts of appeal from them) and waives any right they have to object to an action being brought in those courts, including but not limited to claiming that the action has been brought in an inconvenient forum or that those courts do not have jurisdiction."
        ]
      },
      {
        title: "GPU'S ADDRESS",
        data: [
          "GPU's address for service and electronic mail address are:",
          "Name:",
          "Get Picked Up Pty Ltd ",
          "Attention:",
          "Operations Manager",
          "Address: ",
          "P.O. Box 126, Broadway NSW 2007 ",
          "Email address: ",
          "info@gpu.travel "
        ]
      },
      {
        title: "CUSTOMER'S ADDRESS",
        data: [
          "Your address for service, facsimile number and electronic mail address are as entered into Application from time to time."
        ]
      }
    ]
  }
];

const List = ({ data, list }: IListProps): JSX.Element => {
  return (
    <>
      {data.map((el, index) =>
        typeof el === "object" ? (
          el.list ? (
            <li>
              {el.title !== undefined ? <b>{el.title}</b> : null}
              {el.text !== undefined ? <span>{el.text}</span> : null}

              <ol className={cn(styles.list, styles[el.list])}>
                <List data={el.data} list={el.list} />
              </ol>
            </li>
          ) : (
            <li key={index}>
              <b>{el.title ?? ""}</b>

              {el.list ? (
                <ol className={cn(styles.list, styles[el.list])}>
                  <List data={el.data} list={el.list} />
                </ol>
              ) : (
                <List data={el.data} list={el.list} />
              )}
            </li>
          )
        ) : list ? (
          <li>
            <span key={index}>{el}</span>
          </li>
        ) : (
          <p key={index}>
            <span>{el}</span>
          </p>
        )
      )}
    </>
  );
};

const TermsAndCondModal = (): JSX.Element => {
  return (
    <ModalLayout
      type={EModalTypes.TERMS_AND_CONDITIONS}
      className={styles.terms__inner}
      size="sm"
    >
      <div className={styles.terms}>
        <div className={styles.terms__suptitle}>Terms and conditions</div>
        <div className={styles.terms__bar} />
        <h3 className={styles.terms__title}>
          GET PICKED UP TERMS AND CONDITIONS FOR CUSTOMERS - PASSENGER SERVICE
        </h3>
        <p>
          These Terms and Conditions govern the availability and use of GPU
          Services offered and provided by Get Picked Up Pty Ltd (ACN 150 008
          003) (GPU), and Driver Services offered by transport service operators
          who are subcontractors of GPU for the provision of the Driver Services
          (Drivers), to users of the GPU Application (Customers/you).
        </p>
        <p>
          These Terms and Conditions set out the rights and obligations of GPU,
          the Drivers and Customers. For the avoidance of doubt, when using GPU
          Services, the Customer is entering into an agreement with GPU. The
          Driver Services are provided by the Drivers as subcontractors and any
          payments received from Customers by GPU for the provision of Driver
          Services are received by GPU in its capacity as principal.
        </p>
        <ol className={cn(styles.list, styles.decimal)}>
          <List data={data} list={"decimal"} />
        </ol>
      </div>
    </ModalLayout>
  );
};

export default TermsAndCondModal;

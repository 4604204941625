import React from "react";
import { useSelector } from "react-redux";

import { Payment } from "@getpickedupgpu/gpu2.shared";
import { selectUser } from "@store/user/selector";
import { DEFAULT_URL, config } from "@constants/config";
import { WelcomeBack } from "@components";

const PaymentPage = (): JSX.Element => {
  const user = useSelector(selectUser);

  return (
    <Payment
      user={user}
      config={{
        ...config,
        default_url: DEFAULT_URL
      }}
      extra={<WelcomeBack />}
    />
  );
};

export default PaymentPage;

import axiosInstance from "./axios";

export const downloadFile = async (
  fileUrl: string,
  fileName: string
): Promise<void> => {
  const { data } = await axiosInstance.get(fileUrl, {
    responseType: "blob"
  });
  const href = URL.createObjectURL(data);
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

import React, { useState } from "react";

import { ByAccList, ByCardList, Header } from "@modules/Invoices/components";
import { EPaymentTypes } from "@constants/history";

import type { ISelectListItem } from "@ui/ui.types";
import type { IInvoiceFilter } from "@interfaces";
import { WelcomeBack } from "@components";
import { MainLayout } from "@layouts";

const types = [
  { label: "By card", value: EPaymentTypes.CARD },
  { label: "By account", value: EPaymentTypes.ACCOUNT }
];

const InvoicesPage = (): JSX.Element => {
  const [active, setActive] = useState<ISelectListItem>(types[0]);
  const [filter, setFilter] = useState<IInvoiceFilter>({
    dateFrom: undefined,
    dateTo: undefined,
    amountFrom: "",
    amountTo: ""
  });

  return (
    <MainLayout extra={<WelcomeBack />}>
      <Header
        list={types}
        active={active}
        setActive={setActive}
        setFilter={setFilter}
        filter={filter}
      />
      {active?.value === EPaymentTypes.CARD ? (
        <ByCardList filter={filter} />
      ) : null}
      {active?.value === EPaymentTypes.ACCOUNT ? (
        <ByAccList filter={filter} />
      ) : null}
    </MainLayout>
  );
};

export default InvoicesPage;

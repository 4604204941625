import { useEffect, useRef, useState } from "react";

import type { Dispatch, SetStateAction } from "react";

import axios from "axios";
import { axiosInstance } from "@utils";
import { useDebounce } from "./useDebounce";

interface IData<Type> {
  list: Type[];
  loading: boolean;
  nextPage: (page?: number) => void;
  clearData: () => void;
  hasMore: boolean;
  setData: Dispatch<SetStateAction<Type[]>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const DATA_SIZE = 15;

export const useInfiniteFetchData = <T>(
  url: string,
  data_size: number = DATA_SIZE,
  initValues: T[] = []
): IData<T> => {
  const [list, setList] = useState<T[]>(initValues ?? []);
  const [page, setPage] = useState<number>(
    initValues?.length ? Math.ceil(initValues.length / data_size) - 1 : 0
  );

  const [isInit, setIsInit] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState<boolean>(
    initValues?.length ? Number.isInteger(initValues.length / data_size) : true
  );
  const [loading, setLoading] = useState(initValues?.length === 0);

  const CancelToken = axios.CancelToken;
  let cancel: () => void;

  const newUrl = `${url}&skip=${page * data_size}&take=${data_size}`;
  const urlRef = useRef<string>(newUrl);

  function isIterable(input: any): boolean {
    if (input === null || input === undefined) {
      return false;
    }

    return typeof input[Symbol.iterator] === "function";
  }

  const fetchData = async (): Promise<void> => {
    if (initValues?.length && isInit) {
      setIsInit(false);
      return;
    }
    try {
      urlRef.current = url;
      setLoading(true);
      const response = await axiosInstance.get(newUrl, {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        })
      });
      if (urlRef.current === url) {
        if (isIterable(response.data)) {
          setList((prev) =>
            page ? [...prev, ...response.data] : response.data
          );
          setHasMore(response.data.length === data_size);
        } else if (isIterable(response.data.data)) {
          setList((prev) =>
            page ? [...prev, ...response.data.data] : response.data.data
          );
          setHasMore(response.data.data.length === data_size);
        }

        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useDebounce(
    () => {
      if (cancel) {
        cancel();
      }
      fetchData();
    },
    newUrl,
    100
  );

  const nextPage = (page?: number): void => {
    if (loading) return;

    setPage((prev) => page ?? prev + 1);
  };

  const clearData = (): void => {
    setPage(0);
  };

  useEffect(() => {
    if (!isInit) {
      clearData();
    }
  }, [url]);

  return {
    list,
    loading,
    nextPage,
    hasMore,
    clearData,
    setData: setList,
    setLoading
  };
};

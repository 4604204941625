import * as Yup from "yup";

export const BookingSchema = Yup.object().shape({
  from: Yup.object().nullable(),
  to: Yup.object().nullable(),
  date: Yup.string(),
  time: Yup.object(),
  stops: Yup.array().of(Yup.object())
});
export const BookingRateSchema = Yup.object().shape({
  seats: Yup.array().of(Yup.object().required("Please input age")),
  sign: Yup.string(),
  comment: Yup.string(),
  flight_no: Yup.string().when("flighttype", ([flighttype], schema) => {
    return flighttype
      ? schema
          .required("Flight number is required")
          .matches(
            /^([A-Z][\d]|[\d][A-Z]|[A-Z]{2})(\d{1,})$/,
            "Flight number is invalid"
          )
      : schema;
  })
});

export const CustomerSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  mobile: Yup.string().min(9).max(10),
  email: Yup.string().email().required("Email is required"),
  alternate_email: Yup.string().nullable().email()
});

export const RepeatSchema = Yup.object().shape({
  bookings: Yup.array().of(
    Yup.object().shape({
      repeats: Yup.array().of(
        Yup.object().shape({
          time: Yup.object().required()
        })
      )
    })
  )
});

import { RouterProvider } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { router } from "./routes/router";
import { useAppDispatch } from "@hooks";

import { getAgentLogo } from "@store/user/actions";
import { getCarTypes, getCitites, getCountries } from "@store/lookup/actions";
import { fetchcustomerReviewStats } from "@store/review/actions";

import { selectUser } from "@store/user/selector";
import "./styles/styles.scss";

import "react-loading-skeleton/dist/skeleton.css";
import { setMomentTimezone } from "@utils";

setMomentTimezone();

function App(): JSX.Element {
  console.log("Version 1.3.3");
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (user !== null) {
      dispatch(getAgentLogo());
      dispatch(getCitites());
      dispatch(getCarTypes());
      dispatch(getCountries());
      dispatch(fetchcustomerReviewStats(user?.aid));
    }
  }, [user?.id]);

  return <RouterProvider router={router(user !== null)} />;
}

export default App;

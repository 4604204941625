import React from "react";

import { CardLayout } from "@layouts";
import { Empty } from "@ui";
import BookingStatus from "../BookingStatus";

import type { IBookingStatusProps } from "../components.types";

import styles from "./styles.module.scss";

const EmptyBooking = ({ status }: IBookingStatusProps): JSX.Element => {
  return (
    <CardLayout className={styles.empty}>
      <BookingStatus status={status} className={styles.empty__status} />
      <Empty
        title={`No ${(status as string) ?? ""} bookings`}
        subtitle='Create a New booking via "Book Now" or you can use the search function if you cannot find the booking you are looking for!'
      />
    </CardLayout>
  );
};

export default EmptyBooking;

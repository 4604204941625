import { useEffect, useState } from "react";
import cn from "classnames";

import { SelectList } from "@ui";
import { EModalTypes } from "@constants/modal";
import { ModalLayout } from "@layouts";
import { MarkerSvg } from "@assets/svg";

import type { ISelectListItem } from "@ui/ui.types";

import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import { selectCities, selectCountries } from "@store/lookup/selector";

import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from "react-simple-maps";
import australia from "./maps/australia.json";
import france from "./maps/france.json";

// eslint-disable-next-line
const countriesData: any = {
  1: {
    geography: australia,
    projectionConfig: {
      center: [495, -28],
      scale: 800
    },
    regions: [
      { label: "NSW", value: "New South Wales" },
      { label: "VIC", value: "Victoria" },
      { label: "QLD", value: "Queensland" },
      { label: "SA", value: "South Australia" },
      { label: "NT", value: "Northern Territory" },
      { label: "TAS", value: "Tasmania" }
    ]
  },
  12: {
    geography: france,
    projectionConfig: {
      center: [2, 47],
      scale: 1800
    }
  }
};
const CitiesModal = (): JSX.Element => {
  const countries = useSelector(selectCountries);

  const allCities = useSelector(selectCities);
  // const [active, setActive] = useState<ISelectListItem>(countries[0]);
  const activeData = countriesData["1"];

  const [activeRegion, setActiveRegion] = useState<ISelectListItem | null>(
    null
  );
  const [hovered, setHovered] = useState<string>("");
  const [hoveredCity, setHoveredCity] = useState<number>();

  const clearHovered = (): void => {
    setHovered("");
    setHoveredCity(undefined);
  };

  const [cities, setCities] = useState<ICity[] | null>(null);

  const filterCities = (
    data: ICity[],
    active: ISelectListItem,
    activeRegion: ISelectListItem | null
  ): void => {
    const filtered = data.filter(
      (el) =>
        el.country_id === active.value &&
        (activeData?.regions && activeRegion
          ? activeRegion.label === el.state_info.short
          : false)
    );
    setCities(filtered);
  };

  useEffect(() => {
    filterCities(allCities ?? [], countries[0], activeRegion);
  }, [allCities, activeRegion]);

  return (
    <ModalLayout
      type={EModalTypes.CITIES}
      size="lg"
      className={styles.cities}
      headerClassName={styles.cities__header}
    >
      <h1 className={styles.cities__title}>We serve in 5 regions</h1>
      <p className={styles.cities__subtitle}>
        We cover major cities across the globe for airport transfers.
      </p>
      {/* <SelectList
        className={styles.cities__select}
        list={countries}
        active={active}
        setActive={setActive}
      /> */}
      {activeData?.regions ? (
        <SelectList
          className={cn(styles.cities__select, styles.regions)}
          list={activeData.regions}
          active={activeRegion}
          setActive={setActiveRegion}
          onMouseLeave={clearHovered}
          // eslint-disable-next-line
          onMouseEnter={(e: any) => {
            setHovered(e.target?.innerText ?? "");
          }}
        />
      ) : null}

      <div className={styles.cities__box}>
        <div className={styles["cities__list-wrapper"]}>
          <div className={styles.cities__list}>
            {cities?.map((city) => (
              <div
                className={styles.cities__item}
                key={city.id}
                onMouseLeave={clearHovered}
                // eslint-disable-next-line
                onMouseEnter={(e: any) => {
                  setHoveredCity(city.id);
                }}
              >
                <MarkerSvg className={styles["cities__item-icon"]} />
                {city.title}
              </div>
            ))}
          </div>
        </div>

        <div className={styles["cities__map-wrapper"]}>
          <ComposableMap
            className={styles.cities__map}
            projection={"geoMercator"}
            projectionConfig={activeData?.projectionConfig}
          >
            {/* <ZoomableGroup
              center={[134.64748064216423, -28.74723699897141]}
              zoom={1}
            > */}
            <Geographies geography={activeData?.geography}>
              {({ geographies }) => {
                return geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={
                      geo.properties.STATE_CODE === hovered
                        ? "#d8ddea"
                        : "#C6CBD4"
                    }
                    stroke={"#fff"}
                    strokeWidth={1.5}
                  />
                ));
              }}
            </Geographies>
            {cities?.map((city) =>
              city.lat ? (
                <Marker key={city.id} coordinates={[city.lng, city.lat]}>
                  <g
                    filter="url(#filter0_d_10645_27422)"
                    style={{
                      transform: "translate(-49px, -56px)"
                    }}
                  >
                    <path
                      d="M49 15C45.2882 15.004 41.7297 16.4343 39.1051 18.9772C36.4805 21.52 35.0041 24.9676 35 28.5637C35 35.3636 46.654 53.423 49 57C51.3509 53.423 63 35.3626 63 28.5637C62.9959 24.9676 61.5195 21.52 58.8949 18.9772C56.2703 16.4343 52.7118 15.004 49 15ZM49 35.9034C47.5758 35.9034 46.1836 35.4942 44.9995 34.7276C43.8153 33.961 42.8924 32.8714 42.3475 31.5966C41.8026 30.3217 41.6601 28.919 41.9381 27.5657C42.2161 26.2125 42.9021 24.9695 43.9092 23.9939C44.9164 23.0184 46.1996 22.3542 47.5965 22.0852C48.9933 21.8162 50.4412 21.9547 51.7568 22.4829C53.0725 23.0112 54.1969 23.9057 54.9879 25.0531C55.7788 26.2006 56.2008 27.5495 56.2004 28.9293C56.1975 30.7784 55.438 32.551 54.0882 33.8584C52.7384 35.1657 50.9086 35.9011 49 35.9034Z"
                      fill={hoveredCity === city.id ? "#0e8b4a" : "#12BF66"}
                    />
                    <circle cx="49" cy="28.9451" r="7" fill="white" />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_10645_27422"
                      x="0"
                      y="0"
                      width="98"
                      height="112"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="20" />
                      <feGaussianBlur stdDeviation="17.5" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.137255 0 0 0 0 0.890196 0 0 0 0 0.466667 0 0 0 0.7 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_10645_27422"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_10645_27422"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </Marker>
              ) : null
            )}
            {/* </ZoomableGroup> */}
          </ComposableMap>
        </div>
      </div>
    </ModalLayout>
  );
};

export default CitiesModal;

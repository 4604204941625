import { Field, useField } from "formik";
import React, { forwardRef } from "react";
import InputLabel from ".";

import type { InputLabelFormikProps } from "@ui/ui.types";

const InputLabelFormik = forwardRef<HTMLInputElement, InputLabelFormikProps>(
  ({ name, ...rest }, ref): JSX.Element => {
    const [field, meta, helpers] = useField(name);

    return (
      <InputLabel
        ref={ref}
        setValue={(value) => {
          helpers.setValue(value);
        }}
        onBlur={field.onBlur}
        asTag={Field}
        error={meta.touched ? meta.error : ""}
        touched={meta.touched}
        value={field.value}
        {...rest}
      />
    );
  }
);

export default InputLabelFormik;

import type { ChangeEvent } from "react";
import cn from "classnames";

import type { ICheckboxProps } from "../ui.types";

import styles from "./Checkbox.module.scss";

const Checkbox = ({
  checked,
  setChecked,
  label,
  error,
  id,
  className
}: ICheckboxProps): JSX.Element => {
  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setChecked(e.target.checked);
  };
  return (
    <div className={cn(styles.checkbox, className, error && styles.error)}>
      <input type="checkbox" id={id} checked={checked} onChange={onChange} />
      <label htmlFor={id}>
        <span className={styles.checkbox__box} />
        {label !== undefined ? (
          <div className={styles.checkbox__label}>{label}</div>
        ) : null}
      </label>
    </div>
  );
};

export default Checkbox;

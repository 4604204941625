import { useField } from "formik";
import React from "react";

import type { ICheckboxFormikProps } from "@ui/ui.types";
import Checkbox from ".";

const InputLabelFormik = ({
  name,
  ...rest
}: ICheckboxFormikProps): JSX.Element => {
  // eslint-disable-next-line
  const [field, _meta, helpers] = useField(name);

  return (
    <Checkbox
      checked={field.value}
      id={name}
      setChecked={(value: boolean) => {
        helpers.setValue(value);
      }}
      error={Boolean(_meta.error) && _meta.touched}
      {...rest}
    />
  );
};

export default InputLabelFormik;

import React from "react";
import cn from "classnames";

import styles from "./InfoBox.module.scss";
import type { IInfoBoxProps } from "@ui/ui.types";
const InfoBox = ({
  title,
  text,
  icon: Icon,
  className,
  size = "full",
  color = "warning",
  align = "left",
  borderless = false
}: IInfoBoxProps): JSX.Element => {
  return (
    <div
      className={cn(
        styles.info,
        styles[size],
        styles[color],
        styles[align],
        borderless && styles.borderless,
        className
      )}
    >
      {Icon ? <Icon className={styles.info__icon} /> : null}
      <div className={styles.info__content}>
        <div className={styles["info__content-title"]}>{title}</div>
        <div className={styles["info__content-text"]}>{text}</div>
      </div>
    </div>
  );
};

export default InfoBox;
